import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import simSwap from "../../assets/custom-icons/sim-swap-icon.svg";
import otherIssue from "../../assets/custom-icons/other-issue-icon.svg";
import { FaShareSquare } from "react-icons/fa";

const SimOnlyQuery = () => {
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 mt-3">
        <h1 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
          SIM Only Query
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-4">
          <div className="border custom-border-50 rounded-lg p-4 flex flex-col lg:items-center h-full">
            <div className="flex flex-row lg:flex-col justify-start lg:justify-center items-center gap-2 mb-2">
              <img
                width={42}
                height={42}
                className="w-[32px] h-[32px] lg:w-[42px] lg:h-[42px]"
                src={simSwap}
                alt="Sim Swap"
              />
              <h2 className="text-xl font-semibold">Sim Swap Request</h2>
            </div>
            <div className="custom-border-50 rounded-lg bg-[#0f766d]/10 p-3">
              <p className="text-sm">
                <span className="font-semibold">Process Summary:</span> <br />
                This process can be used when a customer is looking to replace a
                lost/stolen or faulty sim card with the same mobile number.
              </p>
            </div>

            <div className="mt-4">
              <p className="text-sm">
                <span className="font-semibold">The Process:</span>
                <br />
                Customers will be able to request a replacement sim card by
                using the Three App or by visiting a{" "}
                <a
                  className="font-semibold underline"
                  href="https://locator.three.co.uk/search"
                  target="__blank"
                >
                  Three Store
                </a>{" "}
                (with valid photo ID). More instructions below:
              </p>
              <br />
              <p className="text-sm">
                If the customer would like to activate through the Three
                website:
              </p>
              <ul className="text-sm list-disc pl-6">
                <li>Login to your Three App</li>
                <li>Go to Account</li>
                <li>Tap Help</li>
                <li>Select Replace SIM</li>
                <li> Enter the required details and press submit</li>
              </ul>
              <br />
              <p className="text-sm">
                If the customer would like to visit a
                <span className="font-semibold flex gap-1 items-center">
                  Three Store:{" "}
                  <a
                    className="underline flex items-center gap-1"
                    href="https://locator.three.co.uk/search"
                    target="__blank"
                  >
                    {" "}
                    Check Store <FaShareSquare />
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="border custom-border-50 rounded-lg p-4 flex flex-col lg:items-center h-full">
            <div className="flex flex-row lg:flex-col justify-start lg:justify-center items-center gap-2 mb-2">
              <img
                width={42}
                height={42}
                className="w-[32px] h-[32px] lg:w-[42px] lg:h-[42px]"
                src={otherIssue}
                alt="Cancel Contract"
              />
              <h2 className="text-xl font-semibold">Other Query</h2>
            </div>
            <div className="custom-border-50 rounded-lg bg-[#0f766d]/10 p-3">
              <p className="text-sm">
                <span className="font-semibold">Personal Customer:</span> <br />
                Line are open 08:00 to 20:00 Monday to Friday, and 09:00 to
                18:00 Saturday to Sunday
              </p>
            </div>
            <div className="mt-4 w-full">
              <p className="text-sm">
                <span className="font-semibold">Personal customers:</span>
              </p>
              <br />
              <ul className="text-sm list-disc pl-6">
                <li>
                  From your Three phone
                  <br />
                  <span className="text-xl underline">333</span>
                </li>
                <br />
                <li>
                  From any other phone
                  <br />
                  <span className="text-xl underline">0333 338 1001</span>
                </li>
                <br />
                <li>
                  From abroad (standard roaming rates apply)​
                  <br />
                  <span className="text-xl underline">+44 7782 333 333</span>
                </li>
              </ul>
              <br />
              <p className="text-sm">
                If the customer would like to visit a
                <span className="font-semibold flex gap-1 items-center">
                  Three Store:{" "}
                  <a
                    className="underline flex items-center gap-1"
                    href="https://locator.three.co.uk/search"
                    target="__blank"
                  >
                    {" "}
                    Check Store <FaShareSquare />
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SimOnlyQuery;
