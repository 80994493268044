import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { PopoverButton, PopoverPanel } from "@headlessui/react";
import useScrollToTop from "../customHooks/useScrollToTop";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Transition } from "@headlessui/react";
import ResetPasswordService from "../../services/ResetPasswordService";
import ModalComponent from "../modalComponent/ModalComponent";
import {closeModal, openModal, selectModalContentId} from "../modalComponent/modalSlice";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

export default function ResetPassword(props) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [message, setMessage] = useState();
  const [code, setCode] = useState();
  const [confirmPwd, setConfirmPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const { param1, param2 } = useParams();
  const [loader, setLoader] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleRePassword, setToggleRePassword] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState("");
  const modalContentId = useSelector(selectModalContentId);

  const ModalError = ({ errorMsg }) => {
    return (
        <div>
          <p>{errorMsg}</p>
        </div>
    );
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [verification, setVerification] = useState({
    verificationId: "",
    userIdNew: "",
  });

  const [reset, setReset] = useState({
    password: "",
    confirmPassword: "",
    userIdNew: "",
  });
  useEffect(() => {
    setReset({
      password: newPwd,
      confirmPassword: confirmPwd,
      userIdNew: param2,
    });
  }, [newPwd, confirmPwd, param2]);
  useEffect(() => {
    if (param1 && param2) {
      const verificationData = { verificationId: param1, userIdNew: param2 };
      setVerification(verificationData);
      forgotPasswrdVerificationAPI(verificationData);
    }
  }, [param1, param2]);

  const forgotPasswrdVerificationAPI = (verificationData) => {
    ResetPasswordService.forgotPasswordVerification(verificationData)
      .then((response) => {
        if (response.status == 200) {
          setCode(200);
        } else {
          if (response.data && response.data.failedReason) {
            setMessage(response.data.failedReason);
          } else {
            setMessage("Verification failed for an unknown reason.");
          }
          setCode(response.status);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              // setMessage(error.response.data.failedReason);
              setCode(401);
              dispatch(openModal("errorModal"));
              setErrorMessageModal(error.response.data.failedReason);
              break;
            case 403:
              // setMessage(error.response.data.failedReason);
              setCode(403);
              dispatch(openModal("errorModal"));
              setErrorMessageModal(error.response.data.failedReason);
              break;
            case 500:
              // setMessage(error.response.data.failedReason);
              setCode(500);
              dispatch(openModal("errorModal"));
              setErrorMessageModal(error.response.data.failedReason);
              break;
            case 404:
            case 406:
              // setMessage(error.response.data.failedReason);
              setCode(406);
              dispatch(openModal("verificationFailedErrorModal"));
              setErrorMessageModal("This link has expired. Please request a new link and try again.");
              break;
            case 412:
              // setMessage(error.response.data.failedReason);
              setCode(412);
              dispatch(openModal("errorModal"));
              setErrorMessageModal(error.response.data.failedReason);
              break;
            default:
              // setMessage("unknown Error");
              dispatch(openModal("errorModal"));
              setErrorMessageModal(error.response.data.failedReason);
              break;
          }
        }
      });
  };
  const resetPasswordAPI = (data) => {
    setLoader(true);
    const { newPassword, confirmPassword } = data;
    ResetPasswordService.resetPassword({
      password: newPassword,
      confirmPassword: confirmPassword,
      userIdNew: param2,
    })
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          // const message = "Password reset Sucessfully";
          dispatch(openModal("passwordResetSuccessModal"));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason);
              break;
            case 403:
              setMessage(error.response.data.failedReason);
              break;
            case 500:
              setMessage(error.response.data.failedReason);
              break;
            case 404:
            case 406:
              setMessage(error.response.data.failedReason);
              break;
            case 412:
              setMessage(error.response.data.failedReason);
              break;
            default:
              setMessage("unknown Error");
              break;
          }
        }
      });
  };

  const validatePasswords = (value) => {
    return value === watch("newPassword") || "Passwords do not match";
  };

  const passwordValidationRules = {
    required: "Password is required",
    minLength: { value: 8, message: "Password must be at least 8 characters" },
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      message:
        "Password must include 1 uppercase, 1 lowercase, 1 number, and 1 symbol",
    },
  };
  const handletogglePassword = () => {
    setTogglePassword(!togglePassword);
  };
  const handletoggleRePassword = () => {
    setToggleRePassword(!toggleRePassword);
  };
  return (
    <>
      {code == 200 ? (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8">
          <div className="mx-auto w-full max-w-sm md:max-w-md">
            <Link to="/">
              <img className="mx-auto h-[80px] w-auto" src={logo} alt="GKT" />
            </Link>
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text">
                Create new password
              </h2>
              {/* <p className="text-lg font-medium mt-4">Create a new, strong password</p> */}
            </div>
          </div>
          <div className="mt-6 mx-auto w-full max-w-sm md:max-w-md">
            <form onSubmit={handleSubmit(resetPasswordAPI)}>
              <div id="" className="space-y-6">
                <div>
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor="new-password"
                      className="block text-sm font-normal leading-6"
                    >
                      Create Password*
                    </label>
                    <HiOutlineInformationCircle
                      className="cursor-pointer"
                      style={{ fontSize: 20 }}
                      // onClick={() => setIsInfoOpen(true)}
                      onMouseEnter={() => setIsInfoOpen(true)}
                      onMouseLeave={() => setIsInfoOpen(false)}
                    />
                  </div>
                  <div className="mt-2 relative">
                    <input
                      {...register("newPassword", passwordValidationRules)}
                      // onChange={(e) => {
                      //   setNewPwd(e.target.value);
                      // }}
                      id="newpassword"
                      type={togglePassword ? "text" : "password"}
                      placeholder="Create a new password"
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    />
                    <div
                      onClick={handletogglePassword}
                      className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                    >
                      {togglePassword ? <EyeSlashIcon /> : <EyeIcon />}
                    </div>
                    <Transition
                      appear
                      show={isInfoOpen}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <div className="absolute right-0 top-12 z-10 w-screen max-w-xs opacity-100">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                          <div className="relative grid gap-8 bg-white p-2">
                            <div className="">
                              <ul className="text-sm list-disc ml-4">
                                <li>
                                  Password must be at least
                                  <span className="font-semibold">
                                    {" "}
                                    8 characters
                                  </span>
                                </li>
                                <li>
                                  must include at least
                                  <ul className="ml-3">
                                    <li>
                                      <span className="font-semibold">
                                        1 uppercase
                                      </span>
                                    </li>
                                    <li>
                                      <span className="font-semibold">
                                        1 lowercase
                                      </span>
                                    </li>
                                    <li>
                                      <span className="font-semibold">
                                        1 number
                                      </span>
                                    </li>
                                    <li>
                                      <span className="font-semibold">
                                        1 symbol
                                      </span>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>
                {errors.newPassword && (
                  <p className="error-text">{errors.newPassword.message}</p>
                )}
                <div>
                  <label
                    htmlFor="new-password"
                    className="block text-sm font-normal leading-6"
                  >
                    Confirm Password*
                  </label>
                  <div className="mt-2 relative">
                    <input
                      {...register("confirmPassword", {
                        required: "Password is required",
                        validate: validatePasswords,
                      })}
                      //  onChange={(e) => {
                      //   setConfirmPwd(e.target.value);
                      // }}
                      id="confirmPassword"
                      type={toggleRePassword ? "text" : "password"}
                      // autoComplete="email"
                      placeholder="Confirm password"
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    />
                    <div
                      onClick={handletoggleRePassword}
                      className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                    >
                      {toggleRePassword ? <EyeSlashIcon /> : <EyeIcon />}
                    </div>
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="error-text">
                    {errors.confirmPassword.message}
                  </p>
                )}

                <div>
                  <button
                    //  onClick={resetPasswordAPI}
                    type="submit"
                    className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </form>
            {message && (
              <div className="mt-4 p-2 text-center error-text">{message}</div>
            )}
            <p className="mt-10 text-center text-sm ">
              <span className="text-gray-500">Already have an Account? </span>
              <Link
                to="/login"
                className="font-semibold hover:underline decoration-1 primary-text"
              >
                Login
              </Link>
            </p>
          </div>
        </div>
      ) : (
        <div className="mt-4 p-2 text-center error-text">{message}</div>
      )}
      <ModalComponent
        size="md"
        type={modalContentId === "passwordResetSuccessModal" ? "success" : "error"}
        title={
          modalContentId === "passwordResetSuccessModal"
          ? "Password Reset Successfully"
          : modalContentId === "verificationFailedErrorModal"
          ? "Link Expired"
          : "Something went wrong"
        }
          // description="An email with instructions to reset your password has been successfully sent to your registered email address."
      >
        {modalContentId === "passwordResetSuccessModal" ? (
            <div>
              <p>
                Password has been reset successfully. Please proceed for login
              </p>
              <Button
                  style="cta-two w-1/2 mt-4 flex justify-center mx-auto"
                  title="Okay"
                  handleClick={() => {
                    dispatch(closeModal());
                    navigate("/login");
                  }}
              />
            </div>
        ) : (
            <ModalError errorMsg={errorMessageModal}/>
        )}

      </ModalComponent>
      {loader && <Loader/>}
    </>
  );
}
