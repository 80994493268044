import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import useScrollToTop from "../customHooks/useScrollToTop";
import RegisterService from "../../services/RegisterService";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup"; // Added Yup for validation
import { Formik, Field, ErrorMessage, Form } from "formik"; // Added Formik components
import { Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import DealerSignupService from "../../services/DealerSignupService";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  selectModalState,
} from "../modalComponent/modalSlice";
import Button from "../button/Button";
import ModalComponent from "../modalComponent/ModalComponent";
import VerificationService from "../../services/VerificationService";
import { handleLogIn } from "../navbar/navbarSlice";
import { useForm } from "react-hook-form";
import { FaPaperPlane } from "react-icons/fa6";
import { FiClock } from "react-icons/fi";
import Cookies from "js-cookie";
import Loader from "../loader/Loader";
import CustomModal from "../views/components/CustomModal";
import MicroLoader from "../loader/MicroLoader";
import LoaderButton from "../button/LoaderButton";
import { BiSolidWatch } from "react-icons/bi";
import {
  API_URL,
  DEALER_SIGNUP_URL,
  REACT_APP_SITE_KEY,
} from "../../constant/Constant";
import { openDocumentModal } from "../legal/documentModal/DocumentModalSlice";
import DocumentModal from "../legal/documentModal/DocumentModal";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaService from "../../services/RecaptchaService";
import { RiShareBoxFill } from "react-icons/ri";
import TermsAndConditionService from "../../services/TermsAndConditionService";
import axios from "axios";

export default function Register() {
  useScrollToTop();
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleRePassword, setToggleRePassword] = useState(false);
  const [toggleRefferalCodeOne, setToggleRefferalCodeOne] = useState(false);
  const [toggleRefferalCodeTwo, setToggleRefferalCodeTwo] = useState(false);
  const [togglePasswordGroup, setTogglePasswordGroup] = useState(true);
  const [accountType, setAccountType] = useState(2);
  const [firstAcc, setFirstAcc] = useState(false);
  const [secondAcc, setSecondAcc] = useState(true);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const modalInitState = useSelector((state) => state.modal);
  const [readonlyDealer, setReadonlyDealer] = useState(false);
  const {
    register: dealerVerify,
    handleSubmit: verifySubmitDealer,
    formState: { errors: errorsDealer },
  } = useForm();
  const {
    register: registerDealerValidation,
    handleSubmit: handleSubmitDealer,
    watch,
    setValue,
    getValues,
    formState: { errors: dealerError, isSubmitted },
    trigger,
  } = useForm({
    mode: "onSubmit",
  });
  const [open, setOpen] = useState(false);
  const selectOpenModal = useSelector(selectModalState);
  const [isLoading, setIsLoading] = useState(false);
  const [pMessaage, setPMessaage] = useState();
  const [message, setMessage] = useState();
  const [dealerMessage, setDealerMessage] = useState();
  const [loginEmailId, setLoginEmailId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pwd, setPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const [dealerId, setDealerId] = useState("");
  const [firstNameDealer, setFirstNameDealer] = useState("");
  const [lastNameDealer, setLastNameDealer] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [resendLink, setResendLink] = useState(false);

  const dispatch = useDispatch();
  const [failedValue, setFailedValue] = useState("");
  const [isResetButton, setIsResetButton] = useState(false);
  const [seconds, setSeconds] = useState(30);

  //for dealer signup
  const [signupDealerId, setSignupDealerId] = useState("");
  const [signupDealerFirstName, setSignupDealerFirstName] = useState("");
  const [signupDealerLastName, setSignupDealerLastName] = useState("");
  const [signupDealerEmail, setSignupDealerEmail] = useState("");
  const [signupDealerUsername, setSignupDealerUsername] = useState("");
  const [signupDealerPassword, setSignupDealerPassword] = useState("");
  const [signupDealerConfPassword, setSignupDealerConfPassword] = useState("");
  const [signupDealerReferrelCode, setSignupDealerReferrelCode] = useState("");
  const [signupDealerHouseno, setSignupDealerHouseno] = useState("");
  const [signupDealerHouseName, setSignupDealerHouseName] = useState("");
  const [signupDealerStreetName, setSignupDealerStreetName] = useState("");
  const [signupDealerLocality, setSignupDealerLocality] = useState("");
  const [signupDealerTown, setSignupDealerTown] = useState("");
  const [signupDealerCity, setSignupDealerCity] = useState("");
  const [signupDealerCountry, setSignupDealerCountry] = useState("");
  const [signupDealerPostcode, setSignupDealerPostcode] = useState("");
  const [signupDealerVatNo, setSignupDealerVatNo] = useState("");
  const [signupDealerCompanyRegNo, setSignupDealerCompanyRegNo] = useState("");
  const [signupDealerDealerName, setSignupDealerDealerName] = useState("");
  const [signupDealerContactno, setSignupDealerContactno] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const { id } = useParams();
  const location = useLocation();
  const fullUrl = location.pathname;
  const [referralUrl, setReferralUrl] = useState(false);
  const [dealerSignup, setDealerSignup] = useState(false);
  const [dealerSignupSubmit, setDealerSignupSubmit] = useState("");
  const [localStorageValue, setLocalStorageValue] = useState(false);
  let [sameCredentialChecked, setSameCredentialChecked] = useState(false);
  const [dealerReferrelCodeInput, setDealerReferrelCodeInput] = useState("");
  const [dealerNewPassword, setDealerNewPassword] = useState("");
  const [dealerNewConfirmPassword, setDealerNewConfirmPassword] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [count, setCount] = useState(0);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [verifyBtnDisabled, setVerifyBtnDisabled] = useState(false);
  const [resendBtnDisabled, setResendBtnDisabled] = useState(false);
  const [submitDealerBtnDisabled, setSubmitDealerBtnDisabled] = useState(false);
  const modalSliceState = useSelector(selectModalState);

  const [modalClose, setModalClose] = useState(false);

  //Captcha IMPL
  const [recaptchaError, setRecaptchaError] = useState(undefined);
  const captchaRef = useRef(null);

  const [threeDocClicked, setThreeDocClicked] = useState(false);
  const [partnerC8DocClicked, setPartnerC8DocClicked] = useState(false);
  const threeDocValue = watch("threeDoc", false); // default value is false
  const partnerC8DocValue = watch("partnerC8Doc", false); // default value is false
  const [register, setRegister] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conformPassword: "",
    referralCode: "",
  });

  const [registerDealer, setRegisterDealer] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conformPassword: "",
    referralCode,
    dealerId,
    resendLink,
  });

  ////////////////// states end here //////////////////

  useEffect(() => {
    if (fullUrl === `/register/${id}`) {
      setReferralUrl(true);
      setFirstAcc(true);
      setSecondAcc(false);
      setAccountType(1);
      setToggleRefferalCodeOne(true);
    } else {
      setReferralUrl(false);
      setFirstAcc(false);
      setSecondAcc(true);
      setAccountType(2);
      setToggleRefferalCodeOne(false);
    }
  }, [id, fullUrl]);

  const handleAccountType = (type) => {
    if (type === 1) {
      setAccountType(1);
      setFirstAcc(true);
      setSecondAcc(false);
    } else if (type === 2) {
      setAccountType(2);
      setFirstAcc(false);
      setSecondAcc(true);
    } else {
    }
  };

  const handletogglePassword = () => {
    setTogglePassword(!togglePassword);
  };
  const handletoggleRePassword = () => {
    setToggleRePassword(!toggleRePassword);
  };

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  useEffect(() => {
    // DealerSignupSubmit
    if (selectOpenModal == false) {
      setModalClose(true);
    }
  }, [selectOpenModal]);

  useEffect(() => {
    if (count === 2) {
      setSeconds(30); // Reset the countdown timer to 30 seconds when count becomes 2
    }
  }, [count]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  useEffect(() => {
    setRegister({
      email: loginEmailId,
      firstName: firstName,
      lastName: lastName,
      password: pwd,
      conformPassword: confPwd,
      referralCode: referralCode,
    });
  }, [loginEmailId, firstName, lastName, pwd, confPwd, referralCode]);

  useEffect(() => {
    setRegisterDealer({
      email: email,
      firstName: firstNameDealer,
      lastName: lastNameDealer,
      password: password,
      conformPassword: conformPassword,
      referralCode: referralCode,
      dealerId: dealerId,
      resendLink: resendLink,
    });
  }, [
    email,
    firstName,
    lastName,
    password,
    conformPassword,
    referralCode,
    dealerId,
    resendLink,
  ]);

  useEffect(() => {
    //   //console.log("useeffect for dealer signup===");
    //   setDealerSignupSubmit({
    // //console.log("sameCredentialChecked", sameCredentialChecked);
    // //console.log("signupDealerPassword", signupDealerPassword);
    // //console.log("signupDealerConfPassword", signupDealerConfPassword);
    // //console.log("dealerNewPassword", dealerNewPassword);
    // //console.log("dealerNewConfirmPassword", dealerNewConfirmPassword);
    if (sameCredentialChecked) {
      setValue("firstName", signupDealerFirstName, { shouldValidate: true });
      setValue("lastName", signupDealerLastName, { shouldValidate: true });
      setValue("email", signupDealerEmail, { shouldValidate: true });
      setDealerSignupSubmit({
        email: signupDealerEmail,
        firstName: signupDealerFirstName,
        lastName: signupDealerLastName,
        password: signupDealerPassword,
        conformPassword: signupDealerConfPassword,
        referralCode: signupDealerReferrelCode,
        dealerId: signupDealerId,
        resendLink: resendLink,
        sameCredential: sameCredentialChecked,
      });
    } else {
      setValue("firstName", signupDealerFirstName, { shouldValidate: true });
      setValue("lastName", signupDealerLastName, { shouldValidate: true });
      setValue("email", signupDealerEmail, { shouldValidate: true });
      setDealerSignupSubmit({
        email: signupDealerEmail,
        firstName: signupDealerFirstName,
        lastName: signupDealerLastName,
        password: dealerNewPassword,
        conformPassword: dealerNewConfirmPassword,
        referralCode: signupDealerReferrelCode,
        dealerId: signupDealerId,
        resendLink: resendLink,
        sameCredential: sameCredentialChecked,
      });
    }
  }, [
    email,
    firstName,
    lastName,
    password,
    conformPassword,
    referralCode,
    signupDealerId,
    resendLink,
    dealerNewPassword,
    dealerNewConfirmPassword,
    signupDealerReferrelCode,
    signupDealerReferrelCode,
    sameCredentialChecked,
  ]);
  // }, [signupDealerEmail, signupDealerFirstName, signupDealerLastName, signupDealerPassword, signupDealerConfPassword, referralCode, signupDealerId, resendLink, dealerNewPassword, dealerNewConfirmPassword, signupDealerReferrelCode]);

  // const [dealerSignupSubmit, setDealerSignupSubmit] = useState({
  //   email: email,
  //   firstName: firstNameDealer,
  //   lastName: lastNameDealer,
  //   password: password,
  //   conformPassword: conformPassword,
  //   referralCode: referralCode,
  //   dealerId: dealerId,
  //   resendLink: resendLink,
  // })

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conformPassword: "", // Updated initial values
    referralCode: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is Required")
      .email("Invalid Email")
      .max(60, "Email cannot be longer than 60 characters"),

    firstName: Yup.string()
      .max(15, "First Name cannot be longer than 15 characters")
      .matches(/^\S*$/, "First Name cannot contain spaces")
      .matches(/^[A-Za-z]+$/, "First Name can only contain letters")
      .required("First Name is Required"),

    lastName: Yup.string()
      .max(30, "Last Name cannot be longer than 30 characters")
      .matches(/^\S*$/, "Last Name cannot contain spaces")
      .matches(/^[A-Za-z]+$/, "Last Name can only contain letters")
      .required("Last Name is Required"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one digit")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is Required"),

    conformPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is Required"),

    referralCode: Yup.string(),
  });

  const handleSubmit = async (values) => {
    if (fullUrl === `/register/${id}`) {
      values.referralCode = `${id}`;
    }
    //captcha IMPL
    setRecaptchaError(undefined);
    let captchaError = false;
    // setShowLoader(true);
    const token = captchaRef.current.getValue();
    // console.log("token", token);
    if (!token) {
      setRecaptchaError("Please solve the captcha");
      captchaError = true;
    } else {
      //validate the token
      await RecaptchaService.verify(token)
        .then((response) => {
          // console.log("success", response.data);
          if (response.data === true) {
            setRecaptchaError(undefined);
            captchaError = false;
          } else {
            setRecaptchaError("Captcha verification failed. Please try again.");
            captchaError = true;
            window.grecaptcha.reset();
          }
        })
        .catch((error) => {
          console.log("error", error);
          setRecaptchaError(
            "Error occurred while captcha verification. Please try again later."
          );
          captchaError = true;
        });
    }
    console.log("captchaError: ", captchaError);
    // Updated handleSubmit to use Formik values
    if (!captchaError) {
      setShowLoader(true);
      RegisterService.registerUser(values)
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            localStorage.setItem("accesstoken", response.data.token);
            localStorage.setItem("emailId", response.data.email);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem("isVerified", response.data.isVerified);
            localStorage.setItem("roleId", response.data.role.roleId);
            localStorage.setItem(
              "reffralCode",
              response.data.referralCode ? response.data.referralCode : ""
            );
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("emailSendSuccess", 1);
            Cookies.set("accesstoken", response.data.token, {
              expires: 7,
              secure: true,
              sameSite: "strict",
            });
            navigate("/");
          } else {
            setMessage("");
          }
        })
        .catch((error) => {
          setShowLoader(false);
          if (error.message === "Network Error") {
            setMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                //console.log("Internal server error while individual signup",error.response.data.failedReason);
                setMessage("Server error occured. Please try again later.");
                break;
              case 404:
                setMessage(error.response.data.failedReason);
                break;
              case 406:
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setMessage(error.response.data.failedReason);
                break;
              default:
                setMessage("Unknown error occured. Please try again later.");
                break;
            }
          }
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  };

  const handleSignupDealer = async (data) => {
    setShowLoader(true);
    //console.log("signup dealer object====", dealerSignupSubmit);
    //console.log("data-----for signup dealer==", data);
    //console.log("toggleRefferalCodeTwo==", toggleRefferalCodeTwo);
    //captcha IMPL
    setRecaptchaError(undefined);
    let captchaError = false;
    // setShowLoader(true);
    const token = captchaRef.current.getValue();
    const documentsId = JSON.parse(localStorage.getItem("documentsId"));
    // console.log("token", token);
    if (!token) {
      setRecaptchaError("Please solve the captcha");
      captchaError = true;
      setShowLoader(false);
    } else {
      setShowLoader(false);
      //validate the token
      await RecaptchaService.verify(token)
        .then((response) => {
          setShowLoader(false);
          // console.log("success", response.data);
          if (response.data === true) {
            setRecaptchaError(undefined);
            captchaError = false;
          } else {
            setRecaptchaError("Captcha verification failed. Please try again.");
            captchaError = true;
            window.grecaptcha.reset();
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.log("error", error);
          setRecaptchaError(
            "Error occurred while captcha verification. Please try again later."
          );
          captchaError = true;
        });
    }
    console.log("captchaError: ", captchaError);

    setSubmitDealerBtnDisabled(true);
    let signupData = {};
    if (sameCredentialChecked) {
      signupData = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        conformPassword: data.conformPassword,
        referralCode: data.referralCode,
        dealerId: data.dealerId,
        resendLink: false,
        sameCredential: sameCredentialChecked,
        vatNo: data.vatNo,
        companyRegNo: data.companyRegNo,
        postCode: data.postCode,
        houseNumber: data.houseNumber,
        houseName: data.houseName,
        streetName: data.streetName,
        locality: data.locality,
        town: data.town,
        city: data.city,
        country: data.country,
        contactNumber: data.contactNumber,
        phoneNumber: data.contactNumber,
      };
    } else {
      signupData = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.newPassword,
        conformPassword: data.rePassword,
        referralCode: data.referralCode,
        dealerId: data.dealerId,
        resendLink: false,
        sameCredential: sameCredentialChecked,
        vatNo: data.vatNo,
        companyRegNo: data.companyRegNo,
        postCode: data.postCode,
        houseNumber: data.houseNumber,
        houseName: data.houseName,
        streetName: data.streetName,
        locality: data.locality,
        town: data.town,
        city: data.city,
        country: data.country,
        contactNumber: data.contactNumber,
        phoneNumber: data.contactNumber,
      };
    }
    setDealerSignupSubmit(signupData);
    console.log("signupData==>", signupData);
    if (!captchaError) {
      RegisterService.registerDealerUser(signupData)
        .then((response) => {
          setSubmitDealerBtnDisabled(false);
          if (response.status === 200) {
            localStorage.setItem("accesstoken", response.data.token);
            localStorage.setItem("emailId", response.data.email);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem("isVerified", response.data.isVerified);
            localStorage.setItem("roleId", response.data.role.roleId);
            localStorage.setItem(
              "reffralCode",
              response.data.referralCode ? response.data.referralCode : ""
            );
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("welcomePopup", 1);
            localStorage.setItem("isAcknowledge", response.data.isAcknowledge);
            if (documentsId !== undefined) {
              TermsAndConditionService.updateDocumentId(
                signupDealerEmail,
                documentsId.tNcDocId,
                documentsId.dealerDocId
              )
                .then((response) => {
                  if (response.status === 200) {
                    navigate("/");
                  }
                })
                .catch((response) => {});
            }
          } else {
            setDealerMessage("");
          }
        })
        .catch((error) => {
          setSubmitDealerBtnDisabled(false);
          if (error.message === "Network Error") {
            setDealerMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setDealerMessage(error.response.data.failedReason);
                break;
              case 403:
                setDealerMessage(error.response.data.failedReason);
                break;
              case 500:
                //console.log("Internal server error while individual signup",error.response.data.failedReason);
                setDealerMessage(
                  "Server error occured. Please try again later."
                );
                break;
              case 404:
                setDealerMessage(error.response.data.failedReason);
                break;
              case 406:
                //console.log("invalid dealer", error.response.data.failedReason);

                setDealerMessage(error.response.data.failedReason);
                //console.log("dealer message==", dealerMessage);
                break;

              case 412:
                setDealerMessage(error.response.data.failedReason);
                break;
              default:
                setDealerMessage(
                  "Unknown error occured. Please try again later."
                );
                break;
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleReferalCodeOne = (setFieldValue) => {
    if (toggleRefferalCodeOne) {
      setFieldValue("referralCode", "");
    }
    setToggleRefferalCodeOne(!toggleRefferalCodeOne);
  };
  const handleReferalCodeTwo = () => {
    if (toggleRefferalCodeTwo) {
      //console.log("consle for refferel code==", signupDealerReferrelCode);
      //console.log("toggleRefferalCode Two refferel code==",toggleRefferalCodeTwo);

      setSignupDealerReferrelCode(null);
    }
    setToggleRefferalCodeTwo(!toggleRefferalCodeTwo);
  };
  const handlePasswordGroupToggle = () => {
    setSameCredentialChecked(!sameCredentialChecked);
    setTogglePasswordGroup(!togglePasswordGroup);
    //console.log("sameCredentialChecked2:", sameCredentialChecked);
  };

  useEffect(() => {
    if (!modalSliceState) {
      setOpen(false);
      // localStorage.removeItem("kioskSelectedPlanToBuy");
      // setEmailError("");
      // setEmail();
      setSeconds(30); // Reset the countdown timer to 30 seconds
      clearInterval(intervalId); // Clear the interval for verification API calls
      setCount(0); // Reset the count to 0
    }
  }, [modalSliceState]);
  const backClick = () => {
    // //console.log("here");
    setCount(0);
    clearInterval(intervalId);
  };
  const [resendClicked, setResendClicked] = useState(false);

  const handleVerify = (data) => {
    //console.log("data  in verify dealer ----", data);
    //console.log("dealer  in verify dealer ----", dealerId);

    if (!dealerId) {
      setDealerMessage("Dealer Id is required");
      return;
    }

    if (isNaN(dealerId)) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    if (dealerId <= 0) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    const dealerData = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      conformPassword: "",
      // dealerId: Number(data.dealerId),
      dealerId: dealerId,
      referralCode: "",
      resendLink: false,
      sameCredential: false,
    };

    setVerifyBtnDisabled(true);
    setDealerId(dealerId);
    // setStepOne(false);
    // setStepTwo(true);
    console.log("dealer data inside====", dealerData);

    // setShowLoader(true);
    DealerSignupService.dealerSignup(dealerData)

      .then((response) => {
        // setShowLoader(false);
        console.log("response=====", response);
        setVerifyBtnDisabled(false);
        setDealerMessage("");

        if (response.data.status === "success") {
          //console.log("response====inside =", response.data);
          // setFailedValue(0);
          // dispatch(openModal())
          // localStorage.setItem("token", response.data.token);
          // localStorage.setItem("emailId", response.data.email);
          // localStorage.setItem("name", response.data.name);
          // localStorage.setItem("id", response.data.id);
          // localStorage.setItem("isVerified", response.data.isVerified);
          // localStorage.setItem("loggedIn", true);
          // navigate("/");
          setCount(2);
          dispatch(openModal("verificationPending"));

          setSeconds(30);
          let totalTime = 0;
          const interval = setInterval(() => {
            // Call the API every 10 seconds
            // const kioskDetails = localStorage.getItem("kioskLoginDetails")
            //   ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
            //   : undefined;
            //console.log("insideeeeeeee      ");
            VerificationService.getVerificationStatus(dealerId)
              .then((response1) => {
                console.log("response1===", response1.data);
                setDealerMessage("");
                //console.log("response 1 ====", response1);
                //console.log("response 1 ffff====", response1.status);
                //console.log("response 1 data====", response1.data);

                if (response1.status === 200) {
                  if (response1.data.isVerified === 0) {
                    // Continue calling every 10 seconds for 30 seconds
                    totalTime += 10;
                    if (totalTime >= 30) {
                      clearInterval(interval);
                      if (response1.data.isVerified === 0) {
                        //console.log(response1.data);
                        setCount(3);
                        // document.getElementById(
                        //   "resend"
                        // ).style.pointerEvents = "auto";
                      }
                      dispatch(closeModal("verificationPending"));
                      //for button
                      clearInterval(interval);
                      setIsResetButton(true);
                      setDealerId(dealerId);
                    }
                  } else if (response1.data.isVerified === 1) {
                    console.log("response1===", response1.data);
                    // Redirect to checkout page
                    dispatch(closeModal("verificationPending"));
                    clearInterval(interval);
                    // setStepTwo(true);
                    setDealerSignup(true);

                    setAccountType(2);
                    setFirstAcc(false);
                    setSecondAcc(true);
                    setStepOne(true);
                    setStepTwo(true);
                    setReadonlyDealer(true);

                    setDealerId(dealerId);
                    // setSignupDealerId(dealerId)
                    setSignupDealerFirstName(response1.data.firstName);
                    setSignupDealerLastName(response1.data.lastName);
                    setSignupDealerEmail(response1.data.email);
                    setSignupDealerUsername(response1.data.username);
                    setSignupDealerPassword(response1.data.password);
                    setSignupDealerConfPassword(response1.data.password);
                    setSignupDealerHouseno(response1.data.houseNumber);
                    setSignupDealerHouseName(response1.data.houseName);
                    setSignupDealerStreetName(response1.data.streetName);
                    setSignupDealerLocality(response1.data.locality);
                    setSignupDealerTown(response1.data.town);
                    setSignupDealerCity(response1.data.city);
                    setSignupDealerCountry(response1.data.country);
                    setSignupDealerPostcode(response1.data.postCode);
                    setSignupDealerVatNo(response1.data.vatNo);
                    setSignupDealerCompanyRegNo(response1.data.companyRegNo);
                    setSignupDealerId(dealerId);
                    setSignupDealerDealerName(response1.data.dealerName);
                    setSignupDealerContactno(response1.data.contactNumber);

                    setValue("firstName", response1.data.firstName, {
                      shouldValidate: true,
                    });
                    setValue("lastName", response1.data.lastName, {
                      shouldValidate: true,
                    });
                    setValue("email", response1.data.email, {
                      shouldValidate: true,
                    });
                    setValue("password", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("conformPassword", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("referralCode", null, { shouldValidate: true });
                    setValue("dealerId", dealerId, { shouldValidate: true });
                    setValue("houseNumber", response1.data.houseNumber, {
                      shouldValidate: true,
                    });
                    setValue("houseName", response1.data.houseName, {
                      shouldValidate: true,
                    });
                    setValue("streetName", response1.data.streetName, {
                      shouldValidate: true,
                    });
                    setValue("locality", response1.data.locality, {
                      shouldValidate: true,
                    });
                    setValue("town", response1.data.town, {
                      shouldValidate: true,
                    });
                    setValue("city", response1.data.city, {
                      shouldValidate: true,
                    });
                    setValue("country", response1.data.country, {
                      shouldValidate: true,
                    });
                    setValue("postCode", response1.data.postCode, {
                      shouldValidate: true,
                    });
                    setValue("vatNo", response1.data.vatNo, {
                      shouldValidate: true,
                    });
                    setValue("companyRegNo", response1.data.companyRegNo, {
                      shouldValidate: true,
                    });
                    setValue("dealerName", response1.data.dealerName, {
                      shouldValidate: true,
                    });
                    setValue("contactNumber", response1.data.contactNumber, {
                      shouldValidate: true,
                    });
                    // kisoskSTWPrizeAvailable(response.data.id);
                  }
                }
              })
              // })
              .catch((error) => {
                setVerifyBtnDisabled(false);
                setShowLoader(false);
                if (error.message === "Network Error") {
                  setDealerMessage(
                    "Something went wrong, Please try again later"
                  );
                } else {
                  // Handle other errors
                  switch (error.response ? error.response.status : "") {
                    case 401:
                      // Handle 401 error
                      // localStorage.clear();
                      // localStorage.setItem("kioskSessionExpired", true);
                      // history.push("/kiosk/login");
                      break;
                    case 403:
                      // Handle 403 error
                      break;
                    // Handle other error cases
                    default:
                      setDealerMessage(
                        "Something went wrong, Please try again later"
                      );
                      break;
                  }
                }
              });
          }, 10000); // Repeat every 10 seconds

          setIntervalId(interval);
        } else if (response.data.failedValue === 46) {
          //console.log("======failed value 46==");
          setVerifyBtnDisabled(false);
          setFailedValue(46);
          setResendLink(true);
          setIsResetButton(true);
          dispatch(openModal("verificationPending"));
        } else {
          setDealerMessage(undefined);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setVerifyBtnDisabled(false);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 500:
              //console.log("Internal server error while individual signup",error.response.data.failedReason);
              setDealerMessage("Server error occured. Please try again later.");
              break;
            case 404:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 406:
              //console.log("invalid dealer", error.response.data.failedReason);
              setDealerMessage(error.response.data.failedReason);
              //console.log("dealer message==", dealerMessage);
              break;

            case 412:
              setDealerMessage(error.response.data.failedReason);
              break;
            default:
              setDealerMessage(
                "Unknown error occured. Please try again later."
              );
              break;
          }
        }
      });
    // .finally(() => {
    //   setShowLoader(false);
    // });
  };

  const handleResend = (data) => {
    //console.log("handle resend dealer id===", dealerId);
    if (!dealerId) {
      setDealerMessage("Dealer Id is required");
      return;
    }

    if (isNaN(dealerId)) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    if (dealerId <= 0) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    const dealerData = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      conformPassword: "",
      dealerId: dealerId,
      referralCode: "",
      resendLink: true,
      sameCredential: false,
    };

    setResendBtnDisabled(true);
    // setStepOne(false);
    // setStepTwo(true);
    //console.log("dealer data inside====", dealerData);

    // setShowLoader(true);
    DealerSignupService.dealerSignup(dealerData)

      .then((response) => {
        // setShowLoader(false);
        //console.log("response=====", response);
        setResendBtnDisabled(false);
        setDealerMessage("");

        if (response.data.status === "success") {
          //console.log("response====inside =", response.data);
          // setFailedValue(0);
          // dispatch(openModal())
          // localStorage.setItem("token", response.data.token);
          // localStorage.setItem("emailId", response.data.email);
          // localStorage.setItem("name", response.data.name);
          // localStorage.setItem("id", response.data.id);
          // localStorage.setItem("isVerified", response.data.isVerified);
          // localStorage.setItem("loggedIn", true);
          // navigate("/");
          setCount(2);
          dispatch(openModal("verificationPending"));

          setSeconds(30);
          let totalTime = 0;
          const interval = setInterval(() => {
            // Call the API every 10 seconds
            // const kioskDetails = localStorage.getItem("kioskLoginDetails")
            //   ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
            //   : undefined;
            //console.log("insideeeeeeee      ");
            VerificationService.getVerificationStatus(dealerId)
              .then((response1) => {
                // console.log("response 1 22====", response1.data);
                //console.log("response 1 ffff====", response1.status);
                //console.log("response 1 data====", response1.data);
                setDealerMessage("");
                if (response1.status === 200) {
                  if (response1.data.isVerified === 0) {
                    // Continue calling every 10 seconds for 30 seconds
                    totalTime += 10;
                    if (totalTime >= 30) {
                      clearInterval(interval);
                      if (response1.data.isVerified === 0) {
                        //console.log(response1.data);
                        setCount(3);
                        // document.getElementById(
                        //   "resend"
                        // ).style.pointerEvents = "auto";
                      }
                      dispatch(closeModal("verificationPending"));
                    }
                  } else if (response1.data.isVerified === 1) {
                    // Redirect to checkout page
                    dispatch(closeModal("verificationPending"));
                    clearInterval(interval);
                    setStepTwo(true);
                    setReadonlyDealer(true);

                    setDealerSignup(true);
                    setIsResetButton(false);

                    setAccountType(2);
                    setFirstAcc(false);
                    setSecondAcc(true);
                    setStepOne(true);
                    setStepTwo(true);
                    setReadonlyDealer(true);
                    setDealerId(dealerId);
                    // setSignupDealerId(dealerId)
                    setSignupDealerFirstName(response1.data.firstName);
                    setSignupDealerLastName(response1.data.lastName);
                    setSignupDealerEmail(response1.data.email);
                    setSignupDealerUsername(response1.data.username);
                    setSignupDealerPassword(response1.data.password);
                    setSignupDealerConfPassword(response1.data.password);
                    setSignupDealerHouseno(response1.data.houseNumber);
                    setSignupDealerHouseName(response1.data.houseName);
                    setSignupDealerStreetName(response1.data.streetName);
                    setSignupDealerLocality(response1.data.locality);
                    setSignupDealerTown(response1.data.town);
                    setSignupDealerCity(response1.data.city);
                    setSignupDealerCountry(response1.data.country);
                    setSignupDealerPostcode(response1.data.postCode);
                    setSignupDealerVatNo(response1.data.vatNo);
                    setSignupDealerCompanyRegNo(response1.data.companyRegNo);
                    setSignupDealerId(dealerId);
                    setSignupDealerDealerName(response1.data.dealerName);
                    setSignupDealerContactno(response1.data.contactNumber);

                    setValue("firstName", response1.data.firstName, {
                      shouldValidate: true,
                    });
                    setValue("lastName", response1.data.lastName, {
                      shouldValidate: true,
                    });
                    setValue("email", response1.data.email, {
                      shouldValidate: true,
                    });
                    setValue("password", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("conformPassword", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("referralCode", null, { shouldValidate: true });
                    setValue("dealerId", dealerId, { shouldValidate: true });
                    setValue("houseNumber", response1.data.houseNumber, {
                      shouldValidate: true,
                    });
                    setValue("houseName", response1.data.houseName, {
                      shouldValidate: true,
                    });
                    setValue("streetName", response1.data.streetName, {
                      shouldValidate: true,
                    });
                    setValue("locality", response1.data.locality, {
                      shouldValidate: true,
                    });
                    setValue("town", response1.data.town, {
                      shouldValidate: true,
                    });
                    setValue("city", response1.data.city, {
                      shouldValidate: true,
                    });
                    setValue("country", response1.data.country, {
                      shouldValidate: true,
                    });
                    setValue("postCode", response1.data.postCode, {
                      shouldValidate: true,
                    });
                    setValue("vatNo", response1.data.vatNo, {
                      shouldValidate: true,
                    });
                    setValue("companyRegNo", response1.data.companyRegNo, {
                      shouldValidate: true,
                    });
                    setValue("dealerName", response1.data.dealerName, {
                      shouldValidate: true,
                    });
                    setValue("contactNumber", response1.data.contactNumber, {
                      shouldValidate: true,
                    });
                  }
                }
              })
              // })
              .catch((error) => {
                setResendBtnDisabled(false);
                setShowLoader(false);
                if (error.message === "Network Error") {
                  setDealerMessage(
                    "Something went wrong, Please try again later"
                  );
                } else {
                  // Handle other errors
                  switch (error.response ? error.response.status : "") {
                    case 401:
                      // Handle 401 error
                      // localStorage.clear();
                      // localStorage.setItem("kioskSessionExpired", true);
                      // history.push("/kiosk/login");
                      break;
                    case 403:
                      // Handle 403 error
                      break;
                    // Handle other error cases
                    default:
                      setDealerMessage(
                        "Something went wrong, Please try again later"
                      );
                      break;
                  }
                }
              });
          }, 10000); // Repeat every 10 seconds

          setIntervalId(interval);
        } else {
          setDealerMessage(undefined);
        }
      })
      .catch((error) => {
        setResendBtnDisabled(false);
        setShowLoader(false);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 500:
              //console.log("Internal server error while individual signup",error.response.data.failedReason);
              setDealerMessage("Server error occured. Please try again later.");
              break;
            case 404:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 406:
              //console.log("invalid dealer", error.response.data.failedReason);
              setDealerMessage(error.response.data.failedReason);
              //console.log("dealer message==", dealerMessage);
              break;

            case 412:
              setDealerMessage(error.response.data.failedReason);
              break;
            default:
              setDealerMessage(
                "Unknown error occured. Please try again later."
              );
              break;
          }
        }
      });
  };

  const checkVerificationStatus = (data) => {
    //console.log("console for custome dealer id===", dealerId);
    setShowLoader(true);
    setVerifyBtnDisabled(true);
    //console.log("console for custome dealer id===", dealerId);
    VerificationService.getVerificationStatus(dealerId)
      .then((response1) => {
        setShowLoader(false);
        setVerifyBtnDisabled(false);

        //console.log("response 1 ====", response1);
        //console.log("response 1 ffff====", response1.status);
        console.log("response 1 data====", response1.data);

        if (response1.status === 200) {
          if (response1.data.isVerified === 0) {
            setDealerMessage("Dealer is not verified yet. Please check email.");
          } else if (response1.data.isVerified === 1) {
            setDealerMessage("");

            // Redirect to checkout page
            dispatch(closeModal("verificationPending"));
            // clearInterval(interval);
            // setStepTwo(true);
            setDealerSignup(true);
            setIsResetButton(false);

            setAccountType(2);
            setFirstAcc(false);
            setSecondAcc(true);
            setStepOne(true);
            setStepTwo(true);
            setReadonlyDealer(true);
            setDealerId(dealerId);
            // setSignupDealerId(dealerId)
            setSignupDealerFirstName(response1.data.firstName);
            setSignupDealerLastName(response1.data.lastName);
            setSignupDealerEmail(response1.data.email);
            setSignupDealerUsername(response1.data.username);
            setSignupDealerPassword(response1.data.password);
            setSignupDealerConfPassword(response1.data.password);
            setSignupDealerHouseno(response1.data.houseNumber);
            setSignupDealerHouseName(response1.data.houseName);
            setSignupDealerStreetName(response1.data.streetName);
            setSignupDealerLocality(response1.data.locality);
            setSignupDealerTown(response1.data.town);
            setSignupDealerCity(response1.data.city);
            setSignupDealerCountry(response1.data.country);
            setSignupDealerPostcode(response1.data.postCode);
            setSignupDealerVatNo(response1.data.vatNo);
            setSignupDealerCompanyRegNo(response1.data.companyRegNo);
            setSignupDealerId(dealerId);
            setSignupDealerDealerName(response1.data.dealerName);
            setSignupDealerContactno(response1.data.contactNumber);

            setValue("firstName", response1.data.firstName, {
              shouldValidate: true,
            });
            setValue("lastName", response1.data.lastName, {
              shouldValidate: true,
            });
            setValue("email", response1.data.email, { shouldValidate: true });
            setValue("password", response1.data.password, {
              shouldValidate: true,
            });
            setValue("conformPassword", response1.data.password, {
              shouldValidate: true,
            });
            setValue("referralCode", null, { shouldValidate: true });
            setValue("dealerId", dealerId, { shouldValidate: true });
            setValue("houseNumber", response1.data.houseNumber, {
              shouldValidate: true,
            });
            setValue("houseName", response1.data.houseName, {
              shouldValidate: true,
            });
            setValue("streetName", response1.data.streetName, {
              shouldValidate: true,
            });
            setValue("locality", response1.data.locality, {
              shouldValidate: true,
            });
            setValue("town", response1.data.town, {
              shouldValidate: true,
            });
            setValue("city", response1.data.city, {
              shouldValidate: true,
            });
            setValue("country", response1.data.country, {
              shouldValidate: true,
            });
            setValue("postCode", response1.data.postCode, {
              shouldValidate: true,
            });
            setValue("vatNo", response1.data.vatNo, {
              shouldValidate: true,
            });
            setValue("companyRegNo", response1.data.companyRegNo, {
              shouldValidate: true,
            });
            setValue("dealerName", response1.data.dealerName, {
              shouldValidate: true,
            });
            setValue("contactNumber", response1.data.contactNumber, {
              shouldValidate: true,
            });
            // kisoskSTWPrizeAvailable(response.data.id);
          }
        }
      })
      // })
      .catch((error) => {
        setShowLoader(false);
        setVerifyBtnDisabled(false);
        if (error.message === "Network Error") {
          setDealerMessage("Something went wrong, Please try again later");
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              // Handle 401 error
              // localStorage.clear();
              // localStorage.setItem("kioskSessionExpired", true);
              // history.push("/kiosk/login");
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              // Handle 403 error
              break;
            // Handle other error cases
            default:
              setDealerMessage("Something went wrong, Please try again later");
              break;
          }
        }
      });
  };
  // //for dealer verification
  // //console.log("id in rgister====",id);
  // useEffect(() => {
  //   if (id !== undefined && id !== null && id !== "") {
  //     setShowLoader(true)
  //     //console.log("idd====", id);
  //     VerificationService.doVerification(id)
  //       .then((response) => {
  //         setShowLoader(false);
  //         //console.log("response=====", response.data);
  //         //console.log("response tttt=====", response.status);

  //         if (response.status === 200) {
  //           localStorage.setItem("accesstoken", response.data.token);
  //           localStorage.setItem("emailId", response.data.email);
  //           localStorage.setItem("name", response.data.name);
  //           localStorage.setItem("username", response.data.id);

  //           setPMessaage("login successful");
  //           navigate("/");

  //         } else if (response.status === 202) {
  //           // localStorage.setItem("dealerAccesstoken", response.data.token);
  //           // localStorage.setItem("dealerEmailId", response.data.email);
  //           // localStorage.setItem("dealerFirstName", response.data.firstName);
  //           // localStorage.setItem("dealerLastName", response.data.lastName);
  //           // localStorage.setItem("dealerId", response.data.dealerId);
  //           // localStorage.setItem("dealerPassword", response.data.password);
  //           // localStorage.setItem("dealerUsername", response.data.username);
  //           // localStorage.setItem("dealerSignup", response.data.dealerSignup);
  //           // setLocalStorageValue(true);
  //           //console.log("rsponse 202=====");
  //           navigate("/VerificationResponse");

  //         }
  //         else {
  //           setPMessaage("");
  //         }
  //       })
  //       .catch((error) => {
  //         setShowLoader(false);
  //         if (error.message === "Network Error") {
  //           setMessage("Network Error");
  //         } else {
  //           //console.log("errrrroorrr====", error.response);

  //           switch (error.response ? error.response.status : "") {
  //             case 401:
  //               break;
  //             case 403:
  //               break;
  //             case 500:
  //             case 404:
  //             case 406:
  //               setMessage(error.response.data.failedReason);
  //               break;
  //             case 412:
  //               break;
  //             default:
  //               setMessage("unknown Error");
  //               break;
  //           }
  //         }
  //       })
  //     .finally(() => {
  //       setShowLoader(false);
  //     });

  //   }
  // }, []);

  // useEffect(() => {
  //   //console.log("inside useeffetc", localStorage);
  //   const dealerId = localStorage.getItem("dealerId");
  //   //console.log("deale riddddd===========", dealerId);
  //   const dealerFirstName = localStorage.getItem("dealerFirstName");
  //   const dealerLastName = localStorage.getItem("dealerLastName");
  //   const dealerEmailId = localStorage.getItem("dealerEmailId");
  //   const dealerUsername = localStorage.getItem("dealerUsername");
  //   const dealerPassword = localStorage.getItem("dealerPassword");
  //   const dealerSignup = localStorage.getItem("dealerSignup");
  //   // const dealerSignup = localStorage.getItem("dealerSignup");
  //   //console.log("isDealer==", dealerId);
  //   //console.log("dealerFirstName==", dealerFirstName);
  //   //console.log("dealerSignup==", dealerSignup);
  //   if (dealerId !== undefined && dealerId !== null && dealerId !== "" && dealerId != 0) {
  //     //console.log("dealerFirstName==", dealerFirstName);
  //     setAccountType(2);
  //     setFirstAcc(false);
  //     setSecondAcc(true);
  //     setStepOne(true);
  //     setStepTwo(true);
  //     setDealerId(dealerId);
  //     // setSignupDealerId(dealerId)
  //     setSignupDealerFirstName(dealerFirstName);
  //     setSignupDealerLastName(dealerLastName);
  //     setSignupDealerEmail(dealerEmailId);
  //     setSignupDealerUsername(dealerUsername);
  //     setSignupDealerPassword(dealerPassword);
  //     setDealerSignup(true);
  //     setSignupDealerConfPassword(dealerPassword);
  //     setSignupDealerId(dealerId);

  //   }

  // }, [localStorageValue]);

  const validatePasswords = (value) => {
    return value == watch("newPassword") || "Passwords do not match";
  };

  const passwordValidationRules = {
    required: "Password is required",
    minLength: { value: 8, message: "Password must be at least 8 characters" },
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      message:
        "Password must include 1 uppercase, 1 lowercase, 1 number, and 1 symbol",
    },
  };

  // const handleSignupDealerForDealer = (data) => {
  // //console.log("Sign up dealer", data);
  // };
  // const handleResendForDealer = () => {
  // const dealerId = getValues("dealerId");
  // //console.log("Resend for dealerId:", dealerId);
  // };
  // const handleVerifyForDealer = () => {
  // const dealerId = getValues("dealerId");
  // //console.log("Resend for dealerId:", dealerId);
  // };
  // const checkVerificationStatusForDealer = () => {
  // const dealerId = getValues("dealerId");
  // //console.log("Check verification status for dealerId:", dealerId);
  // };
  // Form submit handler
  // const onDealerSubmit = (data, event) => {

  //   const res = data
  //   let buttonName = null;
  //   //console.log(event.target.children, "Button");

  //   if (event.target.tagName === 'button') {
  //       buttonName = event.target.name; // Accessing the 'name' property from the button element
  //   }

  //   //console.log("Button name:", buttonName);

  //   if (res) {
  //     // if (isResetButton) {
  //     //   handleResend();
  //     // } else {
  //     //   handleVerify(res);
  //     // }
  //     // event.preventDefault();

  //       //console.log(buttonName, "buttonType");
  //     switch (buttonName) {
  //       case "submitDealer":
  //         handleSignupDealer(data);
  //         break;
  //       case "verifyDealer":
  //         //console.log("inside buttn 22");

  //         handleVerify(res);
  //         break;
  //       case "resendDealer":
  //         handleResend(res);
  //         break;
  //       case "checkVerificationStatus":
  //         checkVerificationStatus(res);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  const getDocumentId = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/getTermAndConditionAndContractDetails`,
        {
          headers: {
            emailId: signupDealerEmail,
          },
        }
      );
      const tNcDocId = response.data.termAndCondition.termAndConditionId;
      const dealerDocId = response.data.dealerContract.dealerContractId;
      const documentsId = {
        tNcDocId: tNcDocId,
        dealerDocId: dealerDocId,
      };
      localStorage.setItem("documentsId", JSON.stringify(documentsId));
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    if (signupDealerEmail) {
      getDocumentId();
    }
  }, [signupDealerEmail]);
  return (
    <>
      {
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="mx-auto w-full max-w-sm md:max-w-md">
            <Link to="/">
              <img className="mx-auto h-[80px] w-auto" src={logo} alt="GKT" />
            </Link>
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text">
                Create Account
              </h2>
                <p className="text-lg font-medium mt-4">
                  Choose Your Sign Up Type
                </p>
            </div>
            {/* Account tabs */}
            <div className="border custom-border-full flex mt-2 mb-4 rounded-md p-[1px]">
              <button
                onClick={() => handleAccountType(1)}
                className={`w-full h-8 text-sm rounded-md py-1 px-2 ${
                  accountType === 1 ? "login-active" : ""
                }`}
              >
                Customer Sign Up
              </button>
              <button
                onClick={() => handleAccountType(2)}
                className={`w-full h-8 text-sm rounded-md py-1 px-2 ${
                  accountType === 2 ? "login-active" : ""
                }`}
              >
                Business Sign Up
              </button>
            </div>
          </div>
          <div className="mt-4 mx-auto w-full max-w-sm md:max-w-md">
            {firstAcc ? (
              <Formik
                initialValues={initialValues} // Added initial values
                validationSchema={validationSchema} // Added validation schema
                onSubmit={handleSubmit} // Added handleSubmit
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className="space-y-6">
                    {" "}
                    {/* Changed to Formik Form */}
                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-normal leading-6"
                        >
                          First Name*
                        </label>
                        <div className="mt-2">
                          <Field
                            id="firstName"
                            name="firstName" // Updated Field name
                            type="text"
                            autoComplete="first-name"
                            placeholder="Enter your first name"
                            className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                              errors.firstName && touched.firstName
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error-text "
                        />{" "}
                        {/* Added ErrorMessage */}
                      </div>
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-normal leading-6"
                        >
                          Last Name*
                        </label>
                        <div className="mt-2">
                          <Field
                            id="lastName"
                            name="lastName" // Updated Field name
                            type="text"
                            autoComplete="last-name"
                            placeholder="Enter your last name"
                            className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                              errors.lastName && touched.lastName
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error-text "
                        />{" "}
                        {/* Added ErrorMessage */}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-normal leading-6"
                      >
                        Email address*
                      </label>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email" // Updated Field name
                          type="email"
                          placeholder="Your e-mail address"
                          autoComplete="off"
                          className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                            errors.email && touched.email
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-text "
                      />{" "}
                      {/* Added ErrorMessage */}
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-normal leading-6"
                        >
                          Password*
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <Field
                          id="password"
                          name="password" // Updated Field name
                          type={togglePassword ? "text" : "password"}
                          autoComplete="off"
                          placeholder="Enter a password"
                          className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                            errors.password && touched.password
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <div
                          onClick={handletogglePassword}
                          className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                        >
                          {togglePassword ? <EyeSlashIcon /> : <EyeIcon />}
                        </div>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-text "
                      />{" "}
                      {/* Added ErrorMessage */}
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="conformPassword"
                          className="block text-sm font-normal leading-6"
                        >
                          Confirm your password*
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <Field
                          id="conformPassword"
                          name="conformPassword" // Updated Field name
                          type={toggleRePassword ? "text" : "password"}
                          autoComplete="off"
                          placeholder="Re-enter a password"
                          className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                            errors.conformPassword && touched.conformPassword
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <div
                          onClick={handletoggleRePassword}
                          className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                        >
                          {toggleRePassword ? <EyeSlashIcon /> : <EyeIcon />}
                        </div>
                      </div>
                      <ErrorMessage
                        name="conformPassword"
                        component="div"
                        className="error-text "
                      />{" "}
                      {/* Added ErrorMessage */}
                    </div>
                    <div
                      className={`${
                        referralUrl ? "pointer-events-none" : ""
                      } flex justify-start items-start`}
                    >
                      <label className="custom-checkbox">
                        <div>
                          <input
                            checked={toggleRefferalCodeOne}
                            // onChange={handleReferalCodeOne}
                            onChange={() => handleReferalCodeOne(setFieldValue)}
                            type="checkbox"
                            className={`focus:ring-teal-600`}
                            id="refCode"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="refCode" className="font-medium">
                            Do you have a referral code?
                          </label>
                        </div>
                      </label>
                    </div>
                    <Transition
                      show={toggleRefferalCodeOne}
                      enter="transition-all duration-200"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-all duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div style={{ marginTop: 16 }}>
                        <label
                          htmlFor="referralCode"
                          className="block text-sm font-normal leading-6"
                        >
                          Referral Code
                        </label>
                        <div className="mt-2">
                          {/* <input
                              id="referralCode"
                              name="referralCode"
                              type="text"
                              placeholder="Enter your referral Code"
                              className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                            /> */}
                          <Field
                            value={id}
                            id="referralCode"
                            name="referralCode" // Updated Field name
                            type="text"
                            placeholder="Enter your referral Code"
                            className={`${
                              referralUrl ? "readonly-field" : ""
                            } block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6`}
                          />
                        </div>
                        <ErrorMessage
                          name="referralCode"
                          component="div"
                          className="error-text "
                        />{" "}
                      </div>
                    </Transition>
                    {message ? <p className="error-text">{message}</p> : null}
                    <ReCAPTCHA
                      sitekey={REACT_APP_SITE_KEY}
                      ref={captchaRef}
                      onChange={() => setRecaptchaError(undefined)}
                    />
                    {recaptchaError && (
                      <span className="error-text">{recaptchaError}</span>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        Sign up
                      </button>
                      {isLoading && <p>Loading...</p>}
                    </div>
                  </Form>
                )}
              </Formik>
            ) : secondAcc ? (
              <form
                className="space-y-6"
                onSubmit={handleSubmitDealer(handleSignupDealer)}
              >
                <div>
                  <label
                    htmlFor="dealer-id"
                    className="block text-sm font-normal leading-6"
                  >
                    Dealer ID
                  </label>
                  <div className="mt-2">
                    <input
                      className={`${
                        readonlyDealer
                          ? "bg-teal-700/20 pointer-events-none readonly-field"
                          : ""
                      } block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus-visible:ring-teal-600 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6`}
                      // {...registerDealerValidation("dealerId", {
                      //   validate: validateDealerId
                      // })}

                      value={dealerId}
                      disabled={readonlyDealer ? true : false}
                      onChange={(e) => {
                        setDealerId(e.target.value);
                      }}
                      type="text"
                      id="dealerId"
                      // name="dealer-id"
                      // type="number"
                      autoComplete="off"
                      placeholder="Enter your dealer id"
                    />
                    {dealerError.dealerId && (
                      <p className="error-text ">
                        {dealerError.dealerId.message}
                      </p>
                    )}
                    {/* {
                    message ? <p className="error-text">{message}</p> : null
                  } */}
                  </div>
                </div>
                {dealerId !== undefined ? (
                  <>
                    <div>
                      {isResetButton ? (
                        <>
                          {dealerMessage ? (
                            <p className="error-text">{dealerMessage}</p>
                          ) : null}
                          {/* <button
                              type="button"
                              onClick={handleResend}
                              className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                              Resend
                            </button> */}
                          <div className="flex space-x-2">
                            <LoaderButton
                              type="button"
                              title="Resend"
                              name="resendDealer"
                              handleClick={handleResend}
                              style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              disabled={resendBtnDisabled}
                            />

                            <LoaderButton
                              type="button"
                              title="Check Verification Status"
                              name="checkVerificationStatusDealer"
                              handleClick={checkVerificationStatus}
                              style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              disabled={verifyBtnDisabled}
                            />
                          </div>
                        </>
                      ) : (
                        !dealerSignup && (
                          <>
                            {dealerMessage ? (
                              <p className="error-text">{dealerMessage}</p>
                            ) : null}

                            <LoaderButton
                              type="button"
                              title="Verify"
                              // name="verifyDealer"
                              handleClick={handleVerify}
                              style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              disabled={verifyBtnDisabled}
                            />
                            {/* <button
                                type="submit"
                                // onClick={handleVerify}
                                className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              >
                                Verify
                              </button> */}
                          </>
                        )
                      )}
                    </div>
                    {secondAcc && stepOne
                      ? !dealerSignup && (
                          <p className="mt-6 text-center text-sm text-gray-400 flex justify-center items-center">
                            <InformationCircleIcon className="w-4 mr-[0.35rem]" />
                            <span>
                              Enter your dealer id to verify your account
                            </span>
                          </p>
                        )
                      : null}
                  </>
                ) : null}
                {
                  // //console.log(dealerId, "dealerId from localstorage")
                }
                {dealerId !== null && stepTwo ? (
                  <div className="">
                    <label
                      htmlFor="dealerName"
                      className="block text-sm font-normal leading-6"
                    >
                      Dealer Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="dealerName"
                        value={signupDealerDealerName}
                        readOnly={!!dealerId}
                        onChange={(e) =>
                          setSignupDealerDealerName(e.target.value)
                        }
                        autoComplete="off"
                        type="text"
                        placeholder="Enter your Dealer name"
                        className={`block w-full rounded-md border custom-border-50 px-3 py-1.5 sm:text-sm sm:leading-6 ${
                          dealerId ? "bg-teal-700/20 pointer-events-none" : ""
                        }`}
                      />
                    </div>
                  </div>
                ) : null}
                {dealerId !== null && stepTwo ? (
                  <div className="">
                    <label
                      htmlFor="contactNumber"
                      className="block text-sm font-normal leading-6"
                    >
                      Contact Number*
                    </label>
                    <div className="mt-2">
                      <input
                        {...registerDealerValidation("contactNumber", {
                          required: "Contact Number is required",
                          pattern: {
                            value: /^[0-9]{10}$/, // Ensures exactly 10 digits
                            message: "Contact Number must be exactly 10 digits",
                          },
                        })}
                        id="contactNumber"
                        value={signupDealerContactno}
                        onChange={(e) => {
                          // Allow only numeric input
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          setSignupDealerContactno(value);
                        }}
                        maxLength={10}
                        autoComplete="off"
                        type="text"
                        placeholder="Enter your Contact Number"
                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                      />
                      {isSubmitted && dealerError.contactNumber && (
                        <p className="error-text">
                          {dealerError.contactNumber.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}

                {dealerId !== null && stepTwo ? (
                  <>
                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal leading-6"
                        >
                          First Name*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("firstName", {
                              required: "First Name is required",
                              maxLength: {
                                value: 15,
                                message:
                                  "First Name cannot be longer than 15 characters",
                              },
                              pattern: {
                                value: /^[^\s]*$/,
                                message: "First Name cannot contain spaces",
                              },
                              pattern: {
                                value: /^[A-Za-z]+$/, // Only allows letters
                                message: "First Name can only contain letters",
                              },
                            })}
                            id="firstName"
                            // value={signupDealerFirstName}
                            // onChange={(e) => {
                            //   setSignupDealerFirstName(e.target.value);
                            // }}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter your first name"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {/* {dealerError["firstName"] && (
                            <p className="error-text ">
                              {dealerError["firstName"].message}
                            </p>
                          )} */}
                          {isSubmitted && dealerError.firstName && (
                            <p className="error-text">
                              {dealerError.firstName.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-normal leading-6"
                        >
                          Last Name*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("lastName", {
                              required: "Last Name is required",
                              maxLength: {
                                value: 30,
                                message:
                                  "Last Name cannot be longer than 30 characters",
                              },
                              pattern: {
                                value: /^[^\s]*$/,
                                message: "Last Name cannot contain spaces",
                              },
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Last Name can only contain letters",
                              },
                            })}
                            id="lastName"
                            // value={signupDealerLastName}
                            // onChange={(e) => {
                            //   setSignupDealerLastName(e.target.value);
                            // }}
                            // name="lastName"
                            type="text"
                            autoComplete="off"
                            placeholder="Your last name"
                            // required
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {/* {dealerError["lastName"] && (
                            <p className="error-text ">
                              {dealerError["lastName"].message}
                            </p>
                          )} */}
                          {isSubmitted && dealerError.lastName && (
                            <p className="error-text">
                              {dealerError.lastName.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        // htmlFor="email"
                        className="block text-sm font-normal leading-6"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          {...registerDealerValidation("email", {
                            required: "Email is required",
                            maxLength: {
                              value: 60,
                              message:
                                "Email cannot be longer than 60 characters",
                            },
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email format",
                            },
                          })}
                          id="email"
                          readOnly={dealerId !== undefined ? true : false}
                          value={signupDealerEmail}
                          onChange={(e) => {
                            setSignupDealerEmail(e.target.value);
                          }}
                          autoComplete="off"
                          // name="email"
                          type="email"
                          placeholder="Your e-mail address"
                          // autoComplete="email"
                          // required
                          className={`${
                            readonlyDealer
                              ? "bg-teal-700/20 pointer-events-none"
                              : ""
                          } "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"`}
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="vat-no"
                          className="block text-sm font-normal leading-6"
                        >
                          Vat No
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("vatNo", {
                              // required: "Vat No is required",
                              maxLength: {
                                value: 10,
                                message:
                                  "Vat No cannot be longer than 10 characters",
                              },
                              pattern: {
                                value: /^[^\s]*$/,
                                message: "Vat No cannot contain spaces",
                              },
                              pattern: {
                                value: /^[A-Za-z0-9]+$/, // Allows letters and numbers
                                message:
                                  "VAT No can only contain letters and numbers",
                              },
                            })}
                            id="vatNo"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter Vat No"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {/* {dealerError["firstName"] && (
                            <p className="error-text ">
                              {dealerError["firstName"].message}
                            </p>
                          )} */}
                          {isSubmitted && dealerError.vatNo && (
                            <p className="error-text">
                              {dealerError.vatNo.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-normal leading-6"
                        >
                          Company Reg No
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("companyRegNo", {
                              // required: "Company Reg No is required",
                              maxLength: {
                                value: 25,
                                message:
                                  "Company Reg No cannot be longer than 25 characters",
                              },
                              pattern: {
                                value: /^[^\s]*$/,
                                message: "Company Reg No cannot contain spaces",
                              },
                              pattern: {
                                value: /^[0-9]+$/, // Only allows numbers
                                message:
                                  "Company Reg No can only contain numbers",
                              },
                            })}
                            id="companyRegNo"
                            // value={signupDealerLastName}
                            // onChange={(e) => {
                            //   setSignupDealerLastName(e.target.value);
                            // }}
                            // name="lastName"
                            type="text"
                            autoComplete="off"
                            placeholder="Enter Company Reg No"
                            // required
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {/* {dealerError["lastName"] && (
                            <p className="error-text ">
                              {dealerError["lastName"].message}
                            </p>
                          )} */}
                          {isSubmitted && dealerError.companyRegNo && (
                            <p className="error-text">
                              {dealerError.companyRegNo.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-3/4 basis-1">
                        <label
                          htmlFor="postcode"
                          className="block text-sm font-normal leading-6"
                        >
                          Postcode*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("postCode", {
                              required: "Postcode is required",
                              maxLength: {
                                value: 8,
                                message:
                                  "Postcode cannot be longer than 8 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z0-9\s]+$/, // Allows letters, numbers, and spaces
                                message:
                                  "Postcode can only contain letters, numbers, and spaces",
                              },
                            })}
                            id="postCode"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter Postcode"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.postCode && (
                            <p className="error-text">
                              {dealerError.postCode.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="houseNumber"
                          className="block text-sm font-normal leading-6"
                        >
                          Shop Number
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("houseNumber", {
                              validate: (value) => {
                                const houseNameValue = getValues("houseName");
                                if (!value && !houseNameValue) {
                                  return "Either Shop Number or Shop Name is required.";
                                }
                                if (value && value.length > 50) {
                                  return "Shop Number cannot be longer than 50 characters";
                                }
                                if (value && !/^[A-Za-z0-9\s]+$/.test(value)) {
                                  return "Shop Number can only contain letters and numbers";
                                }
                                return true;
                              },
                            })}
                            id="houseNumber"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter Shop Number"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.houseNumber && (
                            <p className="error-text">
                              {dealerError.houseNumber.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="houseName"
                          className="block text-sm font-normal leading-6"
                        >
                          Shop Name / Building Name*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("houseName", {
                              validate: (value) => {
                                const houseNumberValue =
                                  getValues("houseNumber");
                                if (!value && !houseNumberValue) {
                                  return "Either Shop Name or Shop Number is required.";
                                }
                                if (value && value.length > 50) {
                                  return "House Name cannot be longer than 50 characters";
                                }
                                if (value && !/^[A-Za-z0-9\s]+$/.test(value)) {
                                  return "House Name can only contain letters and numbers";
                                }
                                return true;
                              },
                            })}
                            id="houseName"
                            autoComplete="off"
                            type="text"
                            placeholder=" Enter Shop/ Building Name"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.houseName && (
                            <p className="error-text">
                              {dealerError.houseName.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-3">
                      {/* Street Name */}
                      <div className="w-full">
                        <label
                          htmlFor="streetName"
                          className="block text-sm font-normal leading-6"
                        >
                          Street Name*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("streetName", {
                              required: "Street Name is required",
                              maxLength: {
                                value: 50,
                                message:
                                  "Street Name cannot be longer than 50 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z0-9\s]+$/, // Allows letters, numbers, and spaces
                                message:
                                  "Street Name can only contain letters, numbers, and spaces",
                              },
                            })}
                            id="streetName"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter Street Name"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.streetName && (
                            <p className="error-text">
                              {dealerError.streetName.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-3">
                      {/* Locality */}
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="locality"
                          className="block text-sm font-normal leading-6"
                        >
                          Locality*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("locality", {
                              required: "Locality is required",
                              maxLength: {
                                value: 50,
                                message:
                                  "Locality cannot be longer than 50 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z0-9\s]+$/, // Allows letters, numbers, and spaces
                                message:
                                  "Locality can only contain letters, numbers, and spaces",
                              },
                            })}
                            id="locality"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter Locality"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.locality && (
                            <p className="error-text">
                              {dealerError.locality.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* City */}
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="city"
                          className="block text-sm font-normal leading-6"
                        >
                          City*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("city", {
                              required: "City is required",
                              maxLength: {
                                value: 50,
                                message:
                                  "City cannot be longer than 50 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z\s]+$/, // Only allows letters and spaces
                                message:
                                  "City can only contain letters and spaces",
                              },
                            })}
                            id="city"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter City"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.city && (
                            <p className="error-text">
                              {dealerError.city.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-3">
                      {/* Country */}
                      <div className="md:basis-3/4 basis-1">
                        <label
                          htmlFor="country"
                          className="block text-sm font-normal leading-6"
                        >
                          Country*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("country", {
                              required: "Country is required",
                              maxLength: {
                                value: 50,
                                message:
                                  "Country cannot be longer than 50 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z\s]+$/, // Only allows letters and spaces
                                message:
                                  "Country can only contain letters and spaces",
                              },
                            })}
                            id="country"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter Country"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {isSubmitted && dealerError.country && (
                            <p className="error-text">
                              {dealerError.country.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-start items-start">
                      <label className="custom-checkbox">
                        <div>
                          <input
                            checked={sameCredentialChecked}
                            onChange={handlePasswordGroupToggle}
                            type="checkbox"
                            className="focus:ring-teal-600"
                            id="sameCred"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="sameCred" className="font-medium">
                            Continue with the same credentials
                          </label>
                        </div>
                      </label>
                    </div>
                    {togglePasswordGroup && (
                      <>
                        <div>
                          <div className="flex items-center justify-between">
                            <label
                              htmlFor="password"
                              className="block text-sm font-normal leading-6"
                            >
                              New Password*
                            </label>
                          </div>
                          <div className="mt-2 relative">
                            <input
                              {...registerDealerValidation(
                                "newPassword",
                                passwordValidationRules
                              )}
                              id="newPassword"
                              // id="password"
                              onChange={(e) => {
                                setDealerNewPassword(e.target.value);
                              }}
                              placeholder="Create a new password"
                              // name="password"
                              type={togglePassword ? "text" : "password"}
                              // autoComplete="current-password"
                              className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              // className="placeholder:font-normal font-medium block w-full rounded-md border-0 py-1.5 bg-transparent primary-text shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 pr-7"
                            />
                            <div
                              onClick={handletogglePassword}
                              className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                            >
                              {togglePassword ? <EyeSlashIcon /> : <EyeIcon />}
                            </div>
                          </div>
                          {dealerError.newPassword && (
                            <p className="error-text ">
                              {dealerError.newPassword.message}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <label
                              htmlFor="rePassword"
                              className="block text-sm font-normal leading-6"
                            >
                              Confirm your password
                            </label>
                          </div>
                          <div className="mt-2 relative">
                            <input
                              {...registerDealerValidation("rePassword", {
                                required: "Password is required",
                                validate: validatePasswords,
                              })}
                              // {...registerDealerValidation("rePassword", {
                              //   required: "Password is required",
                              //   validate: validatePasswords,
                              // })}
                              // value={signupDealerPassword}
                              id="rePassword"
                              // id="rePassword"
                              onChange={(e) => {
                                setDealerNewConfirmPassword(e.target.value);
                              }}
                              // name="rePassword"
                              type={toggleRePassword ? "text" : "password"}
                              // autoComplete="current-password"
                              placeholder="Re-enter a password"
                              className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                            />
                            <div
                              onClick={handletoggleRePassword}
                              className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                            >
                              {toggleRePassword ? (
                                <EyeSlashIcon />
                              ) : (
                                <EyeIcon />
                              )}
                            </div>
                          </div>
                          {dealerError.rePassword && (
                            <p className="error-text ">
                              {dealerError.rePassword.message}
                            </p>
                          )}
                        </div>
                      </>
                    )}

                    <div className="flex justify-start items-start">
                      <label className="custom-checkbox">
                        <div>
                          <input
                            checked={toggleRefferalCodeTwo}
                            onChange={handleReferalCodeTwo}
                            type="checkbox"
                            className="focus:ring-teal-600"
                            id="refCode"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="refCode" className="font-medium">
                            Do you have a referral code?
                          </label>
                        </div>
                      </label>
                    </div>
                    <Transition
                      show={toggleRefferalCodeTwo}
                      enter="transition-all duration-200"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-all duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div style={{ marginTop: 16 }}>
                        <label
                          htmlFor="referralCode"
                          className="block text-sm font-normal leading-6"
                        >
                          Referral Code
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("referralCode")}
                            id="referralCode"
                            onChange={(e) => {
                              //console.log("e    ==", e, toggleRefferalCodeTwo);
                              toggleRefferalCodeTwo
                                ? setSignupDealerReferrelCode(e.target.value)
                                : setSignupDealerReferrelCode(null);
                              // setSignupDealerReferrelCode(e.target.value)

                              //console.log("checkbox value==",toggleRefferalCodeTwo);
                              //console.log("signupDealerReferrelCode value==",signupDealerReferrelCode);
                            }}
                            name="referralCode"
                            type="text"
                            placeholder="Enter your referral Code"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </Transition>
                    <ReCAPTCHA
                      sitekey={REACT_APP_SITE_KEY}
                      ref={captchaRef}
                      onChange={() => setRecaptchaError(undefined)}
                    />
                    {recaptchaError && (
                      <span className="error-text">{recaptchaError}</span>
                    )}
                    {/* TERMS AND CONDITION */}
                    <div className="my-4 flex flex-col items-start gap-2">
                      <div className="flex">
                        {/* <button
                          // onClick={() => handleCheckboxClick("threeDoc")}
                          className={`dummy-checkbox relative mr-[10px] !mt-0`}
                        >
                          {threeDocClicked ? (
                            <span className="dummy-checked"></span>
                          ) : null}
                        </button> */}
                        <input
                          type="checkbox"
                          {...registerDealerValidation("threeDoc", {
                            required:
                              "You must accept the Three Network Terms and Conditions",
                          })}
                          className={`dummy-checkbox relative mr-[10px] mt-1`}
                          // checked={threeDocClicked} // checkbox checked based on state
                          onChange={(e) => {
                            setValue("threeDoc", e.target.checked); // Update the form state
                            trigger("threeDoc"); // Trigger validation for this field
                          }}
                        />
                        <label
                          name="contract-info"
                          htmlFor="contract-info"
                          className="text-sm flex items-center flex-wrap"
                        >
                          Terms and Conditions for using the Three Network
                          <span
                            onClick={() => dispatch(openDocumentModal(2))}
                            className="inl underline hover:opacity-50 transition flex items-center ml-1 cursor-pointer font-semibold"
                          >
                            View
                            <RiShareBoxFill className="ml-1" />
                          </span>
                        </label>
                      </div>
                      {dealerError.threeDoc && (
                        <p className="error-text">
                          {dealerError.threeDoc.message}
                        </p>
                      )}
                      <div className="flex">
                        {/* <button
                          // onClick={() => handleCheckboxClick("partnerC8Doc")}
                          className={`dummy-checkbox relative mr-[10px] !mt-0`}
                        >
                          {partnerC8DocClicked ? (
                            <span className="dummy-checked"></span>
                          ) : null}
                        </button> */}
                        <input
                          type="checkbox"
                          {...registerDealerValidation("partnerC8Doc", {
                            required:
                              "You must accept the Three Consumer C8 Information",
                          })}
                          className={`dummy-checkbox relative mr-[10px] mt-1`}
                          // checked={partnerC8DocClicked} // checkbox checked based on state
                          onChange={(e) => {
                            setValue("partnerC8Doc", e.target.checked); // Update the form state
                            trigger("partnerC8Doc"); // Trigger validation for this field
                          }}
                        />
                        <label className="text-sm text-nowrap flex flex-wrap">
                          Three Consumer C8 Information
                          <span
                            onClick={() => dispatch(openDocumentModal(1))}
                            className="underline hover:opacity-50 transition flex items-center ml-1 cursor-pointer font-semibold"
                          >
                            View
                            <RiShareBoxFill className="ml-1" />
                          </span>
                        </label>
                      </div>
                      {dealerError.partnerC8Doc && (
                        <p className="error-text">
                          {dealerError.partnerC8Doc.message}
                        </p>
                      )}
                      <div className="flex">
                        <input
                          type="checkbox"
                          {...registerDealerValidation("iotMobileDoc", {
                            required:
                              "You must accept the IOT Mobiles Terms and conditions",
                          })}
                          className={`dummy-checkbox relative mr-[10px] mt-1`}
                          onChange={(e) => {
                            setValue("iotMobileDoc", e.target.checked); // Update the form state
                            trigger("iotMobileDoc"); // Trigger validation for this field
                          }}
                        />
                        <label className="text-sm text-nowrap flex flex-wrap">
                          Terms and Conditions for using the IOT Mobiles
                          <span
                            onClick={() => dispatch(openDocumentModal(4))}
                            className="underline hover:opacity-50 transition flex items-center ml-1 cursor-pointer font-semibold"
                          >
                            View
                            <RiShareBoxFill className="ml-1" />
                          </span>
                        </label>
                      </div>
                      {dealerError.iotMobileDoc && (
                        <p className="error-text">
                          {dealerError.iotMobileDoc.message}
                        </p>
                      )}
                    </div>
                  </>
                ) : null}

                {secondAcc && stepOne ? null : (
                  <>
                    {/* {
                        dealerMessage ? <p className="error-text">{dealerMessage}</p> : null
                      }
                      <div>
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        >
                          Sign up
                        </button>
                      </div> */}
                  </>
                )}
                {dealerId !== null && stepTwo ? (
                  <>
                    {dealerMessage ? (
                      <p className="error-text">{dealerMessage}</p>
                    ) : null}
                    <div>
                      <LoaderButton
                        type="submit"
                        title="Sign up"
                        name="submitDealer"
                        style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        // disabled={submitDealerBtnDisabled}
                      />
                    </div>
                  </>
                ) : null}
              </form>
            ) : null}

            <p className="mt-6 text-center text-sm ">
              <span className="text-gray-500">Already have an Account? </span>
              <Link
                to="/login"
                className="font-semibold hover:underline decoration-1 primary-text"
              >
                Login
              </Link>
            </p>
            {accountType == 2 && (
              <p className="mt-10 text-center text-sm ">
                <span className="text-gray-500">Not a dealer? </span>
                <span
                  className="font-semibold hover:underline decoration-1 primary-text cursor-pointer"
                  onClick={() => dispatch(openModal("redirectToDealerSignUp"))}
                >
                  click here
                </span>
              </p>
            )}
          </div>
          {/* <ModalComponent
            size="md"
            oncl
            type="pending"
            position="center"
            description=""
            bottomLine={true}
            title="Verification pending"
          >
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
              {count == 0 || count == 1 ? (
                <>
                  <h3 className="mb-3 text-center">
                    Verification Link is Already sent to your email. Please
                    click "Resend" button to get the verification link
                  </h3>

                  <Button
                    handleClick={() => {
                      dispatch(closeModal());
                    }}
                    title="Okay"
                    type="button"
                    style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent  hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
                  />
                </>
              ) : count == 2 ? (
                <>
                  <h3 className="mb-3 text-center">
                    Please check your email for confirmation mail and click on
                    “Verify” button to continue.
                  </h3>
                  <div className="flex flex-col items-center justify-center">
                    <FiClock className="text-[42px]" />
                    <h2 className="font-bold">{formattedTime}</h2>
                    <span className="info">
                      *Verification status checking is in progress
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
           
          </ModalComponent> */}
          <ModalComponent
            size={
              modalInitState.contentId === "redirectToDealerSignUp"
                ? "md"
                : modalInitState.contentId === "verificationPending"
                ? "md"
                : null
            }
            type={
              modalInitState.contentId === "redirectToDealerSignUp"
                ? "info"
                : modalInitState.contentId === "verificationPending"
                ? "info"
                : null
            }
            position={
              modalInitState.contentId === "redirectToDealerSignUp"
                ? "center"
                : modalInitState.contentId === "verificationPending"
                ? "center"
                : null
            }
            title={
              modalInitState.contentId === "redirectToDealerSignUp"
                ? "Redirecting to another application"
                : modalInitState.contentId === "verificationPending"
                ? "Verification pending"
                : null
            }
            description=""
            bottomLine={true}
          >
            {modalInitState.contentId === "verificationPending" ? (
              <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
                {count == 0 || count == 1 ? (
                  <>
                    <h3 className="mb-3 text-center">
                      Verification Link is Already sent to your email. Please
                      click "Resend" button to get the verification link
                    </h3>

                    <Button
                      handleClick={() => {
                        dispatch(closeModal("verificationPending"));

                        // if (failedValue == 39 || failedValue == 40) {
                        //   setFailedValue("");
                        // }
                      }}
                      title="Okay"
                      type="button"
                      style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent  hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
                    />
                  </>
                ) : count == 2 ? (
                  <>
                    <h3 className="mb-3 text-center">
                      Please check your email for confirmation mail and click on
                      “Verify” button to continue.
                    </h3>
                    <div className="flex flex-col items-center justify-center">
                      <FiClock className="text-[42px]" />
                      <h2 className="font-bold">{formattedTime}</h2>
                      <span className="info">
                        *Verification status checking is in progress
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : null}
            {modalInitState.contentId === "redirectToDealerSignUp" ? (
              <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
                <h3 className="mb-3 text-center">
                  we are redirecting you to a different application. Please
                  confirm if this is fine.
                </h3>
                <div className="w-full flex justify-between items-center mt-4">
                  <Button
                    handleClick={() => {
                      dispatch(closeModal("redirectToDealerSignUp"));
                    }}
                    title="Cancel"
                    type="button"
                    style="cta-two w-[200px]"
                  />
                  <Button
                    handleClick={() => {
                      window.open(DEALER_SIGNUP_URL, "_blank");
                      dispatch(closeModal("redirectToDealerSignUp"));
                    }}
                    title="Okay"
                    type="button"
                    style="cta-two w-[200px]"
                  />
                </div>
              </div>
            ) : null}
          </ModalComponent>
          {showLoader && <Loader />}
        </div>
      }
      <DocumentModal
        position="center"
        title="Documentation"
        bottomLine={true}
      />
    </>
  );
}
