import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdClose } from "react-icons/md";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import {
  closeCookies,
  setCookiePreferenceOptOpen,
  setCookiePreferenceOptClose,
} from "./cookieSlice";
import { openDocumentModal } from "../legal/documentModal/DocumentModalSlice";
import Cookies from "js-cookie";

const CookiesComponent = () => {
  const { openCookies, cookiePreferenceOpt } = useSelector(
    (state) => state.cookies
  );
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [cookiesPreferences, setCookiesPreferences] = useState({});
  const [functionalCookies, setFunctionalCookies] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(false);
  const [targettingCookies, setTargettingCookies] = useState(false);

  // States end here
  const confirmMyPref = () => {
    setCookiesPreferences({
      acceptAll: false,
      rejectAll: false,
      customCookiesPref: true,
      customisePref: {
        essentailsCookies: true,
        functionalCookies: functionalCookies,
        performanceCookies: performanceCookies,
        targettingCookies: targettingCookies,
      },
    });
  };

useEffect(() => {
  // Save state to cookies whenever it changes
  if(!(Object.keys(cookiesPreferences).length === 0 && cookiesPreferences.constructor === Object)){
    Cookies.set("cookiesPreferences", JSON.stringify(cookiesPreferences), { expires: 365 }); // Expires in 365 days
  }
}, [cookiesPreferences]);


  useEffect(() => {
    if (openCookies) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [openCookies]);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      dispatch(closeCookies());
    }, 700);
  };

  if (!openCookies) return null;

  return (
    <div
      className={`transition-opacity duration-700 fixed bottom-3 left-1/2 -translate-x-1/2 min-h-44 z-[99] mx-auto max-w-7xl flex items-start justify-center p-2 rounded-xl w-[98%] bg-[#99BBBD] text-white shadow-2xl ${
        show ? "opacity-100" : "opacity-0"
      }`}
    >
      {cookiePreferenceOpt ? (
        <div className="w-full">
          <div>
            <div className="flex justify-between items-start my-2 relative">
              <div className="flex items-center gap-2">
                <IoChevronBackCircleOutline
                  onClick={() => dispatch(setCookiePreferenceOptClose())}
                  className="text-3xl cursor-pointer"
                />
                <h3 className="text-xl font-semibold leading-none">
                  Privacy preferences
                </h3>
              </div>
              <MdClose
                className="cursor-pointer p-1 rounded-full h-8 w-8 hover:bg-slate-50/30"
                onClick={() => dispatch(closeCookies())}
              />
            </div>
            <div className="max-h-[400px]  overflow-auto bg-white text-[#99BBBD] rounded p-3">
              <p>
                Your privacy is important to us. And we want to be transparent
                about how we use your data. Below, you'll find information about
                the cookies we use and how we use them. To change your privacy
                preferences, use the toggles and select confirm my preferences.
                Turning off any of these cookies may affect your experience of
                our site and the services we're able to offer.
                <span
                  onClick={() => dispatch(openDocumentModal(5))}
                  className="font-semibold underline underline-offset-1 cursor-pointer hover:text-slate-100"
                >
                  Cookie Policy
                </span>
              </p>
              <h3 className="text-xl font-semibold my-2">
                Manage privacy preferences
              </h3>
              <div className="pl-4">
                {/* first toggle */}
                <div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold my-2">
                      1. Essential Cookies
                    </h4>
                    <label className="inline-flex items-center cursor-none pointer-events-none">
                      <span class="me-3 text-sm font-medium">
                        Always active
                      </span>
                      <input
                        defaultChecked
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-teal-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                    </label>
                  </div>
                  <p>
                    These cookies are necessary for the operation of the Website
                    and enable basic functions such as page navigation and
                    access to secure areas. Without these cookies, services like
                    shopping baskets or account logins would not function.
                  </p>
                  <p className="font-semibold my-2">What they do:</p>
                  <ul className="pl-6">
                    <li className="list-disc">
                      Allow you to move around the Website
                    </li>
                    <li className="list-disc">
                      Retain items in your shopping basket
                    </li>
                    <li className="list-disc">
                      Help us identify if you are a new or returning user
                    </li>
                    <li className="list-disc">
                      Collect statistical data that helps us improve the
                      Website’s performance
                    </li>
                  </ul>
                  <p>
                    Since these cookies are essential for the Website's
                    functionality, you cannot turn them off using Cookie
                    Preferences.
                  </p>
                </div>
                {/* second toggle */}
                <div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold my-2">
                      2. Functional Cookies
                    </h4>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        onClick={() => setFunctionalCookies((prev) => !prev)}
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-teal-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                    </label>
                  </div>
                  <p>
                    These cookies enhance the functionality of the Website by
                    remembering your preferences or enabling features. If you
                    decline these cookies, some features may not function as
                    expected.
                  </p>
                  <p className="font-semibold my-2">What they do:</p>
                  <ul className="pl-6">
                    <li className="list-disc">
                      Remember your personalisation’s, like language or region
                      settings
                    </li>
                    <li className="list-disc">
                      Enable us to identify potential candidates for market
                      research surveys
                    </li>
                  </ul>
                  <p>
                    We will ask for your consent before using these cookies.
                  </p>
                </div>
                {/* third toggle */}
                <div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold my-2">
                      3. Performance Cookies
                    </h4>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        onClick={() => setPerformanceCookies((prev) => !prev)}
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-teal-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                    </label>
                  </div>
                  <p>
                    Performance cookies allow us to analyse how visitors
                    interact with the Website, helping us improve the
                    performance and usability of the site.
                  </p>
                  <p className="font-semibold my-2">What they do:</p>
                  <ul className="pl-6">
                    <li className="list-disc">
                      Track visitor movements and activity on the Website
                    </li>
                    <li className="list-disc">
                      Allow us to identify issues or areas for improvement
                    </li>
                  </ul>
                  <p>
                    We will ask for your consent before using these cookies.
                  </p>
                </div>
                {/* fourth toggle */}
                <div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold my-2">
                      4. Targeting Cookies
                    </h4>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        onClick={() => setTargettingCookies((prev) => !prev)}
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-teal-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                    </label>
                  </div>
                  <p>
                    We use targeting cookies to deliver personalized marketing
                    communications based on your browsing habits and
                    preferences. These cookies may be used by third-party
                    advertisers to target you with relevant ads across different
                    platforms and websites.
                  </p>
                  <p className="font-semibold my-2">What they do:</p>
                  <ul className="pl-6">
                    <li className="list-disc">
                      Build a profile of your interests and serve you tailored
                      ads
                    </li>
                    <li className="list-disc">
                      Enable third-party advertisers to track the effectiveness
                      of ads
                    </li>
                    <li className="list-disc">
                      Share unique identifiers (such as email addresses) with
                      third parties for targeted advertising
                    </li>
                  </ul>
                  <p>
                    By accepting targeting cookies, you agree that we can share
                    this information with third-party advertisers. This may
                    result in your data being processed outside the EU/EEA.
                    <br />
                    We will ask for your consent before using these cookies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3 flex justify-center">
            <button
              onClick={() => {
                confirmMyPref();
                dispatch(closeCookies());
              }}
              className="cta-two px-10 py-2 rounded mx-auto"
            >
              Confirm my preferences
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full p-3">
          <div className="flex justify-between items-start mb-2 relative">
            <h3 className="text-xl font-semibold leading-none">
              Cookie Policy
            </h3>
            <MdClose
              className="absolute top-[-10px] right-[-10px] cursor-pointer p-1 rounded-full h-8 w-8 hover:bg-slate-50/30"
              onClick={() => dispatch(closeCookies())}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-6 md:gap-8">
            <p className="md:mt-1 mt-0">
              Our site uses cookies, which helps us to improve our site and
              enables us to deliver the best possible service and customer
              experience. By clicking Accept, you are agreeing to our cookie
              policy.{" "}
              <span
                onClick={() => dispatch(openDocumentModal(5))}
                className="font-semibold underline underline-offset-1 cursor-pointer hover:text-slate-100"
              >
                Cookie Policy
              </span>
              .
            </p>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => {
                  setCookiesPreferences({
                    acceptAll: true,
                    rejectAll: false,
                    customCookiesPref: false
                  });
                  dispatch(closeCookies());
                }}
                className="rounded text-sm px-4 md:px-12 py-2 cta-two"
              >
                Accept all
              </button>
              <button
                onClick={() => {
                  setCookiesPreferences({
                    acceptAll: false,
                    rejectAll: true,
                    customCookiesPref: true,
                    customisePref: {
                      essentailsCookies: true,
                      functionalCookies: false,
                      performanceCookies: false,
                      targettingCookies: false,
                    },
                  });
                  dispatch(closeCookies());
                }}
                className="rounded text-sm px-4 md:px-12 py-2 cta-one"
              >
                Reject all
              </button>
              <button
                onClick={() => dispatch(setCookiePreferenceOptOpen())}
                className="p-2 flex justify-center items-center gap-2 font-semibold text-[14px] underline underline-offset-1 cursor-pointer rounded hover:outline hover:outline-1"
              >
                Cookie preference
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesComponent;
