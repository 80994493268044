import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import aboutUsBg from "../../assets/about-us-bg.jpg";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../customHooks/useScrollToTop";

const AboutUs = () => {
  useScrollToTop();
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <section className="mx-auto max-w-6xl">
          <h1 className="text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
            GK Telecom, A Trusted Brand.
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-9 mt-6">
            <div>
              <img
                src={aboutUsBg}
                className="w-full object-cover rounded-lg max-h-72 max"
                alt="About Us"
              />
            </div>
            <div>
              <h2 className="text-lg font-semibold">Our Heritage</h2>
              <p className="text-justify">
                Founded over 24 years ago, GK Telecom has established itself as
                a leading distributor in the UK&#39;s telecommunications sector.
                Initially focusing on calling cards, we expanded into the
                prepaid SIM market in 2004, becoming one of only four SIM only
                distributors in the UK, and now sell over 100,000 SIMs monthly
                across various networks.
              </p>
              <Button
                style="cta-one w-1/2 mt-5"
                type="button"
                title="Get Started"
                handleClick={() => navigate("/")}
              />
            </div>
          </div>
        </section>
        <section className="bg-[#5A949A]/10 py-6 my-14 about-us-strip mx-auto max-w-5xl">
          <div className="mx-auto max-w-5xl grid grid-cols-1 gap-y-12 md:grid-cols-3">
            <div className="text-center">
              <h2
                style={{ fontFamily: "Inter" }}
                className="font-semibold text-4xl"
              >
                5 Million
              </h2>
              <p>SIMs Delivered Globally</p>
            </div>
            <div className="text-center">
              <h2
                style={{ fontFamily: "Inter" }}
                className="font-semibold text-4xl"
              >
                24+ Years
              </h2>
              <p>Global Distribution Excellence</p>
            </div>
            <div className="text-center">
              <h2
                style={{ fontFamily: "Inter" }}
                className="font-semibold text-4xl"
              >
                200+ Countries
              </h2>
              <p>GKT eSIM Available In</p>
            </div>
          </div>
        </section>

        <section className="mx-auto max-w-6xl">
          <h2 className="text-lg font-semibold">Innovative Evolution</h2>
          <p>
            We&#39;ve continually evolved, adapting to market changes and
            leading the introduction of innovative eSIM technology. This
            revolutionary communication method has transformed the market by
            enabling customers to instantly swap networks without the need for a
            physical SIM card, making global communication more accessible and
            affordable.
          </p>
          <br />
          <h2 className="text-lg font-semibold">Unique Market Position</h2>
          <p>
            What sets GK Telecom apart are our unique mobile accessories, in
            house branding, and point of sale facilities. As an award winning,
            trustworthy business partner in the mobile industry, we stand out as
            a unique entity, dedicated to innovation and quality.
          </p>
          <br />
          <h2 className="text-lg font-semibold">
            Introducing Our IoT Mobile Platform
          </h2>
          <p>
            Building on our 24 year legacy of leadership in SIM distribution, we
            are proud to announce the launch of our IoT platform, marking a
            transformative step into the future of connectivity. We have
            initiated an exclusive partnership with Three for SIM only plans,
            and our platform is designed to cater to the rapidly growing IoT
            ecosystem. From smart devices to industrial applications, our
            solutions empower businesses to stay connected, competitive, and
            forward thinking.
          </p>
          <br />
          <h2 className="text-lg font-semibold">Our IoT Platform Features:</h2>
          <ul className="pl-6">
            <li className="list-disc">
              <b>Scalable Solutions:</b> <br/> Supporting SIM only plans today,
              expanding into IoT devices tomorrow, and ready to adapt to future
              innovations.
            </li>
            <li className="list-disc">
              <b>Tailored for Business:</b> <br/> Specifically designed with B2B needs
              in mind, offering seamless connectivity for smart devices across
              industries.
            </li>
            <li className="list-disc">
              <b>Partnerships That Matter:</b> <br/> Collaborating with industry
              leaders like Three to ensure reliable and efficient IoT services.
            </li>
            <li className="list-disc">
              <b>Future Ready:</b> <br/> Our platform is built to integrate with
              emerging technologies, keeping our clients at the forefront of
              connectivity.
            </li>
          </ul>
          <br />
          <h2 className="text-lg font-semibold">Commitment to Innovation</h2>
          <p>
            As we continue to innovate, we are committed to equipping businesses
            with the tools they need to thrive in a connected world. Whether
            powering IoT devices or supporting next generation technologies, GK
            Telecom is shaping the future of connectivity.
          </p>
          <br />
          <h2 className="text-lg font-semibold">Explore the Possibilities</h2>
          <p>
            Discover what GK Telecom’s IoT platform can do for your business.
            Whether you&#39;re seeking scalable IoT solutions or a partnership
            in cutting edge connectivity, we are here to help you innovate and
            grow.
          </p>
          <br />
        </section>
      </main>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default AboutUs;
