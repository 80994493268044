import { createSlice } from '@reduxjs/toolkit';

const cookieSlice = createSlice({
  name: 'cookies',
  initialState: {
    openCookies: false,
    cookiePreferenceOpt: false,
  },
  reducers: {
    openCookies(state) {
      state.openCookies = true;
    },
    closeCookies(state) {
      state.openCookies = false;
      state.cookiePreferenceOpt = false;
    },
    setCookiePreferenceOptOpen(state) {
      state.cookiePreferenceOpt = true;
    },
    setCookiePreferenceOptClose(state) {
      state.cookiePreferenceOpt = false;
    },
  },
});

export const { openCookies, closeCookies, setCookiePreferenceOptOpen, setCookiePreferenceOptClose } = cookieSlice.actions;

export default cookieSlice.reducer;
