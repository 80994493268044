import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import cancelContract from "../../assets/custom-icons/contract-cancel-icon.svg";
import simSwap from "../../assets/custom-icons/sim-swap-icon.svg";
import otherIssue from "../../assets/custom-icons/other-issue-icon.svg";
import axios from "axios";
import LoaderButton from "../button/LoaderButton";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  selectModalState,
} from "../modalComponent/modalSlice";
import { FiClock } from "react-icons/fi";
import CancellationService from "../../services/CancellationService";
import { useNavigate } from "react-router-dom";
import { FaShareSquare } from "react-icons/fa";
import Button from "../button/Button";

// Timer component
const Timer = ({
  start,
  onTimerEnd,
  time,
  orderId,
  setDisabledResendBtnDisable,
  resendStartTimer,
}) => {
  const [timeLeft, setTimeLeft] = useState(30);
  const navigate = useNavigate();
  const orderDetails = localStorage.getItem("orderCancellationDetails");
  const dispatch = useDispatch();

  useEffect(() => {
    if (resendStartTimer == true) {
      setTimeLeft(30);
    } else {
    }
  }, [resendStartTimer]);
  useEffect(() => {
    let timer;
    if (start && timeLeft > 0) {
      setDisabledResendBtnDisable(true);
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      if (timeLeft == 20 || timeLeft == 10 || timeLeft == 3) {
        console.log(orderDetails, "orderDetails");
        handleCheckOrderCancellationStatusFunc(JSON.parse(orderDetails));
      }
    } else if (timeLeft === 0) {
      setDisabledResendBtnDisable(false);

      clearInterval(timer);
      onTimerEnd(0);
    }
    return () => {
      clearInterval(timer);
    };
  }, [start, timeLeft, onTimerEnd, resendStartTimer]);

  const handleCheckOrderCancellationStatusFunc = async (orderDetails) => {
    CancellationService.checkOrderCancellationStatus(
      orderDetails?.orderIdEncrypted
    )
      .then((response) => {
        if (response.data.status === "success") {
          navigate("/cancel-confirmation");
          dispatch(closeModal());
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    return () => {
      setDisabledResendBtnDisable(false);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      <FiClock className="text-[42px]" />
      <h2 className="font-bold text-xl">
        00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
      </h2>
    </div>
  );
};

const ModelForTimer = ({
  formattedTime,
  showResendBtn,
  showTimer,
  modalInitState,
  setSeconds,
  setShowResendBtn,
  setShowTimer,
  emailValue,
  iccidMsisdnValue,
  setTimerEnd,
  setStartTimer,
  startTimer,
  timerEnd,
  time,
}) => {
  const [resendBtnDisableState, setResendBtnDisableState] = useState(false);
  const [modalErrors, setModalErrors] = useState(null);
  const dispatch = useDispatch();
  const orderDetails = localStorage.getItem("orderCancellationDetails");
  const navigate = useNavigate();
  const [checkStatusError, setCheckStatusError] = useState(null);
  const [resendStartTimer, setResendStartTimer] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);

  const [disabledResendBtnDisable, setDisabledResendBtnDisable] =
    useState(false);

  useEffect(() => {
    return () => {
      // Cleanup function to set disabledResendBtnDisable to false on unmount
      setDisabledResendBtnDisable(false);
    };
  }, [disabledResendBtnDisable]);

  const handleTimerEnd = (status) => {
    setTimerEnd(status);
    setResendBtnDisableState(false);
  };

  const handleSecondTimerEnd = (status) => {
    if (status == 0) {
      setResendStartTimer(false);
    }
  };

  const handleResendCancellationForm = async (e) => {
    e.preventDefault();
    setResendBtnDisableState(true);
    setModalErrors({});
    const errors = {};
    const msisdnPattern = /^\d{1,19}$/; // Numeric, 1 to 19 digits
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailValue) {
      errors.emailValue = "Please enter your email";
    }
    if (emailValue && !emailRegex.test(emailValue)) {
      errors.emailValue = "Please enter a valid email.";
    }
    if (!iccidMsisdnValue) {
      errors.iccidMsisdnValue = "Mobile Number is required";
    } else if (!msisdnPattern.test(iccidMsisdnValue)) {
      errors.iccidMsisdnValue = "Invalid Mobile Number/ICCID";
    }

    if (Object.values(errors).some((error) => error)) {
      setModalErrors(errors);
      // setResendBtnDisableState(false);
      return;
    }
    CancellationService.cancellationApi(emailValue, iccidMsisdnValue, 2)
      .then((response) => {
        if (response.status === 200) {
          setResendStartTimer(true);
          setResendBtnDisableState(false);
          dispatch(openModal("resendStatus"));
          setShowTimer(true);
          setStartTimer(true);
        }
      })
      .catch((error) => {
        setResendBtnDisableState(false);
        switch (error.response ? error.response.status : "") {
          case 400:
            setModalErrors((prev) => ({
              ...prev,
              form: error.response.data.failedReason,
            }));
            break;
          case 403:
            setModalErrors((prev) => ({
              ...prev,
              form: error.response.data.failedReason,
            }));
            break;
          case 404:
            setModalErrors((prev) => ({
              ...prev,
              form: error.response.data.failedReason,
            }));
            break;
          case 406:
            const cancellationDetails = {
              email: error?.response?.data?.creditCheck?.customer?.email,
              name: error?.response?.data?.creditCheck?.customer?.name,
              userId: error?.response?.data?.creditCheck?.customer?.id,
              iccid: error?.response?.data?.creditCheck?.iccid,
              msisdn: error?.response?.data?.creditCheck?.msisdn || null,
              lockMsisdn: error?.response?.data?.creditCheck?.lockMsisdn,
              mobileNumberSelection:
                error?.response?.data?.creditCheck?.mobileNumberSelection
                  ?.mobileNoSelectionId,
              //orderId: error?.response?.data?.orderId,
              orderIdEncrypted: error?.response?.data?.orderIdEncrypted,
              orderStatus: error?.response?.data?.orderStatus?.orderStatusId,
              orderCreatedDate: error?.response?.data?.createdDate,
              orderCancellationStatus:
                error?.response?.data?.orderCancellationStatus,
              cancellationReasonList:
                error?.response?.data?.cancellationReasonList,
            };
            //console.log("cancellationDetails", cancellationDetails);
            if (cancellationDetails) {
              //console.log("inside cancellationDetails");
              localStorage.setItem(
                "orderCancellationDetails",
                JSON.stringify(cancellationDetails)
              );
            }
            dispatch(openModal("resendStatus"));
            setShowResendBtn(true);
            setShowTimer(false);
            // setStartTimer(false);
            break;
          case 500:
            setModalErrors((prev) => ({
              ...prev,
              form: "Server error occured. Please try again later.",
            }));
            break;
          default:
            setModalErrors((prev) => ({
              ...prev,
              form: "Unknown error occured. Please try again later.",
            }));
            break;
        }
        setResendBtnDisableState(false);
      });
  };

  const handleCheckOrderCancellationStatusFunc = async (orderDetails) => {
    setCheckStatus(true);
    CancellationService.checkOrderCancellationStatus(
      orderDetails?.orderIdEncrypted
    )
      .then((response) => {
        setCheckStatus(false);
        if (response.data.status === "success") {
          navigate("/cancel-confirmation");
          dispatch(closeModal());
        } else if (response.data.status === "failed") {
          setCheckStatusError(
            "You have not acknowledge the mail yet. Please acknowledge it."
          );
        }
      })
      .catch((error) => {
        setCheckStatus(false);
        switch (error.response ? error.response.status : "") {
          case 400:
            setCheckStatusError(error.response.data.failedReason);
            break;
          case 403:
            setCheckStatusError(error.response.data.failedReason);
            break;
          case 404:
            setCheckStatusError(error.response.data.failedReason);
            break;
          case 406:
            setCheckStatusError(error.response.data.failedReason);
            break;
          case 500:
            setCheckStatusError(error.response.data.failedReason);
            break;
          default:
            setCheckStatusError(error.response.data.failedReason);
            break;
        }
      });
  };

  return (
    <div>
      <h2 className="text-md text-center font-semibold mb-2">
        Please Acknowledge Your Email to proceed
      </h2>
      <div className="w-full flex flex-col items-center justify-center mt-4">
        {showTimer == true &&
          modalInitState.contentId == "openAcknowledgeModal" && (
            <Timer
              setDisabledResendBtnDisable={setDisabledResendBtnDisable}
              start={startTimer}
              onTimerEnd={handleTimerEnd}
              time={time}
            />
          )}
        {showTimer == true && modalInitState.contentId == "resendStatus" && (
          <Timer
            setDisabledResendBtnDisable={setDisabledResendBtnDisable}
            start={startTimer}
            onTimerEnd={handleSecondTimerEnd}
            time={time}
            resendStartTimer={resendStartTimer}
          />
        )}
        <span className="text-[#414141] text-sm text-center font-semibold">
          *Please do not close the current tab or refresh the page
        </span>
        {(modalInitState.contentId == "openAcknowledgeModal" &&
          timerEnd == 0) ||
        modalInitState.contentId == "resendStatus" ? (
          <>
            <div>
              {modalErrors?.form && (
                <p className="error-text text-center">{modalErrors.form}</p>
              )}
              {checkStatusError && (
                <p className="error-text text-center">{checkStatusError}</p>
              )}
            </div>

            <div className="w-full flex gap-3 mt-1">
              <LoaderButton
                type="submit"
                title="Resend"
                disabled={resendBtnDisableState}
                style={`${
                  disabledResendBtnDisable ? "btn-disabled" : ""
                } mt-2 flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600`}
                handleClick={handleResendCancellationForm}
              />
              <LoaderButton
                type="button"
                title="Check Status"
                disabled={checkStatus}
                style={`${
                  checkStatus ? "btn-disabled" : ""
                } mt-2 flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600`}
                handleClick={() =>
                  handleCheckOrderCancellationStatusFunc(
                    JSON.parse(orderDetails)
                  )
                }
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const ModalForEligibility = ({ errorMsg }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <p>{errorMsg}</p>
      <div>
        <Button
          style="cta-one mt-4 w-full"
          title="Okay"
          type="button"
          handleClick={() => dispatch(closeModal())}
        />
      </div>
    </div>
  );
};

const Cancellation = () => {
  const [emailValue, setEmailValue] = useState(null);
  const [iccidMsisdnValue, setIccidMsisdnValue] = useState(null);
  const [btnDisableState, setBtnDisableState] = useState(false);
  const [errors, setErrors] = useState(null);
  const [updatedModalErrors, setUpdatedModalErrors] = useState("");
  const dispatch = useDispatch();
  const [showResendBtn, setShowResendBtn] = useState(false);
  const modalInitState = useSelector((state) => state.modal);
  const [seconds, setSeconds] = useState(10);
  const [thirtySecTimer, setThirtySecTimer] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [resendBtnState, setResendBtnState] = useState(false);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      emailValue: null,
    }));
  }, [emailValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      iccidMsisdnValue: null,
    }));
  }, [iccidMsisdnValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      emailValue: null,
    }));
  }, [emailValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      iccidMsisdnValue: null,
    }));
  }, [iccidMsisdnValue]);

  const [startTimer, setStartTimer] = useState(false);
  const [timerEnd, setTimerEnd] = useState(null);
  const [time, setTime] = useState(30);

  const handleTimerEnd = (status) => {
    setTimerEnd(status);
    // setResendBtnDisableState(false);
  };

  useEffect(() => {}, []);

  const handleCancellationForm = async (e) => {
    e.preventDefault();
    setBtnDisableState(true);
    setErrors({});
    const errors = {};
    // const msisdnPattern = /^\d{1,19}$/; // Numeric, 1 to 19 digits
    const msisdnPattern = /^(?:\d{10}|\d{19})$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailValue) {
      errors.emailValue = "Please enter your email";
    }
    if (emailValue && !emailRegex.test(emailValue)) {
      errors.emailValue = "Please enter a valid email.";
    }
    if (!iccidMsisdnValue) {
      errors.iccidMsisdnValue = "ICCID/Mobile Number is required";
    } else if (!msisdnPattern.test(iccidMsisdnValue)) {
      // errors.iccidMsisdnValue = "Invalid Mobile Number/ICCID";
      errors.iccidMsisdnValue = "ICCID/Mobile Number must be 19 or 10 digits";
    }

    if (Object.values(errors).some((error) => error)) {
      setErrors(errors);
      setBtnDisableState(false);
      return;
    }
    CancellationService.cancellationApi(emailValue, iccidMsisdnValue, 1)
      .then((response) => {
        setBtnDisableState(false);
        //console.log("response?.data?", response.data);
        const cancellationDetails = {
          email: response?.data?.creditCheck?.customer?.email,
          name: response?.data?.creditCheck?.customer?.name,
          userId: response?.data?.creditCheck?.customer?.id,
          iccid: response?.data?.creditCheck?.iccid,
          msisdn: response?.data?.creditCheck?.msisdn || null,
          lockMsisdn: response?.data?.creditCheck?.lockMsisdn,
          mobileNumberSelection:
            response?.data?.creditCheck?.mobileNumberSelection
              ?.mobileNoSelectionId,
          //orderId: response?.data?.orderId,
          orderIdEncrypted: response?.data?.orderIdEncrypted,
          orderStatus: response?.data?.orderStatus?.orderStatusId,
          orderCreatedDate: response?.data?.createdDate,
          orderCancellationStatus: response?.data?.orderCancellationStatus,
          cancellationReasonList: response?.data?.cancellationReasonList,
        };
        dispatch(openModal("openAcknowledgeModal"));
        // setThirtySecTimer(true)
        setShowTimer(true);
        setStartTimer(true);

        // setT
        if (cancellationDetails) {
          localStorage.setItem(
            "orderCancellationDetails",
            JSON.stringify(cancellationDetails)
          );
        }
      })
      .catch((error) => {
        //console.log("error===", error);

        switch (error.response ? error.response.status : "") {
          case 400:
            if (
              error.response.data.failedReason ==
              "We have already received a cancellation request for the provided information."
            ) {
              setUpdatedModalErrors(error.response.data.failedReason);
              dispatch(openModal("alreadyRequest"));
            } else if (
              error.response.data.failedReason ==
              "Unfortunately, you are not eligible for order cancellation. Pay Monthly contracts can only be canceled within 30 days of order placement."
            ) {
              setUpdatedModalErrors(error.response.data.failedReason);
              dispatch(openModal("notEligible"));
            } else if (
              error.response.data.failedReason ==
              "PAYG Orders are not eligible for Cancellation."
            ) {
              setUpdatedModalErrors(error.response.data.failedReason);
              dispatch(openModal("notEligible"));
            }else {
              setErrors((prev) => ({
                ...prev,
                form: error.response.data.failedReason,
              }));
            }
            break;
          case 403:
            setErrors((prev) => ({
              ...prev,
              form: error.response.data.failedReason,
            }));
            break;
          case 404:
            setErrors((prev) => ({
              ...prev,
              form: error.response.data.failedReason,
            }));
            break;
          case 406:
            const cancellationDetails = {
              email: error?.response?.data?.creditCheck?.customer?.email,
              name: error?.response?.data?.creditCheck?.customer?.name,
              userId: error?.response?.data?.creditCheck?.customer?.id,
              iccid: error?.response?.data?.creditCheck?.iccid,
              msisdn: error?.response?.data?.creditCheck?.msisdn || null,
              lockMsisdn: error?.response?.data?.creditCheck?.lockMsisdn,
              mobileNumberSelection:
                error?.response?.data?.creditCheck?.mobileNumberSelection
                  ?.mobileNoSelectionId,
              //orderId: error?.response?.data?.orderId,
              orderIdEncrypted: error?.response?.data?.orderIdEncrypted,
              orderStatus: error?.response?.data?.orderStatus?.orderStatusId,
              orderCreatedDate: error?.response?.data?.createdDate,
              orderCancellationStatus:
                error?.response?.data?.orderCancellationStatus,
              cancellationReasonList:
                error?.response?.data?.cancellationReasonList,
            };
            //console.log("cancellationDetails", cancellationDetails);
            if (cancellationDetails) {
              //console.log("inside cancellationDetails");
              localStorage.setItem(
                "orderCancellationDetails",
                JSON.stringify(cancellationDetails)
              );
            }
            dispatch(openModal("resendStatus"));
            setShowResendBtn(true);
            setShowTimer(false);
            setStartTimer(false);
            setTime(30);
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              form: "Server error occured. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              form: "Unknown error occured. Please try again later.",
            }));
            break;
        }
        setBtnDisableState(false);
      });
  };

  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <h1 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
        SIM Only Query
        </h1>
        <div className="lg:max-w-2xl md:max-w-xl mx-auto flex flex-col">
          <div className="border custom-border-50 rounded-lg p-4 flex flex-col md:items-center">
            <div className="flex flex-row lg:flex-col justify-start lg:justify-center items-center gap-2 mb-2">
              <img
                width={42}
                height={42}
                className="w-[32px] h-[32px] lg:w-[42px] lg:h-[42px]"
                src={cancelContract}
                alt="Cancel Contract"
              />
              <h2 className="text-xl font-semibold">Cancel Your Contract</h2>
            </div>
            <div className="custom-border-50 rounded-lg bg-[#0f766d]/10 p-3">
              <p className="text-sm">
                To proceed with cancelling your contract, please provide your
                details below.
              </p>
            </div>
            <form
              onSubmit={handleCancellationForm}
              className="space-y-6 w-full mt-3"
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-normal leading-6"
                >
                  Email address*
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => setEmailValue(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your e-mail address"
                    className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6`}
                  />
                  {errors?.emailValue && (
                    <p className="error-text">{errors.emailValue}</p>
                  )}
                </div>

                {/* Added ErrorMessage */}
              </div>
              <div>
                <label
                  htmlFor="iccid/msisdn"
                  className="block text-sm font-normal leading-6"
                >
                  ICCID / Mobile Number*
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => setIccidMsisdnValue(e.target.value)}
                    name="iccid/msisdn"
                    type="number"
                    placeholder="Enter your ICCID or Mobile Number   "
                    className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6`}
                  />
                  {errors?.iccidMsisdnValue && (
                    <p className="error-text">{errors.iccidMsisdnValue}</p>
                  )}
                </div>
                {/* Added ErrorMessage */}
              </div>
              <div>
                {errors?.form && (
                  <p className="error-text text-center">{errors.form}</p>
                )}
                <LoaderButton
                  type="submit"
                  title="Submit"
                  disabled={btnDisableState}
                  // handleClick={}
                />
              </div>
            </form>
          </div>
        </div>
      </main>
      <ModalComponent
        bottomLine={true}
        size="md"
        position="center"
        type={
          modalInitState.contentId === "openAcknowledgeModal" ||
          modalInitState.contentId === "resendStatus"
            ? "success"
            : modalInitState.contentId === "notEligible"
            ? "error"
            : modalInitState.contentId === "alreadyRequest"
            ? "error"
            : modalInitState.contentId === "notEligible"
            ? "error"
            : null
        }
        title={
          modalInitState.contentId === "openAcknowledgeModal"
            ? "Email Sent Successfully"
            : modalInitState.contentId === "resendStatus"
            ? "Confirmation mail already sent"
            : modalInitState.contentId === "alreadyRequest"
            ? "Cancellation request already exists"
            : modalInitState.contentId === "notEligible"
            ? "Not Eligible"
            : null
        }
      >
        {modalInitState.contentId === "openAcknowledgeModal" ||
        modalInitState.contentId === "resendStatus" ? (
          <ModelForTimer
            modalInitState={modalInitState}
            showResendBtn={showResendBtn}
            thirtySecTimer={thirtySecTimer}
            showTimer={showTimer}
            handleCancellationForm={handleCancellationForm}
            setSeconds={setSeconds}
            setResendBtnState={setResendBtnState}
            setShowTimer={setShowTimer}
            emailValue={emailValue}
            iccidMsisdnValue={iccidMsisdnValue}
            setShowResendBtn={setShowResendBtn}
            setTimerEnd={setTimerEnd}
            setStartTimer={setStartTimer}
            startTimer={startTimer}
            timerEnd={timerEnd}
            time={time}
          />
        ) : modalInitState.contentId === "notEligible" ||
          modalInitState.contentId === "alreadyRequest" ? (
          <ModalForEligibility errorMsg={updatedModalErrors} />
        ) : null}
      </ModalComponent>

      <Footer />
    </>
  );
};

export default Cancellation;
