import axios from "axios";
import { Html5QrcodeScanner } from "html5-qrcode";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { CiBarcode } from "react-icons/ci";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { RiArrowRightDoubleFill, RiDiscountPercentLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Datepicker from "tailwind-datepicker-react";
import { API_URL } from "../../constant/Constant";
import AddressService from "../../services/AddressService";
import OrderService from "../../services/OrderService";
import PersonalInformationCreditCheckService from "../../services/PersonalInformationCreditCheckService";
import VoucherService from "../../services/VoucherService";
import Button from "../button/Button";
import LoaderButton from "../button/LoaderButton";
import CustomSelect from "../common/dropdown/CustomSelect";
import useScrollToTop from "../customHooks/useScrollToTop";
import Loader from "../loader/Loader";
import ModalComponent from "../modalComponent/ModalComponent";
import {
  closeModal,
  openModal,
  selectModalState,
} from "../modalComponent/modalSlice";
import Navbar from "../navbar/Navbar";
import ProductCard from "../product/ProductCard";
import MicroLoaderGreen from "../loader/MicroLoaderGreen";
import AcknowledgementService from "../../services/AcknowledgementService";

const dateOptions = {
  title: "",
  autoHide: true,
  todayBtn: false,
  clearBtn: true,
  clearBtnText: "Clear",
  maxDate: new Date("2025-01-01"),
  minDate: new Date("1950-01-01"),
  theme: {
    background: "bg-white",
    todayBtn: "",
    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "text-gray-300",
    input:
      "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 pl-9 pr-2.5 py-1.5 bg-transparent sm:text-sm sm:leading-6 mt-2",
    inputIcon: "text-primary",
    selected: "bg-primary",
  },
  icons: {
    prev: () => <FaArrowLeft />,
    next: () => <FaArrowRight />,
  },
  datepickerClassNames: "top-15",
  defaultDate: new Date(),
  language: "en",
  disabledDates: [],
  weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Select Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

const CalendarComponent = ({
  setDobValueRef,
  defaultDateValue,
  setDateObject,
}) => {
  const [showDate, setDateShow] = useState(false);
  // const [errorForDatePicker, setErrorForDatePicker] = useState("");

  const handleDateChange = (selectedDate) => {
    let dateObject = new Date(selectedDate);
    const offset = dateObject.getTimezoneOffset();
    dateObject = new Date(dateObject.getTime() - offset * 60 * 1000);
    setDateObject(dateObject);
    const formattedDate = dateObject.toISOString().split("T")[0];
    setDobValueRef(formattedDate);
  };
  const handleDateClose = (state) => {
    setDateShow(state);
  };

  return (
    <>
      <Datepicker
        primaryColor={"white"}
        options={{
          title: "",
          autoHide: true,
          todayBtn: false,
          clearBtn: true,
          clearBtnText: "Clear",
          maxDate: new Date(),
          minDate: new Date("1900-01-01"),
          theme: {
            background: "bg-white",
            todayBtn: "",
            clearBtn: "",
            icons: "",
            text: "",
            disabledText: "text-gray-300",
            input:
              "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 pl-9 pr-2.5 py-1.5 bg-transparent sm:text-sm sm:leading-6 mt-2",
            inputIcon: "text-primary",
            selected: "bg-primary",
          },
          icons: {
            prev: () => <FaArrowLeft />,
            next: () => <FaArrowRight />,
          },
          datepickerClassNames: "top-15",
          defaultDate: defaultDateValue ? new Date(defaultDateValue) : null,
          language: "en",
          disabledDates: [],
          weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
          inputNameProp: "date",
          inputIdProp: "date",
          inputPlaceholderProp: "Select Date",
          inputDateFormatProp: {
            day: "numeric",
            month: "long",
            year: "numeric",
          },
        }}
        onChange={handleDateChange}
        show={showDate}
        setShow={handleDateClose}
      />
      {/* {errorForDatePicker && <p className="error-text">{errorForDatePicker}</p>} */}
    </>
  );
};

// Modal content
const ModalForCredit = ({
  navigate,
  dispatch,
  handleCheckout,
  checkoutFormRef,
}) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        We regret to inform you that you didn't pass our credit checks.
      </h2>
      <p className="text-sm">
        However, we do offer a PAYG SIM option with auto top-up similar to a
        contract. Would you like to proceed with that?
      </p>
      <div className="w-full flex justify-between items-center mt-4">
        <Button
          handleClick={() => {
            navigate("/");
            dispatch(closeModal());
          }}
          title="No Thanks"
          type="button"
          style="
            rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 text-red-600 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-semibold px-3 py-1.5 text-center
            "
        />
        <Button
          handleClick={() => {
            // handleCheckout();
            navigate("/free-sim");
            dispatch(closeModal());
            if (checkoutFormRef.current) {
              checkoutFormRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }}
          title="Proceed"
          type="button"
          style="cta-two"
        />
      </div>
    </div>
  );
};

const ModalForContract = ({
  setButtonClicked,
  setIsContractRead,
  buttonClicked,
  dispatch,
  contractData,
  setIsApplyDiscountDisabled,
}) => {
  const handleReadContract = () => {
    setButtonClicked((prev) => !prev);
    setIsContractRead((prev) => !prev);
    setIsApplyDiscountDisabled(false);
  };

  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = today.toLocaleString("default", { month: "long" });
    const year = today.getFullYear();

    const formatted = `${day} ${month} ${year}`;
    setFormattedDate(formatted);
  }, []);
  const handleProceedClick = () => {
    setIsApplyDiscountDisabled(true);
  };
  const styles = {
    contractSummary: {
      fontFamily: "Arial, sans-serif",
      padding: "20px",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "20px",
    },
    headerLeft: {
      flex: 1,
    },
    headerRight: {
      textAlign: "right",
      flex: 1,
    },
    header: {
      color: "#333",
    },
    address: {
      fontStyle: "normal",
      lineHeight: "1.5",
    },
    date: {
      marginTop: "10px",
    },
    subHeader: {
      color: "#333",
      marginTop: "20px",
    },
    section: {
      marginBottom: "20px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
    },
    tableHeader: {
      border: "1px solid #ddd",
      padding: "8px",
      backgroundColor: "#f4f4f4",
      fontWeight: "bold",
    },
    tableCell: {
      border: "1px solid #ddd",
      padding: "8px",
      width: "170px",
    },
    footer: {
      marginTop: "20px",
    },
  };
  // {//console.log(contractData,"contractData==>>")}

  return (
    <div>
      {/* <ul>
        <li className="mb-3">
          <span className="font-semibold">Purpose</span> <br />
          The purpose of this Policy is to ensure that necessary records and
          documents of GK Telecom Limited are adequately protected and
          maintained and to ensure that records that are no longer needed by GK
          Telecom Limited or are of no value are discarded at the proper time.
          This Policy is also for the purpose of aiding employees of GK Telecom
          Limited in understanding their obligations in retaining electronic
          documents – including e-mail, Web files, text files, sound and video
          files, PDF documents, and all Microsoft Office or other formatted
          files.
        </li>
        <li className="mb-3">
          <span className="font-semibold">Purpose</span> <br />
          The purpose of this Policy is to ensure that necessary records and
          documents of GK Telecom Limited are adequately protected and
          maintained and to ensure that records that are no longer needed by GK
          Telecom Limited or are of no value are discarded at the proper time.
          This Policy is also for the purpose of aiding employees of GK Telecom
          Limited in understanding their obligations in retaining electronic
          documents – including e-mail, Web files, text files, sound and video
          files, PDF documents, and all Microsoft Office or other formatted
          files.
        </li>
        <li className="mb-3">
          <span className="font-semibold">Purpose</span> <br />
          The purpose of this Policy is to ensure that necessary records and
          documents of GK Telecom Limited are adequately protected and
          maintained and to ensure that records that are no longer needed by GK
          Telecom Limited or are of no value are discarded at the proper time.
          This Policy is also for the purpose of aiding employees of GK Telecom
          Limited in understanding their obligations in retaining electronic
          documents – including e-mail, Web files, text files, sound and video
          files, PDF documents, and all Microsoft Office or other formatted
          files.
        </li>
        <li className="mb-3">
          <span className="font-semibold">Purpose</span> <br />
          The purpose of this Policy is to ensure that necessary records and
          documents of GK Telecom Limited are adequately protected and
          maintained and to ensure that records that are no longer needed by GK
          Telecom Limited or are of no value are discarded at the proper time.
          This Policy is also for the purpose of aiding employees of GK Telecom
          Limited in understanding their obligations in retaining electronic
          documents – including e-mail, Web files, text files, sound and video
          files, PDF documents, and all Microsoft Office or other formatted
          files.
        </li>
      </ul> */}
      <div style={styles.contractSummary}>
        <div style={styles.headerContainer}>
          <div style={styles.headerLeft}>
            <h1 style={styles.header}>Consumer Pay Monthly (Partner)</h1>
            <div style={styles.headerRight}>
              <address style={styles.address}>
                Hutchison 3G UK Limited
                <br />
                450 Longwater Avenue
                <br />
                Green Park
                <br />
                Reading
                <br />
                Berkshire
                <br />
                RG2 6GF
                <br />
                <br />
                Contact Customer Care: 333 (from a Three phone) or
                <br />
                0333 338 1001 (from any other phone)
              </address>
              <p style={styles.date}>{formattedDate}</p>
            </div>
            <h1 style={styles.header}>Contract summary</h1>
            <p>
              This contract summary provides the main elements of this service
              offer.
            </p>
            <p>It helps to make a comparison between service offers.</p>
            <p>
              Complete information about the service is provided in other
              documents.
            </p>
          </div>
        </div>

        <section style={styles.section}>
          <h2 style={styles.subHeader}>Services and equipment</h2>
          <p>Mobile voice telephony Mobile internet access</p>
          <p>
            Go Roam Fair Use Policies apply. See{" "}
            <a href="https://three.co.uk/aboutgoroam">
              three.co.uk/aboutgoroam
            </a>
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.subHeader}>
            Speeds of the Internet Service and Remedies
          </h2>
          {/* <p>
            Estimated maximum speeds customers are likely to experience on
            Three’s UK network:
          </p> */}
          <table style={styles.table}>
            <thead>
              <tr>
                <th colSpan={3} style={styles.tableHeader}>
                  Estimated maximum speeds customers are likely to experience on
                  Three’s UK network
                </th>
              </tr>
              <tr>
                <th style={styles.tableHeader}>Network</th>
                <th style={styles.tableHeader}>Download</th>
                <th style={styles.tableHeader}>Upload</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.tableCell}>3G</td>
                <td style={styles.tableCell}>18 Mbps</td>
                <td style={styles.tableCell}>2.6 Mbps</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>4G</td>
                <td style={styles.tableCell}>70 Mbps</td>
                <td style={styles.tableCell}>22 Mbps</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>5G</td>
                <td style={styles.tableCell}>597 Mbps</td>
                <td style={styles.tableCell}>50 Mbps</td>
              </tr>
            </tbody>
          </table>
          <p>
            Speeds may vary due to a number of factors (including the Device
            used) which are outlined in our{" "}
            <a href="https://three.co.uk/terms">terms</a>.
          </p>
          <p>
            If you experience disruption to services, you may be entitled to a
            price reduction or other remedy available under consumer law.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.subHeader}>Price</h2>
          {/* <p>Upfront: £{contractData?.creditCheck?.plan?.finalPrice}</p> */}
          <table style={styles.table}>
            {/* <thead>
              <tr>
                <th style={styles.tableHeader}>Allowance Information</th>
                <th style={styles.tableHeader}>Cost without discount</th>
                <th style={styles.tableHeader}>Cost with discount</th>
                <th style={styles.tableHeader}>Discount information</th>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td style={styles.tableCell}>Upfront</td>
                <td style={styles.tableCell}>£0</td>
                <td colSpan={3} style={styles.tableCell}></td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Package and Add-on</td>
                <td style={styles.tableCell}>Allowance Information</td>
                <td style={styles.tableCell}>Cost without discount</td>
                <td style={styles.tableCell}>Cost with discount</td>
                <td style={styles.tableCell}>Discount information</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Monthly Charge</td>
                <td style={styles.tableCell}>
                  {contractData?.creditCheck?.plan?.data === -1
                    ? "Unlimited Data"
                    : `${contractData?.creditCheck?.plan?.data} GB Data`}
                  ,{" "}
                  {contractData?.creditCheck?.plan?.noOfMinutes === -1
                    ? "Unlimited Minutes"
                    : `${contractData?.creditCheck?.plan?.noOfMinutes} Minutes`}
                  ,{" "}
                  {contractData?.creditCheck?.plan?.noOfText === -1
                    ? "Unlimited Text"
                    : `${contractData?.creditCheck?.plan?.noOfText} Text`}
                </td>
                <td style={styles.tableCell}>
                  £{contractData?.creditCheck?.plan?.tariffRecurringCharge}
                </td>
                <td style={styles.tableCell}>
                  £{contractData?.creditCheck?.plan?.finalPrice}
                </td>
                <td style={styles.tableCell}>
                  {contractData?.creditCheck?.plan?.discountInfo &&
                  contractData?.creditCheck?.plan?.discountInfo !== null &&
                  contractData?.creditCheck?.plan?.discountInfo.trim().length >
                    0 &&
                  contractData?.creditCheck?.plan?.discountInfo !== "null" &&
                  contractData?.voucher?.voucherApplyedMessage &&
                  contractData?.voucher?.voucherApplyedMessage !== null &&
                  contractData?.voucher?.voucherApplyedMessage.trim().length >
                    0 &&
                  contractData?.voucher?.voucherApplyedMessage !== "null"
                    ? contractData?.creditCheck?.plan?.discountInfo +
                      ", " +
                      contractData?.voucher?.voucherApplyedMessage +
                      "."
                    : contractData?.creditCheck?.plan?.discountInfo &&
                      contractData?.creditCheck?.plan?.discountInfo !== null &&
                      contractData?.creditCheck?.plan?.discountInfo.trim()
                        .length > 0 &&
                      contractData?.creditCheck?.plan?.discountInfo !== "null"
                    ? contractData?.creditCheck?.plan?.discountInfo + "."
                    : contractData?.voucher?.voucherApplyedMessage &&
                      contractData?.voucher?.voucherApplyedMessage !== null &&
                      contractData?.voucher?.voucherApplyedMessage.trim()
                        .length > 0 &&
                      contractData?.voucher?.voucherApplyedMessage !== "null"
                    ? contractData?.voucher?.voucherApplyedMessage + "."
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Add-On</td>
                <td style={styles.tableCell}></td>
                <td style={styles.tableCell}>£0</td>
                <td style={styles.tableCell}>£0</td>
                <td style={styles.tableCell}></td>
              </tr>
              <tr>
                <td style={styles.tableCell}></td>
                <td style={styles.tableCell}></td>
                <td style={styles.tableCell}>£0</td>
                <td style={styles.tableCell}>£0</td>
                <td style={styles.tableCell}></td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Total</td>
                <td style={styles.tableCell}></td>
                <td style={styles.tableCell}>
                  £{contractData?.creditCheck?.plan?.tariffRecurringCharge}
                </td>
                <td style={styles.tableCell}>
                  £{contractData?.creditCheck?.plan?.finalPrice}
                </td>
                <td style={styles.tableCell}></td>
              </tr>
            </tbody>
          </table>
          <p>
            Each April, your Monthly Charge will increase by a fixed amount
            depending on your plan’s data allowance. Plans 4GB or less &
            Smartwatch Pairing Plans will increase by £1.00 per month. Plans
            from 5GB to 99GB will increase by £1.25 per month. Plans 100GB or
            over will increase by £1.50 per month. All Home Broadband plans will
            increase by £2.00 per month. See{" "}
            <a href="https://three.co.uk/terms-conditions/price-guides">
              three.co.uk/terms-conditions/price-guides
            </a>{" "}
            for an example.
          </p>
          <p>
            To use your UK allowances for 24 hours in Go Roam in Europe there’s
            a £2 daily charge. When you Go Roam Around the World it’s £5 a day
            (excluding the Republic of Ireland and Isle of Man). Fair use
            policies apply.
          </p>
          <p>
            If you have used all your monthly inclusive minutes allowance and
            continue to make calls, you will be charged 65p per minute for
            standard calls. All Packages include unlimited minutes and texts
            unless otherwise stated.
          </p>
          <p>
            Price includes a £5 monthly discount for paying by a recurring
            method such as direct debit. For information on additional charges
            you may incur which are not included in your Package, please see
            your{" "}
            <a href="https://three.co.uk/terms-conditions/price-guides">
              Price Guide
            </a>
            .
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.subHeader}>Duration, Renewal, and Termination</h2>
          <p>
            Minimum Term: {contractData?.creditCheck?.plan?.contractTerm} Months
          </p>
          <p>
            You can end your Agreement at any time by giving us 30 days’ notice,
            or you can choose to switch provider. If you are within your Minimum
            Term, you’ll need to pay all Outstanding Charges due plus a
            Cancellation Fee.
          </p>
          <p>
            If you made your purchase online or via telesales, you will also be
            entitled to end your services agreement with Three in accordance
            with Three’s Returns Policy. For more details on termination, see{" "}
            <a href="https://three.co.uk/terms-conditions/price-guides">
              terms and conditions
            </a>
            .
          </p>
          <p>
            Equipment can be returned in accordance with the Partner’s returns
            policy.
          </p>
          <p>
            Any Monthly Add-Ons will be applied automatically each month unless
            you provide one month’s notice to remove them.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.subHeader}>Accessibility Features</h2>
          <p>
            If you would like a copy of this document in an alternative format
            (e.g. Braille or large print), contact Three on the details above.
          </p>
          <p>
            For information on Three’s accessibility services, see{" "}
            <a href="https://three.co.uk/Accessibility">
              three.co.uk/Accessibility
            </a>
            .
          </p>
        </section>

        {/* <footer style={styles.footer}>
          <p>Consumer Pay Monthly (Partner)</p>
        </footer> */}
        {/* <div style={styles.headerRight}>
          <address style={styles.address}>
            Hutchison 3G UK Limited
            <br />
            450 Longwater Avenue
            <br />
            Green Park
            <br />
            Reading
            <br />
            Berkshire
            <br />
            RG2 6GF
            <br />
            <br />
            Contact Customer Care: 333 (from a Three phone) or
            <br />
            0333 338 1001 (from any other phone)
          </address>
          {/* <p style={styles.date}>DD Month YYYY</p>}
        </div> */}
      </div>

      <div className="contract-footer border-t-2">
        <div className="d-flex justify-content-start align-items-start">
          <div className="custom-checkbox flex-col items-center pt-6">
            <div className="flex justify-center items-center mx-auto">
              <div>
                <button
                  onClick={handleReadContract}
                  className={`dummy-checkbox relative mr-[10px]`}
                >
                  {buttonClicked ? (
                    <span className="dummy-checked"></span>
                  ) : null}
                </button>
              </div>
              <label
                name="contract-info"
                htmlFor="contract-info"
                className="font-semibold text-base mr-[10px]"
              >
                I have read and agree to the contract
              </label>
            </div>
            <button
              disabled={!buttonClicked}
              onClick={() => {
                handleProceedClick(); // Call your function here
                dispatch(closeModal());
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className={`${
                !buttonClicked ? "btn-disabled" : ""
              } cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto`}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalForPortInFail = ({ navigate, dispatch }) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        We regret to inform you that you are not eligible for Port In.
      </h2>
      <p className="text-sm">
        However, you can a get new number from Three. Please select option one.
      </p>
      <div className="w-full flex justify-center items-center gap-4">
        <Button
          handleClick={() => {
            navigate("/");
            dispatch(closeModal());
          }}
          title="Go To Home Page"
          type="button"
          // style="
          //   rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 text-red-600 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-semibold px-3 py-1.5 text-center
          //   "
          style="cta-two"
        />
        <Button
          handleClick={() => {
            dispatch(closeModal());
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Get a New Number"
          type="button"
          style="cta-two"
        />
      </div>
    </div>
  );
};

const ModalForPortInSuccess = ({ dispatch }) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        You are eligible for Port In. You can proceed further.
      </h2>
      <div className="w-full flex justify-center items-center">
        <Button
          handleClick={() => {
            dispatch(closeModal());
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Proceed"
          type="button"
          style="cta-two"
        />
      </div>
    </div>
  );
};
const ModelForTimer = ({
  formattedTime,
  customerMessage,
  verificationMessage,
  isResetButton,
  handleResendVerificationLink,
  resendBtnDisabled,
  handleManualCheckToVerify,
}) => {
  return (
    <div>
      <h2 className="text-md text-center font-semibold mb-2">
        Please Verify Your Email to proceed.
      </h2>
      <div className="w-full flex flex-col items-center justify-center mt-4">
        <FiClock className="text-[42px]" />
        <h2 className="font-bold">{formattedTime}</h2>
        <span className="info">
          *Verification status checking is in progress
        </span>
        {customerMessage ? (
          <p className="error-text text-center">{customerMessage}</p>
        ) : null}
        {verificationMessage ? (
          <p className="text-center text-blue-700">{verificationMessage}</p>
        ) : null}

        {isResetButton ? (
          <>
            <div className="w-full flex gap-3 mt-1">
              <LoaderButton
                handleClick={handleResendVerificationLink}
                title="Resend"
                disabled={resendBtnDisabled}
                type="button"
                style="mt-2 flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              />
              <Button
                handleClick={handleManualCheckToVerify}
                title="Check Status"
                type="button"
                style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent  hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
const ModelForLogin = ({ dispatch, navigate }) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        Please Login to proceed further.
      </h2>
      <div className="w-full flex justify-between items-center mt-4">
        <Button
          handleClick={() => {
            dispatch(closeModal("login"));
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            navigate("/login");
            dispatch(closeModal());
          }}
          title="Proceed"
          type="button"
          style="cta-two"
        />
      </div>
    </div>
  );
};

const ModelForEmailConfirm = ({
  dispatch,
  setSubmitLoader,
  setLoader,
  handlePersonalDeatils,
}) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        Please Make Sure Your Email-Id is Correct.
      </h2>
      <div className="w-full flex justify-center items-center mt-4">
        <Button
          handleClick={() => {
            dispatch(closeModal("emailConfirm"));
            setSubmitLoader(false);
            setLoader(true);
            handlePersonalDeatils();
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Okay"
          type="button"
          style="cta-two mx-auto w-1/2"
        />
      </div>
    </div>
  );
};

const ModelForCheckoutButton = ({
  resendBtnDisabled,
  handleCheckoutButtonClick,
  propsError,
}) => {
  const [iccid, setIccid] = useState("");
  const [error, setError] = useState("");
  // const inputRef = useRef(null);
  // useEffect(() => {
  //   // Force focus on the input field when the component mounts
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, []);

  const handleSubmit = (iccidResult) => {
    // console.log("handleSubmit", iccidResult);
    localStorage.setItem("iccidStore", iccidResult);
    setError("");
    let tempError = "";

    // Validation for ICCID
    if (!iccidResult || iccidResult <= 0) {
      tempError = "Please enter a valid ICCID";
    } else if (iccidResult.length !== 19) {
      tempError = "ICCID must be exactly 19 digits";
    }

    if (tempError) {
      // console.log("error");
      setError(tempError);
    } else {
      // console.log("no error");
      setError("");
      // setIccidValue(iccidResult);
      handleCheckoutButtonClick(iccidResult);
    }
  };
  // console.log("propsError===>", propsError);
  const scanCode = () => {
    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 350,
        height: 250,
      },
      fps: 2,
    });
    function onScanSuccess(decodedText, decodedResult) {
      // Handle on success condition with the decoded text or result.
      //console.log("Scan result:", decodedText, decodedResult);
      setIccid(decodedText);
      scanner.clear();
      handleSubmit(decodedText);
    }
    function onScanError(errorMessage) {
      //console.log("Scan error:", errorMessage);
    }
    scanner.render(onScanSuccess, onScanError);
  };

  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        Please enter the ICCID to proceed further.
      </h2>
      <div className="w-full mt-4">
        <input
          value={iccid}
          onChange={(e) => {
            setIccid(e.target.value.trim());
            if (error) {
              setError("");
            }
            localStorage.setItem("iccid", iccid);
          }}
          type="number"
          id="iccid"
          placeholder="Please enter ICCID number"
          className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
        />
        <span className="or-text">OR</span>
        <div className="flex justify-center items-center">
          <Button
            handleClick={scanCode}
            title={
              <div className="flex justify-center items-center">
                <CiBarcode className="text-2xl mr-2" />
                Scan ICCID Barcode
              </div>
            }
            type="button"
            style={
              "cta-one px-4 py-2 mb-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            }
          />
        </div>
        <div id="reader"></div>
        {/* <input
          type="text"
          ref={inputRef} // Reference to the input field for programmatic focus
          value={iccid}
          onChange={(e) => {
            setIccid(e.target.value);
            if (error) {
              setError('');
            }
          }}
          placeholder="Enter ICCID"
          // className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:border-blue-500"
          className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
        /> */}
        {error ? (
          <div className="h-5 mt-3">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
        ) : (
          propsError && (
            <div className="h-5 mt-3">
              <p className="text-red-600 text-sm">{propsError?.checkout}</p>
            </div>
          )
        )}
        <LoaderButton
          title="Submit"
          type="button"
          disabled={resendBtnDisabled}
          handleClick={() => handleSubmit(iccid)}
          style="w-full px-4 py-2 mt-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        />
      </div>
    </div>
  );
};

const ModelForAcknowledgeContract = ({
  handleResendAcknowledgeEmail,
  resendBtnDisabled,
  ackEmailMessage,
  resendEmailMessage,
  handleCheckoutContract,
  customerMessage,
  isResetButton,
  formattedTime,
  handleManualCheckAcknowledge,
}) => {
  const [showCustomerMessage, setShowCustomerMessage] = useState(false);

  const handleCheckStatus = () => {
    setShowCustomerMessage(true); // Enable showing the message after the first check
    handleManualCheckAcknowledge();
  };

  return (
    <div>
      <h2 className="text-md text-center font-semibold mb-2">
        Please acknowledge before proceeding to checkout.
      </h2>
      <div className="w-full flex flex-col items-center justify-center mt-4">
        <FiClock className="text-[42px]" />
        <h2 className="font-bold">{formattedTime}</h2>
        <span className="info">
          *Verification status checking is in progress
        </span>
        {ackEmailMessage && (
          <p className="success-text text-center">{ackEmailMessage}</p>
        )}
        {resendEmailMessage && (
          <p className="error-text text-center">{resendEmailMessage}</p>
        )}
        {showCustomerMessage && customerMessage && (
          <p className="error-text text-center">{customerMessage}</p>
        )}

        {isResetButton && (
          <div className="w-full flex gap-3 mt-1">
            <LoaderButton
              handleClick={handleResendAcknowledgeEmail}
              title="Resend"
              disabled={resendBtnDisabled}
              type="button"
              style="mt-2 flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            />
            <Button
              handleClick={handleCheckStatus}
              title="Check Status"
              type="button"
              style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
            />
          </div>
         )} 
      </div>
    </div>
  );
};

const ModelForProceedToCheckout = ({ dispatch }) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        Congratulations! Your credit check has passed successfully. You can now
        proceed with the next steps.
      </h2>
      <div className="w-full flex justify-center items-center">
        <Button
          handleClick={() => {
            dispatch(closeModal());
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Proceed"
          type="button"
          style="cta-two"
        />
      </div>
    </div>
  );
};

const ModelForCreditVerification = ({
  dispatch,
  handleSubmitButton,
  creditCheckLoader,
}) => {
  return (
    <div>
      {creditCheckLoader && <MicroLoaderGreen size={62} />}
      <h2
        className={`${
          creditCheckLoader ? "text-center mt-2" : ""
        } text-md font-semibold mb-2`}
      >
        {creditCheckLoader
          ? "Please wait credit check is inprogress"
          : " Please note that proceeding will initiate a soft credit check. Ensure all your inputs are correct. Do you wish to continue?"}
      </h2>
      {!creditCheckLoader && (
        <div className="w-full flex justify-between items-center mt-4">
          <Button
            handleClick={() => {
              // navigate("/");
              dispatch(closeModal());
            }}
            title="Cancel"
            type="button"
            style={`${
              creditCheckLoader ? "btn-disabled" : ""
            } rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 text-red-600 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-semibold px-3 py-1.5 text-center`}
          />
          <Button
            handleClick={() => {
              handleSubmitButton();
            }}
            title="Proceed"
            type="button"
            style={`${creditCheckLoader ? "btn-disabled" : ""} cta-two`}
          />
        </div>
      )}
    </div>
  );
};

const ModelForBadReqInCreateOrderAPI = ({
  dispatch,
  setSubmitLoader,
  setLoader,
  errorMessage,
  handleCheckoutButtonClick,
  setSubmitButtonDisable,
  setErrors,
}) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">{errorMessage}</h2>
      <div className="w-full flex justify-center items-center mt-4">
        <Button
          handleClick={() => {
            const storedIccid = localStorage.getItem("iccidStore");
            handleCheckoutButtonClick(storedIccid);
            setErrors((prev) => ({
              ...prev,
              checkout: "",
            }));
            dispatch(closeModal("badReqCheckInCreateOrderAPI"));
            setSubmitButtonDisable(true);
            setSubmitLoader(false);
            setLoader(true);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Retry"
          type="button"
          style="cta-two mx-auto w-1/2"
        />
      </div>
    </div>
  );
};

const ModelForBadReqInCreateOrderAPICustomMsg = ({
  dispatch,
  setSubmitLoader,
  setLoader,
  errorMessage,
  setSubmitButtonDisable,
  setErrors,
  setEnableCheckoutButton,
  setIsContractRead,
  setIsTNCChecked,
  setButtonClicked,
}) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">{errorMessage}</h2>
      <div className="w-full flex justify-center items-center mt-4">
        <Button
          handleClick={() => {
            // handleCheckoutButtonClick(null);
            setErrors((prev) => ({
              ...prev,
              checkout: "",
            }));
            dispatch(closeModal("badReqCheckInCreateOrderAPICustomMsg"));
            setSubmitLoader(false);
            setSubmitButtonDisable(false);
            // console.log("isContract==============>>>>>>inside model");
            setIsContractRead(false);
            setEnableCheckoutButton(false);
            setIsTNCChecked(false);
            setButtonClicked(false);
            setLoader(false);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Check your input"
          type="button"
          style="cta-two mx-auto w-1/2"
        />
      </div>
    </div>
  );
};

const ModelForInvalidSIM = ({
  dispatch,
  setSubmitLoader,
  setLoader,
  errorMessage,
  setSubmitButtonDisable,
}) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">{errorMessage}</h2>
      <div className="w-full flex justify-center items-center mt-4">
        <Button
          handleClick={() => {
            // handleCheckoutButtonClick(null);
            dispatch(closeModal("invalidSim"));
            setSubmitLoader(false);
            setSubmitButtonDisable(true);
            setLoader(false);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Okay"
          type="button"
          style="cta-two mx-auto w-1/2"
        />
      </div>
    </div>
  );
};

const ModelForUserUpdateRequest = ({
  formattedTime,
  customerMessage,
  verificationMessage,
  isResetButton,
  handleResendVerificationLinkForUserUpdateReq,
  resendBtnDisabled,
  handleManualCheckToVerifyUserUpdateReq,
}) => {
  return (
    <div>
      <h2 className="text-md text-center font-semibold mb-2">
        Please Verify Your Email to proceed.
      </h2>
      <div className="w-full flex flex-col items-center justify-center mt-4">
        <FiClock className="text-[42px]" />
        <h2 className="font-bold">{formattedTime}</h2>
        <span className="info">
          *Verification status checking is in progress
        </span>
        {customerMessage ? (
          <p className="error-text text-center">{customerMessage}</p>
        ) : null}
        {verificationMessage ? (
          <p className="text-center text-blue-700">{verificationMessage}</p>
        ) : null}

        {isResetButton ? (
          <>
            <div className="w-full flex gap-3 mt-1">
              <LoaderButton
                handleClick={handleResendVerificationLinkForUserUpdateReq}
                title="Resend"
                disabled={resendBtnDisabled}
                type="button"
                style="mt-2 flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              />
              <Button
                handleClick={handleManualCheckToVerifyUserUpdateReq}
                title="Check Status"
                type="button"
                style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent  hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
//Modal content ends

export default function ProductDetails() {
  useScrollToTop();
  const dispatch = useDispatch();
  const modalInitState = useSelector((state) => state.modal);
  const navigate = useNavigate();
  const modalSliceState = useSelector(selectModalState);
  const checkoutFormRef = useRef(null);
  const [isApplyDiscountDisabled, setIsApplyDiscountDisabled] = useState(false);
  const [checkContractEmailStatus, setCheckContractEmailStatus] =
    useState(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);

  const checkoutSteps = [1, 2, 3, 4, 5, 6];
  const [creditCheck, setCreditCheck] = useState(
    JSON.parse(localStorage.getItem("creditCheck"))
      ? JSON.parse(localStorage.getItem("creditCheck"))
      : {}
  );
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [creditCheckLoader, setCreditCheckLoader] = useState(false);
  const [activeSteps, setActiveSteps] = useState([1]);
  const [currentStep, setCurrentStep] = useState(1);

  const [isTNCChecked, setIsTNCChecked] = useState(false);
  const [isContractRead, setIsContractRead] = useState(false);
  // console.log("isContractRead====>",isContractRead);
  const [buttonClicked, setButtonClicked] = useState(false);

  const [enableCheckout, setEnableCheckout] = useState(false);
  const [enableCheckoutButton, setEnableCheckoutButton] = useState(false);
  const [activeZipCodeSearch, setActiveZipCodeSearch] = useState(false);
  const [isLogin, setIsLogin] = useState();

  // managing all the steps
  const [creditCheckId, setCreditCheckId] = useState(null);
  // step 1
  const [billCappingValue, setBillCappingValue] = useState(null);
  const [billCappAmount, setBillCappAmount] = useState(null);
  const [billCappingError, setBillCappingError] = useState(null);
  // step 2
  // const [alternativeFormatValue, setAlternativeFormatValue] = useState(null);
  const [alternativeFormatValue, setAlternativeFormatValue] = useState(2);

  // step 3
  const [mobileNumberSelection, setMobileNumberSelection] = useState(null);
  const [mobileNumberSelectionValue, setMobileNumberSelectionValue] =
    useState(null);
  // const [pacNo,setPacNo]=useState();
  // const [msidnForPacNo,setMsidnForPacNo]=useState();
  //Port In eligibility
  const [pacNumber, setPacNumber] = useState(null);
  const [misdinNumber, setMisdinNumber] = useState(null);
  const [stackNumber, setStacNumber] = useState(null);
  const [pacNoError, setPacNoError] = useState();
  const [msisdnError, setMsisdnError] = useState();
  const [stacNoError, setStacNoError] = useState();
  const [msisdnErrorForStac, setMsisdnErrorForStac] = useState();

  //Credit check
  const [isCreditFail, setIsCreditFail] = useState(false);
  // step 4
  const [affiliateValue, setAffiliateValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [contactNoValue, setContactNoValue] = useState("");
  const [marketingOptValue, setMarketingOptValue] = useState([]);
  const [seconds, setSeconds] = useState(30);
  const [isResetButton, setIsResetButton] = useState(false);
  const [verifyBtnDisabled, setVerifyBtnDisabled] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [time, setTime] = useState(30);
  const [thirtySecTimer, setThirtySecTimer] = useState();
  const [resendBtnDisabled, setResendBtnDisabled] = useState(false);
  const [customerMessage, setCustomerMessage] = useState();
  const [verificationMessage, setVerificationMessage] = useState("");
  const [step4First, setStep4First] = useState("0");
  const [openVerificationModal, setOpenVerificationModal] = useState(false);

  //step 5
  const [dobValue, setDobValue] = useState("");
  const [maritalValue, setMaritalValue] = useState("");
  const [residentialStatusValue, setResidentialStatus] = useState(null);
  const [employmentStatusValue, setEmploymentStatusValue] = useState(null);
  const [gktNoValue, setGktNoValue] = useState(null);
  const [monthValue, setMonthValue] = useState(null);
  const [yearValue, setYearValue] = useState();
  const [employeTime, setEmployeTime] = useState(null);
  const [bankAccountSince, setBankAccountSince] = useState(null);
  const [bankAccountSinceYearValue, setBankAccountSinceYearValue] = useState();
  const [bankAccountSinceMonthValue, setBankAccountSinceMonthValue] =
    useState(null);
  const [bicValue, setBicValue] = useState(null);
  const [ibanValue, setIbanValue] = useState(null);
  const [iccidValue, setIccidValue] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [previousAddress1DeliveryMethod, setPreviousAddress1DeliveryMethod] =
    useState(null);
  const [previousAddress2DeliveryMethod, setPreviousAddress2DeliveryMethod] =
    useState(null);
  const [previousAddress3DeliveryMethod, setPreviousAddress3DeliveryMethod] =
    useState(null);
  const [addressTotalDurationStep, setAddressTotalDurationStep] = useState(1);
  const [currentAddressSince, setCurrentAddressSince] = useState(null);
  const [currentAddressSinceYearValue, setCurrentAddressSinceYearValue] =
    useState();
  const [currentAddressSinceMonthValue, setCurrentAddressSinceMonthValue] =
    useState(null);

  const [previousAddressSince1, setPreviousAddressSince1] = useState(null);
  const [previousAddressSince1YearValue, setPreviousAddressSince1YearValue] =
    useState();
  const [previousAddressSince1MonthValue, setPreviousAddressSince1MonthValue] =
    useState(null);

  const [previousAddressSince2, setPreviousAddressSince2] = useState(null);
  const [previousAddressSince2YearValue, setPreviousAddressSince2YearValue] =
    useState();
  const [previousAddressSince2MonthValue, setPreviousAddressSince2MonthValue] =
    useState(null);

  const [previousAddressSince3, setPreviousAddressSince3] = useState(null);
  const [previousAddressSince3YearValue, setPreviousAddressSince3YearValue] =
    useState();
  const [previousAddressSince3MonthValue, setPreviousAddressSince3MonthValue] =
    useState(null);

  const [submitLoader, setSubmitLoader] = useState(false);

  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [isAffiliateValueReadonly, setIsAffiliateValueReadonly] =
    useState(true);
  const [isEmailValueReadonly, setIsEmailValueReadonly] = useState(false);
  const [order, setOrder] = useState({
    subtotalAmount: creditCheck.plan.finalPrice,
    discountAmount: 0,
    amount: creditCheck.plan.finalPrice,
    voucher: null,
  });
  const [voucherCode, setVoucherCode] = useState(null);
  const [dateObject, setDateObject] = useState(null);

  // search suggestion
  // console.log("setIsContractRead",isContractRead);
  const dummyAddresses = [
    "John Lark, 123 Main St, Apt 4B, 2 floor st 2, London, UK, 10001",
    "Clark Kent, 456 Second St, Apt 4B, 2 floor st 2, Bristol, UK, 10002",
    "Bruce wayne, 456 Second St, Apt 4B, Gotham, Bristol, UK, 10002",
    "John Lark, 123 Main St, Apt 4B, 2 floor st 2, London, UK, 10001",
    "Clark Kent, 456 Second St, Apt 4B, 2 floor st 2, Bristol, UK, 10002",
    "Bruce wayne, 456 Second St, Apt 4B, Gotham, Bristol, UK, 10002",
  ];
  const dummyAddressesBilling = [
    "John Lark, 123 Main St, Apt 4B, 2 floor st 2, London, UK, 10001",
    "Clark Kent, 456 Second St, Apt 4B, 2 floor st 2, Bristol, UK, 10002",
    "Bruce wayne, 456 Second St, Apt 4B, Gotham, Bristol, UK, 10002",
    "John Lark, 123 Main St, Apt 4B, 2 floor st 2, London, UK, 10001",
    "Clark Kent, 456 Second St, Apt 4B, 2 floor st 2, Bristol, UK, 10002",
    "Bruce wayne, 456 Second St, Apt 4B, Gotham, Bristol, UK, 10002",
  ];

  // shipping add
  const [shippingZipCode, setShippingZipCode] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [shippingBtnDisable, setShippingBtnDisable] = useState(false);
  const [shippingAddValue, setShippingAddValue] = useState(null);
  const [shippingPostCode, setShippingPostCode] = useState(null);
  const [shippingAddressKey, setShippingAddressKey] = useState(null);
  // previous shipping add 1
  const [p1ShippingZipCode, setP1ShippingZipCode] = useState(null);
  const [p1ShippingAddress, setP1ShippingAddress] = useState(null);
  const [p1ShippingBtnDisable, setP1ShippingBtnDisable] = useState(false);
  const [p1ShippingAddValue, setP1ShippingAddValue] = useState(null);
  const [p1ShippingPostCode, setP1ShippingPostCode] = useState(null);
  const [p1ShippingAddressKey, setP1ShippingAddressKey] = useState(null);
  // previous shipping add 2
  const [p2ShippingZipCode, setP2ShippingZipCode] = useState(null);
  const [p2ShippingAddress, setP2ShippingAddress] = useState(null);
  const [p2ShippingBtnDisable, setP2ShippingBtnDisable] = useState(false);
  const [p2ShippingAddValue, setP2ShippingAddValue] = useState(null);
  const [p2ShippingPostCode, setP2ShippingPostCode] = useState(null);
  const [p2ShippingAddressKey, setP2ShippingAddressKey] = useState(null);
  // previous shipping add 3
  const [p3ShippingZipCode, setP3ShippingZipCode] = useState(null);
  const [p3ShippingAddress, setP3ShippingAddress] = useState(null);
  const [p3ShippingBtnDisable, setP3ShippingBtnDisable] = useState(false);
  const [p3ShippingAddValue, setP3ShippingAddValue] = useState(null);
  const [p3ShippingPostCode, setP3ShippingPostCode] = useState(null);
  const [p3ShippingAddressKey, setP3ShippingAddressKey] = useState(null);

  // billing add
  const [billingAddressActive, setBillingAddressActive] = useState(false);
  const [billingZipCode, setBillingZipCode] = useState(null);
  const [billingAddress, setBillingAddress] = useState(false);
  const [billingBtnDisable, setBillingBtnDisable] = useState(false);
  const [billingAddValue, setBillingAddValue] = useState(null);
  const [billingPostCode, setBillingPostCode] = useState(null);
  const [billingAddressKey, setBillingAddressKey] = useState(null);

  let isLoggedIn = localStorage.getItem("loggedIn");
  let isVerified = localStorage.getItem("isVerified");

  const [currentPlanData, setCurrentPlanData] = useState(null);
  // States end here
  //   useEffect(()=>{
  //     if(creditCheck){
  //       // const firstLastName = localStorage.getItem("FirstLastName") || "";

  // // Step 2: Split FirstLastName into [firstName, lastName]
  //     const [storedFirstName, storedLastName] = firstLastName.split(" ");

  //       localStorage.setItem("fName",creditCheck.firstName);
  //       localStorage.setItem("lName",creditCheck.lastName);
  //     }
  //   },[creditCheck])
  useEffect(() => {
    setBillCappingValue(creditCheck.billCaping?.billCappingId);
    setBillCappAmount(creditCheck.billCappingAmount);
    setAlternativeFormatValue(
      creditCheck.alternativeFormat?.alternativeFormatId
    );
    setMobileNumberSelection(
      creditCheck.mobileNumberSelection?.mobileNoSelectionId
    );
    setMobileNumberSelectionValue(
      creditCheck.mobileNumberSelection?.mobileNoSelectionId
    );
    setAffiliateValue(creditCheck.affIdOrRefCode);
    setMarketingOptValue(
      creditCheck.marketingOptIn ? creditCheck.marketingOptIn : []
    );
    setFirstNameValue(creditCheck.firstName);
    setLastNameValue(creditCheck.lastName);
    setEmailValue(creditCheck.emailId);
    setContactNoValue(creditCheck.contactNumber);
    setDobValue(creditCheck.dateOfBirth);
    setResidentialStatus(
      creditCheck.residentialStatus?.residentialStatusId > 0
        ? creditCheck.residentialStatus?.residentialStatusId
        : null
    );
    setMaritalValue(
      creditCheck.maritalStatus?.martialStatusId > 0
        ? creditCheck.maritalStatus?.martialStatusId
        : null
    );
    setEmploymentStatusValue(
      creditCheck.employmentStatus?.employmentStatusId > 0
        ? creditCheck.employmentStatus?.employmentStatusId
        : null
    );
    setGktNoValue(
      creditCheck.existingGktNumber?.existingGktId > 0
        ? creditCheck.existingGktNumber?.existingGktId
        : null
    );
    setEmployeTime(creditCheck.timeWithEmployer);
    setYearValue(
      creditCheck.timeWithEmployerYear > 0
        ? creditCheck.timeWithEmployerYear
        : null
    );
    setMonthValue(
      creditCheck.timeWithEmployerMonth > 0
        ? creditCheck.timeWithEmployerMonth
        : null
    );
    setBankAccountSince(creditCheck.bankAccSince);
    setBankAccountSinceYearValue(
      creditCheck.bankAccSinceYear > 0 ? creditCheck.bankAccSinceYear : null
    );
    setBankAccountSinceMonthValue(
      creditCheck.bankAccSinceMonth > 0 ? creditCheck.bankAccSinceMonth : null
    );
    setBicValue(creditCheck.bicCode);
    setIbanValue(creditCheck.ibanNumber);
    setIccidValue(creditCheck.iccid);
    setPacNumber(creditCheck.pacNumber);
    setMisdinNumber(creditCheck.msisdn);
    setStacNumber(creditCheck.stacNumber);
    setDeliveryMethod(
      creditCheck.deliveryMethod?.deliveryMethodId > 0
        ? creditCheck.deliveryMethod?.deliveryMethodId
        : null
    );
    setPreviousAddress1DeliveryMethod(
      creditCheck.previousAddress1DeliveryMethod?.deliveryMethodId > 0
        ? creditCheck.previousAddress1DeliveryMethod?.deliveryMethodId
        : null
    );
    setPreviousAddress2DeliveryMethod(
      creditCheck.previousAddress2DeliveryMethod?.deliveryMethodId > 0
        ? creditCheck.previousAddress2DeliveryMethod?.deliveryMethodId
        : null
    );
    setPreviousAddress3DeliveryMethod(
      creditCheck.previousAddress3DeliveryMethod?.deliveryMethodId > 0
        ? creditCheck.previousAddress3DeliveryMethod?.deliveryMethodId
        : null
    );
    setCurrentAddressSince(creditCheck.currentAddressSince);
    setCurrentAddressSinceYearValue(
      creditCheck.currentAddressSinceYear > 0
        ? creditCheck.currentAddressSinceYear
        : null
    );
    setCurrentAddressSinceMonthValue(
      creditCheck.currentAddressSinceMonth > 0
        ? creditCheck.currentAddressSinceMonth
        : null
    );

    setPreviousAddressSince1(creditCheck.previousAddressSince1);
    setPreviousAddressSince1YearValue(
      creditCheck.previousAddressSince1Year > 0
        ? creditCheck.previousAddressSince1Year
        : null
    );
    setPreviousAddressSince1MonthValue(
      creditCheck.previousAddressSince1Month > 0
        ? creditCheck.previousAddressSince1Month
        : null
    );

    setPreviousAddressSince2(creditCheck.previousAddressSince2);
    setPreviousAddressSince2YearValue(
      creditCheck.previousAddressSince2Year > 0
        ? creditCheck.previousAddressSince2Year
        : null
    );
    setPreviousAddressSince2MonthValue(
      creditCheck.previousAddressSince2Month > 0
        ? creditCheck.previousAddressSince2Month
        : null
    );

    setPreviousAddressSince3(creditCheck.previousAddressSince3);
    setPreviousAddressSince3YearValue(
      creditCheck.previousAddressSince3Year > 0
        ? creditCheck.previousAddressSince3Year
        : null
    );
    setPreviousAddressSince3MonthValue(
      creditCheck.previousAddressSince3Month > 0
        ? creditCheck.previousAddressSince3Month
        : null
    );

    setShippingZipCode(creditCheck.shippingAddress?.postCode);
    setShippingPostCode(creditCheck.shippingAddress?.postCode);
    setShippingAddValue(creditCheck.shippingAddress?.addressStr);
    setShippingAddressKey(creditCheck.shippingAddress?.addressKey);

    setP1ShippingZipCode(creditCheck.previousShippingAddress1?.postCode);
    setP1ShippingPostCode(creditCheck.previousShippingAddress1?.postCode);
    setP1ShippingAddValue(creditCheck.previousShippingAddress1?.addressStr);
    setP1ShippingAddressKey(creditCheck.previousShippingAddress1?.addressKey);

    setP2ShippingZipCode(creditCheck.previousShippingAddress2?.postCode);
    setP2ShippingPostCode(creditCheck.previousShippingAddress2?.postCode);
    setP2ShippingAddValue(creditCheck.previousShippingAddress2?.addressStr);
    setP2ShippingAddressKey(creditCheck.previousShippingAddress2?.addressKey);

    setP3ShippingZipCode(creditCheck.previousShippingAddress3?.postCode);
    setP3ShippingPostCode(creditCheck.previousShippingAddress3?.postCode);
    setP3ShippingAddValue(creditCheck.previousShippingAddress3?.addressStr);
    setP3ShippingAddressKey(creditCheck.previousShippingAddress3?.addressKey);

    setBillingZipCode(creditCheck.billingAddress?.postCode);
    setBillingPostCode(creditCheck.billingAddress?.postCode);
    setBillingAddValue(creditCheck.billingAddress?.addressStr);
    setBillingAddressKey(creditCheck.billingAddress?.addressKey);
    if (
      creditCheck.shippingAddress &&
      creditCheck.shippingAddress.addressId != null &&
      creditCheck.billingAddress &&
      creditCheck.billingAddress.addressId != null &&
      creditCheck.shippingAddress.addressId !==
        creditCheck.billingAddress.addressId
    ) {
      setBillingAddressActive(false);
    }
    if (userRoleId != null && userRoleId === "ROLE_DEALER") {
      setIsAffiliateValueReadonly(true);
    } else {
      if (
        creditCheck.affIdOrRefCode != null &&
        creditCheck.affIdOrRefCode.length > 0
      ) {
        setIsAffiliateValueReadonly(true);
      } else {
        setIsAffiliateValueReadonly(false);
      }
      let loggedInEmailId = localStorage.getItem("emailId");
      if (loggedInEmailId != null && loggedInEmailId.length > 0) {
        setEmailValue(loggedInEmailId);
        setIsEmailValueReadonly(true);
      }
    }
  }, []);

  useEffect(() => {
    const name = localStorage.getItem("name");
    if (userRoleId != null && userRoleId !== "ROLE_DEALER") {
      if (name) {
        const nameParts = name.split(" ");
        setFirstNameValue(nameParts[0]);
        setLastNameValue(nameParts[1] || ""); // Handle case where last name might be missing
      }
    }
  }, []);

  useEffect(() => {
    var nameModified = false;
    const originalFullName = localStorage.getItem("FirstLastName");
    if (originalFullName) {
      const [originalFirstName, originalLastName] = originalFullName?.split(
        " "
      ) || ["", ""];

      nameModified = false;
      nameModified =
        firstNameValue !== originalFirstName ||
        lastNameValue !== originalLastName
          ? true
          : false;
    }
    if (localStorage.getItem("roleId") == "ROLE_CUSTOMER") {
      nameModified = true;
    }
    setCreditCheck((prev) => ({
      ...prev,
      billCaping: { ...prev.billCaping, billCappingId: billCappingValue },
      billCappingAmount: billCappingValue === 1 ? billCappAmount : 0,
      alternativeFormat: {
        ...prev.alternativeFormat,
        alternativeFormatId: alternativeFormatValue,
      },
      mobileNumberSelection: {
        ...prev.mobileNumberSelection,
        mobileNoSelectionId: mobileNumberSelectionValue,
      },
      affIdOrRefCode: affiliateValue,
      marketingOptIn: marketingOptValue,
      existingGktNumber: {
        ...prev.existingGktNumber,
        existingGktId: gktNoValue,
      },
      dateOfBirth: dobValue,
      residentialStatus: {
        ...prev.residentialStatus,
        residentialStatusId: residentialStatusValue,
      },
      contactNumber: contactNoValue,
      emailId: emailValue,
      maritalStatus: { ...prev.maritalStatus, martialStatusId: maritalValue },
      employmentStatus: {
        ...prev.employmentStatus,
        employmentStatusId: employmentStatusValue,
      },
      firstName: firstNameValue,
      lastName: lastNameValue,
      nameModified: nameModified,
      timeWithEmployer: employeTime,
      timeWithEmployerYear: yearValue,
      timeWithEmployerMonth: monthValue,
      bankAccSince: bankAccountSince,
      bankAccSinceYear: bankAccountSinceYearValue,
      bankAccSinceMonth: bankAccountSinceMonthValue,
      bicCode: bicValue,
      ibanNumber: ibanValue,
      iccid: iccidValue,
      sameAddress: billingAddressActive,
      shippingAddress: {
        ...prev.shippingAddress,
        postCode: shippingPostCode,
        addressStr: shippingAddValue,
        addressKey: shippingAddressKey,
      },
      previousShippingAddress1: {
        ...prev.previousShippingAddress1,
        postCode: p1ShippingPostCode,
        addressStr: p1ShippingAddValue,
        addressKey: p1ShippingAddressKey,
      },
      previousShippingAddress2: {
        ...prev.previousShippingAddress2,
        postCode: p2ShippingPostCode,
        addressStr: p2ShippingAddValue,
        addressKey: p2ShippingAddressKey,
      },
      previousShippingAddress3: {
        ...prev.previousShippingAddress3,
        postCode: p3ShippingPostCode,
        addressStr: p3ShippingAddValue,
        addressKey: p3ShippingAddressKey,
      },
      billingAddress: {
        ...prev.billingAddress,
        postCode: billingPostCode,
        addressStr: billingAddValue,
        addressKey: billingAddressKey,
      },
      deliveryMethod: {
        ...prev.deliveryMethod,
        deliveryMethodId: deliveryMethod,
      },
      previousAddress1DeliveryMethod: {
        ...prev.previousAddress1DeliveryMethod,
        deliveryMethodId: previousAddress1DeliveryMethod,
      },
      previousAddress2DeliveryMethod: {
        ...prev.previousAddress2DeliveryMethod,
        deliveryMethodId: previousAddress2DeliveryMethod,
      },
      previousAddress3DeliveryMethod: {
        ...prev.previousAddress3DeliveryMethod,
        deliveryMethodId: previousAddress3DeliveryMethod,
      },
      currentAddressSince: currentAddressSince,
      currentAddressSinceYear: currentAddressSinceYearValue,
      currentAddressSinceMonth: currentAddressSinceMonthValue,
      previousAddressSince1: previousAddressSince1,
      previousAddressSince1Year: previousAddressSince1YearValue,
      previousAddressSince1Month: previousAddressSince1MonthValue,
      previousAddressSince2: previousAddressSince2,
      previousAddressSince2Year: previousAddressSince2YearValue,
      previousAddressSince2Month: previousAddressSince2MonthValue,
      previousAddressSince3: previousAddressSince3,
      previousAddressSince3Year: previousAddressSince3YearValue,
      previousAddressSince3Month: previousAddressSince3MonthValue,
      pacNumber: pacNumber,
      msisdn: misdinNumber,
      stacNumber: stackNumber,
      flag: currentStep,
      createdBy:
        isLoggedIn == null && nameModified ? { id: null } : prev.createdBy,
      // login: isLogin,
    }));
  }, [
    currentStep,
    billCappingValue,
    alternativeFormatValue,
    mobileNumberSelectionValue,
    pacNumber,
    misdinNumber,
    stackNumber,
    affiliateValue,
    marketingOptValue,
    firstNameValue,
    lastNameValue,
    emailValue,
    contactNoValue,
    dobValue,
    residentialStatusValue,
    maritalValue,
    employmentStatusValue,
    gktNoValue,
    employeTime,
    bankAccountSince,
    bicValue,
    ibanValue,
    iccidValue,
    shippingPostCode,
    shippingAddValue,
    shippingAddressKey,
    p1ShippingPostCode,
    p1ShippingAddValue,
    p1ShippingAddressKey,
    p2ShippingPostCode,
    p2ShippingAddValue,
    p2ShippingAddressKey,
    p3ShippingPostCode,
    p3ShippingAddValue,
    p3ShippingAddressKey,
    billingPostCode,
    billingAddValue,
    billingAddressKey,
    billingAddressActive,
    billCappAmount,
    yearValue,
    monthValue,
    bankAccountSinceYearValue,
    bankAccountSinceMonthValue,
    deliveryMethod,
    previousAddress1DeliveryMethod,
    previousAddress2DeliveryMethod,
    previousAddress3DeliveryMethod,
    currentAddressSince,
    currentAddressSinceYearValue,
    currentAddressSinceMonthValue,
    previousAddressSince1,
    previousAddressSince1YearValue,
    previousAddressSince1MonthValue,
    previousAddressSince2,
    previousAddressSince2YearValue,
    previousAddressSince2MonthValue,
    previousAddressSince3,
    previousAddressSince3YearValue,
    previousAddressSince3MonthValue,
  ]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      billCappingValue: null,
    }));
  }, [billCappingValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      alternativeFormatValue: null,
    }));
  }, [alternativeFormatValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      mobileNumberSelectionValue: null,
    }));
  }, [mobileNumberSelectionValue]);
  useEffect(() => {
    setPacNoError(null);
  }, [pacNumber]);
  useEffect(() => {
    setMsisdnError(null);
    setMsisdnErrorForStac(null);
  }, [misdinNumber]);
  useEffect(() => {
    setStacNoError(null);
  }, [stackNumber]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      marketingOpt: null,
    }));
  }, [marketingOptValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      firstNameValue: null,
    }));
  }, [firstNameValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      lastNameValue: null,
    }));
  }, [lastNameValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      emailValue: null,
    }));
  }, [emailValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      contactNoValue: null,
    }));
  }, [contactNoValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      dobValue: null,
    }));
  }, [dobValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      residentialStatusValue: null,
    }));
  }, [residentialStatusValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      maritalValue: null,
    }));
  }, [maritalValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      employmentStatusValue: null,
    }));
  }, [employmentStatusValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      gktNoValue: null,
    }));
  }, [gktNoValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      employeTime: null,
    }));
  }, [employeTime]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      bankAccountSince: null,
    }));
  }, [bankAccountSince]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      bankAccountSince: null,
    }));
  }, [bankAccountSince]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      bicValue: null,
    }));
  }, [bicValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      ibanValue: null,
    }));
  }, [ibanValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      iccidValue: null,
    }));
  }, [iccidValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      shippingZipCode: null,
    }));
  }, [shippingZipCode]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      shippingAddValue: null,
    }));
  }, [shippingAddValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      p1ShippingZipCode: null,
    }));
  }, [p1ShippingZipCode]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      p1ShippingAddValue: null,
    }));
  }, [p1ShippingAddValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      p2ShippingZipCode: null,
    }));
  }, [p2ShippingZipCode]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      p2ShippingAddValue: null,
    }));
  }, [p2ShippingAddValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      p3ShippingZipCode: null,
    }));
  }, [p3ShippingZipCode]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      p3ShippingAddValue: null,
    }));
  }, [p3ShippingAddValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      billingZipCode: null,
    }));
  }, [billingZipCode]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      billingAddValue: null,
    }));
  }, [billingAddValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      yearValue: null,
    }));
  }, [yearValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      monthValue: null,
    }));
  }, [monthValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      bankAccountSinceYearValue: null,
    }));
  }, [bankAccountSinceYearValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      bankAccountSinceMonthValue: null,
    }));
  }, [bankAccountSinceMonthValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      deliveryMethod: null,
    }));
  }, [deliveryMethod]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddress1DeliveryMethod: null,
    }));
  }, [previousAddress1DeliveryMethod]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddress2DeliveryMethod: null,
    }));
  }, [previousAddress2DeliveryMethod]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddress3DeliveryMethod: null,
    }));
  }, [previousAddress3DeliveryMethod]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      currentAddressSinceYearValue: null,
    }));
  }, [currentAddressSinceYearValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      currentAddressSinceMonthValue: null,
    }));
  }, [currentAddressSinceMonthValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddressSince1YearValue: null,
    }));
  }, [previousAddressSince1YearValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddressSince1MonthValue: null,
    }));
  }, [previousAddressSince1MonthValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddressSince2YearValue: null,
    }));
  }, [previousAddressSince2YearValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddressSince2MonthValue: null,
    }));
  }, [previousAddressSince2MonthValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddressSince3YearValue: null,
    }));
  }, [previousAddressSince3YearValue]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      previousAddressSince3MonthValue: null,
    }));
  }, [previousAddressSince3MonthValue]);

  // useEffect(() => {
  //   function timeWithEmployer(monthValue, yearValue) {
  //     // Create a new date object from user input
  //     let userDate = new Date(yearValue, monthValue - 1, 1); // Months are 0-based in JavaScript

  //     // Format the userDate as yyyy-mm-dd
  //     let userFormattedDate = `${yearValue}-${String(monthValue).padStart(
  //       2,
  //       "0"
  //     )}-01`; // Day set to 01 for consistency

  //     // Current date
  //     let curDate = new Date();
  //     let formattedDate = `${curDate.getFullYear()}-${String(
  //       curDate.getMonth() + 1
  //     ).padStart(2, "0")}-${String(curDate.getDate()).padStart(2, "0")}`;

  //     // Calculate the difference in years, months, and days

  //     let diffYears = curDate.getFullYear() - yearValue;
  //     let diffMonths = curDate.getMonth() - monthValue;
  //     let diffDays = 1;

  //     // Format the difference into yyyy-mm-dd format
  //     let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
  //       2,
  //       "0"
  //     )}-${String(diffDays).padStart(2, "0")}`;
  //     setEmployeTime(diffFormattedDate);
  //   }

  //   timeWithEmployer(monthValue, yearValue);
  // }, [monthValue, yearValue]);
  //new date format
  useEffect(() => {
    function timeWithEmployer(monthValue, yearValue) {
      // Current date
      let curDate = new Date();

      // Subtract years and months from the current date
      curDate.setFullYear(curDate.getFullYear() - yearValue);
      curDate.setMonth(curDate.getMonth() - monthValue);

      // Format the resulting date as yyyy-mm-dd
      let formattedDate = `${curDate.getFullYear()}-${String(
        curDate.getMonth() + 1 // Months are zero-based
      ).padStart(2, "0")}-${String(curDate.getDate()).padStart(2, "0")}`;

      // Set the formatted date to the state
      setEmployeTime(formattedDate);
    }

    timeWithEmployer(monthValue, yearValue);
  }, [monthValue, yearValue]);

  useEffect(() => {
    if (openVerificationModal == true) {
      if (seconds > 0) {
        const interval = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [seconds, thirtySecTimer, openVerificationModal]);

  const formattedTime = `${Math.floor(seconds / 60)}:${
    seconds % 60 < 10 ? "0" : ""
  }${seconds % 60}`; // Added formatted time

  useEffect(() => {
    // Create a new date object from the user's input (year and month)
    let userDate = new Date(
      bankAccountSinceYearValue,
      bankAccountSinceMonthValue - 1,
      1
    ); // Months are 0-based in JavaScript

    // Current date
    let curDate = new Date();

    // Calculate the difference in years, months, and days
    let diffYears = curDate.getFullYear() - bankAccountSinceYearValue;
    let diffMonths = curDate.getMonth() - (bankAccountSinceMonthValue - 1);
    let diffDays = curDate.getDate() > 1 ? curDate.getDate() - 1 : 1; // Adjust by subtracting day 1 as base

    // Adjust if the current month is earlier in the year than the user's month
    if (diffMonths < 0) {
      diffYears--;
      diffMonths += 12;
    }

    // Adjust if the day calculation leads to a negative value
    if (diffDays < 0) {
      diffMonths--;
      let previousMonth = new Date(
        curDate.getFullYear(),
        curDate.getMonth(),
        0
      ); // Last day of the previous month
      diffDays += previousMonth.getDate();
    }

    // Format the difference into yyyy-mm-dd format
    let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
      2,
      "0"
    )}-${String(diffDays).padStart(2, "0")}`;

    // Set the calculated difference
    setBankAccountSince(diffFormattedDate);
  }, [bankAccountSinceMonthValue, bankAccountSinceYearValue]);

  // useEffect(() => {
  //   // Create a new date object from user input
  //   let userDate = new Date(
  //     currentAddressSinceYearValue,
  //     currentAddressSinceMonthValue - 1,
  //     1
  //   ); // Months are 0-based in JavaScript

  //   // Format the userDate as yyyy-mm-dd
  //   let userFormattedDate = `${currentAddressSinceYearValue}-${String(
  //     currentAddressSinceMonthValue
  //   ).padStart(2, "0")}-01`; // Day set to 01 for consistency

  //   // Current date
  //   let curDate = new Date();
  //   let formattedDate = `${curDate.getFullYear()}-${String(
  //     curDate.getMonth() + 1
  //   ).padStart(2, "0")}-${String(curDate.getDate()).padStart(2, "0")}`;

  //   // Calculate the difference in years, months, and days

  //   let diffYears = curDate.getFullYear() - currentAddressSinceYearValue;
  //   let diffMonths = curDate.getMonth() - currentAddressSinceMonthValue;
  //   let diffDays = 1;

  //   // Format the difference into yyyy-mm-dd format
  //   let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
  //     2,
  //     "0"
  //   )}-${String(diffDays).padStart(2, "0")}`;
  //   setCurrentAddressSince(diffFormattedDate);
  //   //bankAccountSinceFunction(currentAddressSinceMonthValue, currentAddressSinceYearValue);
  // }, [currentAddressSinceMonthValue, currentAddressSinceYearValue]);

  const updateAddressTotalDuration = () => {
    //console.log("currentAddressSinceYearValue:", currentAddressSinceYearValue);
    //console.log("addressTotalDurationStep:", addressTotalDurationStep);
    setAddressTotalDurationStep(1);
    if (currentAddressSinceYearValue && currentAddressSinceYearValue < 3) {
      //console.log("=====2");
      setAddressTotalDurationStep(2);
    }
    if (
      currentAddressSinceYearValue &&
      currentAddressSinceYearValue < 3 &&
      previousAddressSince1YearValue &&
      previousAddressSince1MonthValue
    ) {
      let totalYear =
        new Number(currentAddressSinceYearValue) +
        new Number(previousAddressSince1YearValue);
      let totalMonth =
        new Number(currentAddressSinceMonthValue) +
        new Number(previousAddressSince1MonthValue);
      //console.log("totalYear:", totalYear);
      //console.log("totalMonth:", totalMonth);
      if ((totalYear === 2 && totalMonth < 12) || totalYear < 3) {
        setAddressTotalDurationStep(3);
      }
    }
    if (
      currentAddressSinceYearValue &&
      currentAddressSinceYearValue < 3 &&
      previousAddressSince1YearValue &&
      previousAddressSince1MonthValue &&
      previousAddressSince2YearValue &&
      previousAddressSince2MonthValue
    ) {
      //console.log("=====4");
      let totalYear =
        new Number(currentAddressSinceYearValue) +
        new Number(previousAddressSince1YearValue) +
        new Number(previousAddressSince2YearValue);
      let totalMonth =
        new Number(currentAddressSinceMonthValue) +
        new Number(previousAddressSince1MonthValue) +
        new Number(previousAddressSince2MonthValue);
      //console.log("totalYear:", totalYear);
      //console.log("totalMonth:", totalMonth);
      if ((totalYear === 2 && totalMonth < 12) || totalYear < 3) {
        setAddressTotalDurationStep(4);
      }
    }
  };

  useEffect(() => {
    //set address duration value
    updateAddressTotalDuration();
    // Create a new date object from user input
    let userDate = new Date(
      currentAddressSinceYearValue,
      currentAddressSinceMonthValue - 1,
      1
    ); // Months are 0-based in JavaScript

    // Format the userDate as yyyy-mm-dd
    let userFormattedDate = `${currentAddressSinceYearValue}-${String(
      currentAddressSinceMonthValue
    ).padStart(2, "0")}-01`; // Day set to 01 for consistency

    // Current date
    let curDate = new Date();

    // Calculate the difference in years, months, and days
    let diffYears = curDate.getFullYear() - currentAddressSinceYearValue;
    let diffMonths = curDate.getMonth() - (currentAddressSinceMonthValue - 1);
    let diffDays = curDate.getDate() > 1 ? curDate.getDate() - 1 : 1; // Adjust by subtracting day 1 as base

    // Adjust if the current month is earlier in the year than the user's month
    if (diffMonths < 0) {
      diffYears--;
      diffMonths += 12;
    }

    // Adjust if the day calculation leads to a negative value
    if (diffDays < 0) {
      diffMonths--;
      let previousMonth = new Date(
        curDate.getFullYear(),
        curDate.getMonth(),
        0
      ); // Last day of the previous month
      diffDays += previousMonth.getDate();
    }

    // Format the difference into yyyy-mm-dd format
    let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
      2,
      "0"
    )}-${String(diffDays).padStart(2, "0")}`;

    // Set the calculated difference
    setCurrentAddressSince(diffFormattedDate);

    // Optionally call another function with the user inputs
    // bankAccountSinceFunction(currentAddressSinceMonthValue, currentAddressSinceYearValue);
  }, [currentAddressSinceMonthValue, currentAddressSinceYearValue]);

  useEffect(() => {
    if (
      previousAddressSince1YearValue &&
      previousAddressSince1MonthValue &&
      (previousAddressSince1YearValue > 0 ||
        previousAddressSince1MonthValue > 0)
    ) {
      //set address duration value
      updateAddressTotalDuration();
      // Create a new date object from user input
      let userDate = new Date(
        previousAddressSince1YearValue,
        previousAddressSince1MonthValue - 1,
        1
      ); // Months are 0-based in JavaScript

      // Format the userDate as yyyy-mm-dd
      let userFormattedDate = `${previousAddressSince1YearValue}-${String(
        previousAddressSince1MonthValue
      ).padStart(2, "0")}-01`; // Day set to 01 for consistency

      // Current date
      let curDate = currentAddressSince
        ? new Date(currentAddressSince)
        : new Date();
      //console.log("p1 curDate:" + curDate);

      // Calculate the difference in years, months, and days
      let diffYears = curDate.getFullYear() - previousAddressSince1YearValue;
      let diffMonths =
        curDate.getMonth() - (previousAddressSince1MonthValue - 1);
      let diffDays = curDate.getDate() > 1 ? curDate.getDate() - 1 : 1; // Adjust by subtracting day 1 as base

      // Adjust if the current month is earlier in the year than the user's month
      if (diffMonths < 0) {
        diffYears--;
        diffMonths += 12;
      }

      // Adjust if the day calculation leads to a negative value
      if (diffDays < 0) {
        diffMonths--;
        let previousMonth = new Date(
          curDate.getFullYear(),
          curDate.getMonth(),
          0
        ); // Last day of the previous month
        diffDays += previousMonth.getDate();
      }

      // Format the difference into yyyy-mm-dd format
      let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
        2,
        "0"
      )}-${String(diffDays).padStart(2, "0")}`;

      // Set the calculated difference
      setPreviousAddressSince1(diffFormattedDate);

      // Optionally call another function with the user inputs
      // bankAccountSinceFunction(previousAddressSince1MonthValue, previousAddressSince1YearValue);
    }
  }, [previousAddressSince1MonthValue, previousAddressSince1YearValue]);

  useEffect(() => {
    if (
      previousAddressSince2YearValue &&
      previousAddressSince2MonthValue &&
      (previousAddressSince2YearValue > 0 ||
        previousAddressSince2MonthValue > 0)
    ) {
      //set address duration value
      updateAddressTotalDuration();
      // Create a new date object from user input
      let userDate = new Date(
        previousAddressSince2YearValue,
        previousAddressSince2MonthValue - 1,
        1
      ); // Months are 0-based in JavaScript

      // Format the userDate as yyyy-mm-dd
      let userFormattedDate = `${previousAddressSince2YearValue}-${String(
        previousAddressSince2MonthValue
      ).padStart(2, "0")}-01`; // Day set to 01 for consistency

      // Current date
      let curDate = previousAddressSince1
        ? new Date(previousAddressSince1)
        : new Date();
      //console.log("p2 curDate:" + curDate);

      // Calculate the difference in years, months, and days
      let diffYears = curDate.getFullYear() - previousAddressSince2YearValue;
      let diffMonths =
        curDate.getMonth() - (previousAddressSince2MonthValue - 1);
      let diffDays = curDate.getDate() > 1 ? curDate.getDate() - 1 : 1; // Adjust by subtracting day 1 as base

      // Adjust if the current month is earlier in the year than the user's month
      if (diffMonths < 0) {
        diffYears--;
        diffMonths += 12;
      }

      // Adjust if the day calculation leads to a negative value
      if (diffDays < 0) {
        diffMonths--;
        let previousMonth = new Date(
          curDate.getFullYear(),
          curDate.getMonth(),
          0
        ); // Last day of the previous month
        diffDays += previousMonth.getDate();
      }

      // Format the difference into yyyy-mm-dd format
      let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
        2,
        "0"
      )}-${String(diffDays).padStart(2, "0")}`;

      // Set the calculated difference
      setPreviousAddressSince2(diffFormattedDate);

      // Optionally call another function with the user inputs
      // bankAccountSinceFunction(previousAddressSince2MonthValue, previousAddressSince2YearValue);
    }
  }, [previousAddressSince2MonthValue, previousAddressSince2YearValue]);

  useEffect(() => {
    if (
      previousAddressSince3YearValue &&
      previousAddressSince3MonthValue &&
      (previousAddressSince3YearValue > 0 ||
        previousAddressSince3MonthValue > 0)
    ) {
      // Create a new date object from user input
      let userDate = new Date(
        previousAddressSince3YearValue,
        previousAddressSince3MonthValue - 1,
        1
      ); // Months are 0-based in JavaScript

      // Format the userDate as yyyy-mm-dd
      let userFormattedDate = `${previousAddressSince3YearValue}-${String(
        previousAddressSince3MonthValue
      ).padStart(2, "0")}-01`; // Day set to 01 for consistency

      // Current date
      let curDate = previousAddressSince2
        ? new Date(previousAddressSince2)
        : new Date();
      //console.log("p3 curDate:" + curDate);

      // Calculate the difference in years, months, and days
      let diffYears = curDate.getFullYear() - previousAddressSince3YearValue;
      let diffMonths =
        curDate.getMonth() - (previousAddressSince3MonthValue - 1);
      let diffDays = curDate.getDate() > 1 ? curDate.getDate() - 1 : 1; // Adjust by subtracting day 1 as base

      // Adjust if the current month is earlier in the year than the user's month
      if (diffMonths < 0) {
        diffYears--;
        diffMonths += 12;
      }

      // Adjust if the day calculation leads to a negative value
      if (diffDays < 0) {
        diffMonths--;
        let previousMonth = new Date(
          curDate.getFullYear(),
          curDate.getMonth(),
          0
        ); // Last day of the previous month
        diffDays += previousMonth.getDate();
      }

      // Format the difference into yyyy-mm-dd format
      let diffFormattedDate = `${diffYears}-${String(diffMonths).padStart(
        2,
        "0"
      )}-${String(diffDays).padStart(2, "0")}`;

      // Set the calculated difference
      setPreviousAddressSince3(diffFormattedDate);

      // Optionally call another function with the user inputs
      // bankAccountSinceFunction(previousAddressSince3MonthValue, previousAddressSince3YearValue);
    }
  }, [previousAddressSince3MonthValue, previousAddressSince3YearValue]);

  // const CalendarComponent = () => {
  //   const [showDate, setDateShow] = useState(false);
  //   const handleDateChange = (selectedDate) => {
  //     setDobValue(selectedDate);

  //     //const dateObject = new Date(selectedDate);
  //     // Extract the date part in YYYY-MM-DD format
  //     //const formattedDate = dateObject.toISOString().split("T")[0];
  //     //setDobValue(formattedDate);
  //   };
  //   const handleDateClose = (state) => {
  //     setDateShow(state);
  //   };

  //   return (
  //     <Datepicker
  //       value={dobValue}
  //       options={dateOptions}
  //       onChange={handleDateChange}
  //       show={showDate}
  //       setShow={handleDateClose}
  //     />
  //   );
  // };

  // Step 1
  const handleBillCapping = async () => {
    setSubmitLoader(true);
    setErrors({});
    //validate
    if (billCappingValue && billCappingValue > 0) {
      //update credit check
      let hasError = false;

      if (billCappingValue === 1) {
        if (!billCappAmount) {
          setBillCappingError("Bill Capping Amount required");
          hasError = true;
          setSubmitLoader(false);
        } else if (!/^\d*\.?\d*$/.test(billCappAmount)) {
          setBillCappingError("Bill Capping Amount must be a number");
          hasError = true;
          setSubmitLoader(false);
        } else if (
          billCappAmount > 9999 ||
          billCappAmount < creditCheck.plan.finalPrice
        ) {
          setBillCappingError(
            "The minimum bill capping amount must be £ " +
              creditCheck.plan.finalPrice +
              "."
          );
          hasError = true;
          setSubmitLoader(false);
        } else {
          setBillCappingError(""); // Clear the error if the input is valid
        }
      }

      if (hasError) {
        setSubmitLoader(false);
        return;
      }
      try {
        const response = await axios.put(
          `${API_URL}api/v1/updateCreditCheck`,
          creditCheck
        );
        if (response.data.status == "success") {
          setSubmitLoader(false);

          localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
          // setCreditCheckId(response.data.id);
          setCurrentStep(currentStep + 1);
          setActiveSteps((prevActiveSteps) => [
            ...prevActiveSteps,
            currentStep + 1,
          ]);
          setSubmitLoader(false);
        }
      } catch (error) {
        setSubmitLoader(false);

        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrors((prev) => ({
                ...prev,
                stepOne:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                stepOne:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                stepOne:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 500:
              setErrors((prev) => ({
                ...prev,
                stepOne: "Server error occured. Please try again later.",
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                stepOne: "Unknown error occured. Please try again later.",
              }));
              break;
          }
        }
      }
    } else {
      setSubmitLoader(false);

      setErrors((prev) => ({
        ...prev,
        billCappingValue: "Please select bill capping",
      }));
    }
  };
  // step 2
  const handleAlternateFormat = async () => {
    setSubmitLoader(true);
    setErrors({});
    //validate
    if (alternativeFormatValue && alternativeFormatValue > 0) {
      //update credit check
      try {
        const response = await axios.put(
          `${API_URL}api/v1/updateCreditCheck`,
          creditCheck
        );
        if (response.data.status === "success") {
          setSubmitLoader(false);
          localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
          setCurrentStep(currentStep + 1);
          setActiveSteps((prevActiveSteps) => [
            ...prevActiveSteps,
            currentStep + 1,
          ]);
          setSubmitLoader(false);
        }
        // setData(response.data); // Uncomment if needed
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepTwo = "Network error occurred. Please try again later.";
          setLoader(false);
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrors((prev) => ({
                ...prev,
                stepTwo:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                stepTwo:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                stepTwo:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 500:
              setErrors((prev) => ({
                ...prev,
                stepTwo: "Server error occured. Please try again later.",
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                stepTwo: "Unknown error occured. Please try again later.",
              }));
              break;
          }
          // setLoader(false);
        }
      }
    } else {
      setSubmitLoader(false);
      setErrors((prev) => ({
        ...prev,
        alternativeFormatValue: "Please select alternative format",
      }));
      // setLoader(false);
    }
  };
  // step 3
  const handleMobileNumberSelectionAsync = async () => {
    console.log("pacNumber",pacNumber);
    console.log("msdinNumber",misdinNumber);

    setSubmitLoader(true);
    setErrors({});
    let pacStacError = "";
    //validate
    if (mobileNumberSelectionValue > 0) {
      if (mobileNumberSelectionValue === 2) {
        pacStacError = "PAC number is invalid.";
        // const pacPattern = /^[A-Za-z0-9]{1,9}$/; // Alphanumeric, 1 to 9 characters
        const pacPattern = /^[A-Z0-9]{1,9}$/;
        const msisdnPattern = /^[1-9]\d{9}$/;

        let hasError = false;

        if (!pacNumber) {
          setPacNoError("PAC No is required");
          hasError = true;
          setSubmitLoader(false);
        } else if (!pacPattern.test(pacNumber)) {
          setPacNoError("Invalid PAC No format");
          hasError = true;
          setSubmitLoader(false);
        } else {
          setPacNoError("");
        }

        if (!misdinNumber) {
          setMsisdnError("Mobile Number is required");
          hasError = true;
        } else if (!msisdnPattern.test(misdinNumber)) {
          setMsisdnError("Mobile Number should be a 10 digit number");
          hasError = true;
        } else {
          setMsisdnError("");
        }

        if (hasError) {
          setSubmitLoader(false);
          return;
        }
      }

      if (mobileNumberSelectionValue === 3) {
        pacStacError = "STAC number is invalid.";
        // const stacPattern = /^[A-Za-z0-9]{1,9}$/; // Alphanumeric, 1 to 9 characters
        const stacPattern = /^[A-Z0-9]{1,9}$/;
        const msisdnPattern = /^[1-9]\d{9}$/; // Numeric, 1 to 11 digits

        let hasError = false;

        if (!stackNumber) {
          setStacNoError("STAC No is required");
          hasError = true;
        } else if (!stacPattern.test(stackNumber)) {
          setStacNoError("Invalid STAC No format");
          hasError = true;
        } else {
          setStacNoError("");
        }

        if (!misdinNumber) {
          setMsisdnErrorForStac("Mobile Number is required");
          hasError = true;
        } else if (!msisdnPattern.test(misdinNumber)) {
          setMsisdnErrorForStac("Mobile Number should be a 10 digit number");
          hasError = true;
        } else {
          setMsisdnErrorForStac("");
        }

        if (hasError) {
          setSubmitLoader(false);
          return;
        }
      }
    console.log("creditCheck 3rd step",creditCheck);
      try {
        const response = await axios.put(
          `${API_URL}api/v1/updateCreditCheck`,
          creditCheck
        );
        if (response.data.status === "success") {
          localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
          setSubmitLoader(false);
          setCurrentStep(currentStep + 1);
          setActiveSteps((prevActiveSteps) => [
            ...prevActiveSteps,
            currentStep + 1,
          ]);
          setSubmitLoader(false);
        } else if (response.data.status === "Open") {
          setSubmitLoader(false);
          localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
          dispatch(openModal("portinsuccess"));
          setCurrentStep(currentStep + 1);
          setActiveSteps((prevActiveSteps) => [
            ...prevActiveSteps,
            currentStep + 1,
          ]);
          setSubmitLoader(false);
        } else if (response.data.status === "Error") {
          setSubmitLoader(false);
          localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
          dispatch(openModal("portinfail"));
        } else {
          setSubmitLoader(false);
          localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
          dispatch(openModal("portinfail"));
        }
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepThree = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrors((prev) => ({
                ...prev,
                stepThree:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                stepThree:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                stepThree:
                  "Failed to update Check Credit. Please try again later.",
              }));
              break;
            case 406:
              setErrors((prev) => ({
                ...prev,
                stepThree:
                  error.response.data.failedReason === "PAC or STAC is invalid."
                    ? pacStacError
                    : error.response.data.failedReason,
              }));
              break;
            case 500:
              setErrors((prev) => ({
                ...prev,
                stepThree: "Server error occured. Please try again later.",
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                stepThree: "Unknown error occured. Please try again later.",
              }));
              break;
          }
          setSubmitLoader(false);
        }
      }
    } else {
      setSubmitLoader(false);
      setErrors((prev) => ({
        ...prev,
        mobileNumberSelectionValue: "Please select mobile number format",
      }));
    }
  };
  // const loggedIn = localStorage.getItem("loggedIn");
  // useEffect(() => {
  //   // const loggedIn = localStorage.getItem("loggedIn");
  //   if (loggedIn === "true") {
  //     setIsLogin(true);
  //   } else if (
  //     loggedIn === null ||
  //     loggedIn === undefined ||
  //     loggedIn === "null"
  //   ) {
  //     setIsLogin(false);
  //   } else {
  //     setIsLogin(false);
  //   }
  // }, [loggedIn]);

  const emailConfirmationModal = async () => {
    setErrors({});

    // setSubmitLoader(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z]+$/;
    const contactNoRegex = /^[1-9]\d{9}$/;
    //console.log("marketingOptValue.length", marketingOptValue.length);
    const errors = {
      marketingOpt:
        marketingOptValue.length > 0
          ? null
          : "Please select atleast one marketing option.",
      firstNameValue: !firstNameValue ? "Please enter your first name." : "",
      lastNameValue: !lastNameValue ? "Please enter your last name." : "",
      emailValue: !emailValue ? "Please enter your email." : "",
      contactNoValue: !contactNoValue
        ? "Please enter your contact number."
        : "",
    };
    if (marketingOptValue?.length <= 0) {
      errors.marketingOpt = "Please select atleast one marketing option";
    }
    if (!firstNameValue) {
      errors.firstNameValue = "Please enter your first name";
    }
    if (!lastNameValue) {
      errors.lastNameValue = "Please enter your last name";
    }
    if (!emailValue) {
      errors.emailValue = "Please enter your email";
    }
    if (!contactNoValue) {
      errors.contactNoValue = "Please enter your contact number.";
    }

    if (emailValue && !emailRegex.test(emailValue)) {
      errors.emailValue = "Please enter a valid email.";
    }
    if (firstNameValue && !nameRegex.test(firstNameValue)) {
      errors.firstNameValue =
        "First name can only contain letters without space.";
    }
    if (lastNameValue && !nameRegex.test(lastNameValue)) {
      errors.lastNameValue =
        "Last name can only contain letters without space.";
    }
    if (contactNoValue && !contactNoRegex.test(contactNoValue)) {
      errors.contactNoValue =
        "Invalid contact number format. Valid formats is 3232523432";
    }

    if (Object.values(errors).some((error) => error)) {
      setErrors(errors);
      setSubmitLoader(false);
      return;
    }

    //show modal
    //on ok click close the modal, setSubmitLoader(false);, setLoader and call handlePersonalDeatils
    setSubmitLoader(false);
    dispatch(openModal("emailConfirm"));
  };

  //step 4
  const handlePersonalDeatils = async () => {
    // console.log("4th step call");
    setLoader(false);
    setErrors({});
    setSubmitLoader(true);
    // const originalFullName = localStorage.getItem("FirstLastName");
    // const [originalFirstName, originalLastName] = originalFullName?.split(" ") || ["", ""];
    // const nameModified = (firstNameValue !== originalFirstName) || (lastNameValue !== originalLastName);
    // console.log();
    console.log("Email Value:", emailValue);
    console.log("Credit Check Payload:", creditCheck);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z]+$/;
    // const contactNoRegex = /^(0\d{10}|[1-9]\d{9})$/;
    const contactNoRegex = /^[1-9]\d{9}$/;
    const errors = {
      // marketingOpt:
      //   marketingOptValue.length > 0
      //     ? null
      //     : "Please select atleast one marketing option.",
      firstNameValue: !firstNameValue ? "Please enter your first name." : "",
      lastNameValue: !lastNameValue ? "Please enter your last name." : "",
      emailValue: !emailValue ? "Please enter your email." : "",
      contactNoValue: !contactNoValue
        ? "Please enter your contact number."
        : "",
    };
    // if (marketingOptValue.length <= 0) {
    //   errors.marketingOpt = "Please select atleast one marketing option";
    // }
    if (!firstNameValue) {
      errors.firstNameValue = "Please enter your first name";
    }
    if (!lastNameValue) {
      errors.lastNameValue = "Please enter your last name";
    }
    if (!emailValue) {
      errors.emailValue = "Please enter your email";
    }
    if (!contactNoValue) {
      errors.contactNoValue = "Please enter your contact number.";
    }

    if (emailValue && !emailRegex.test(emailValue)) {
      errors.emailValue = "Please enter a valid email.";
    } else if (emailValue?.length > 60) {
      errors.emailValue = "Email cannot exceed 60 characters.";
    }
    if (firstNameValue && !nameRegex.test(firstNameValue)) {
      errors.firstNameValue =
        "First name can only contain letters without space.";
    } else if (firstNameValue?.length > 15) {
      errors.firstNameValue = "First Name cannot be longer than 15 characters";
    }
    if (lastNameValue && !nameRegex.test(lastNameValue)) {
      errors.lastNameValue =
        "Last name can only contain letters without space.";
    } else if (lastNameValue?.length > 30) {
      errors.lastNameValue = "Last Name cannot be longer than 30 characters";
    }
    if (contactNoValue && !contactNoRegex.test(contactNoValue)) {
      errors.contactNoValue =
        // "Invalid contact number format. Valid formats is 3232523432";
        "Invalid contact number format. The contact number should be exactly 10 digits";
    }

    if (Object.values(errors).some((error) => error)) {
      setErrors(errors);
      setSubmitLoader(false);
      // errors.emailValue = null;
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}api/v1/updateCreditCheck`,
        creditCheck
      );
      if (response.data.status === "newUserCreated") {
        localStorage.removeItem("FirstLastName");
        // let creditCheck = JSON.parse(localStorage.getItem("creditCheck"));

        // if (creditCheck) {
        //   // Remove the firstName and lastName properties
        //   delete creditCheck.firstName;
        //   delete creditCheck.lastName;

        //   localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
        // }

        setCreditCheck((prev) => ({
          ...prev,
          createdBy: { id: response.data.failedValue },
          customer: { id: response.data.failedValue },
        }));
        setSubmitLoader(false);
        dispatch(openModal("userVerification"));
        setOpenVerificationModal(true);
        setSeconds(30);
        let totalTime = 0;

        const interval = setInterval(async () => {
          // Call the API every 10 seconds
          try {
            // Call the API every 10 seconds
            const response1 =
              await PersonalInformationCreditCheckService.checkUserStatus(
                emailValue
              );
            if (response1.status === 200) {
              if (response1.data.status === "failed") {
                // Continue calling every 10 seconds for 30 seconds
                totalTime += 10;
                setThirtySecTimer(true);

                if (totalTime >= 30) {
                  clearInterval(interval);
                  setIsResetButton(true);
                }
              } else if (response1.data.status === "Success") {
                // // Redirect to checkout page
                dispatch(closeModal("userVerification"));
                setOpenVerificationModal(false);
                try {
                  const response2 = await axios.put(
                    `${API_URL}api/v1/updateCreditCheck`,
                    creditCheck
                  );

                  if (response2.data.status === "success") {
                    // setSubmitLoader(false);
                    localStorage.setItem(
                      "creditCheck",
                      JSON.stringify(creditCheck)
                    );
                    setErrors({});
                    setCurrentStep(currentStep + 1);
                    setActiveSteps((prevActiveSteps) => [
                      ...prevActiveSteps,
                      currentStep + 1,
                    ]);
                    // setSubmitLoader(false);
                    clearInterval(interval);
                    console.log("ggg");
                    const firstName = creditCheck.firstName || "";
                    const lastName = creditCheck.lastName || "";
                    const fullName = `${firstName} ${lastName}`.trim();
                    console.log("full name" + fullName);
                    localStorage.setItem("FirstLastName", fullName);
                  }
                } catch (error) {
                  setSubmitLoader(false);
                  if (error.message === "Network Error") {
                    setErrors((prev) => ({
                      ...prev,
                      stepFour:
                        "Network error occurred. Please try again later.",
                    }));
                  } else {
                    switch (error.response ? error.response.status : "") {
                      case 400:
                      case 403:
                      case 404:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Failed to update Check Credit. Please try again later.",
                        }));
                        break;
                      case 500:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Server error occurred. Please try again later.",
                        }));
                        break;
                      default:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Unknown error occurred. Please try again later.",
                        }));
                        break;
                    }
                  }
                }
              }
            }
          } catch (error) {
            setVerifyBtnDisabled(false);
            setSubmitLoader(false);
            dispatch(closeModal());

            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              // Handle other errors
              switch (error.response ? error.response.status : "") {
                case 401:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to check user verify. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }, 10000); // Repeat every 10 seconds
        setIntervalId(interval);
      } else if (response.data.status === "notVerified") {
        setCreditCheck((prev) => ({
          ...prev,
          createdBy: { id: response.data.failedValue },
          customer: { id: response.data.failedValue },
        }));
        setSubmitLoader(false);
        dispatch(openModal("userVerification"));
        setOpenVerificationModal(true);
        setSeconds(30);
        let totalTime = 0;

        const interval = setInterval(async () => {
          try {
            // Call the API every 10 seconds
            const response1 =
              await PersonalInformationCreditCheckService.checkUserStatus(
                emailValue
              );
            if (response1.status === 200) {
              if (response1.data.status === "failed") {
                // Continue calling every 10 seconds for 30 seconds
                totalTime += 10;
                setThirtySecTimer(true);

                if (totalTime >= 30) {
                  clearInterval(interval);
                  setIsResetButton(true);
                }
              } else if (response1.data.status === "Success") {
                dispatch(closeModal("userVerification"));
                setOpenVerificationModal(false);
                try {
                  const response2 = await axios.put(
                    `${API_URL}api/v1/updateCreditCheck`,
                    creditCheck
                  );
                  if (response2.data.status === "success") {
                    localStorage.setItem(
                      "creditCheck",
                      JSON.stringify(creditCheck)
                    );
                    setErrors({});
                    setCurrentStep(currentStep + 1);
                    setActiveSteps((prevActiveSteps) => [
                      ...prevActiveSteps,
                      currentStep + 1,
                    ]);
                    clearInterval(interval);
                  }
                } catch (error) {
                  setSubmitLoader(false);
                  if (error.message === "Network Error") {
                    setErrors((prev) => ({
                      ...prev,
                      stepFour:
                        "Network error occurred. Please try again later.",
                    }));
                  } else {
                    switch (error.response ? error.response.status : "") {
                      case 400:
                      case 403:
                      case 404:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Failed to update Check Credit. Please try again later.",
                        }));
                        break;
                      case 500:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Server error occurred. Please try again later.",
                        }));
                        break;
                      default:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Unknown error occurred. Please try again later.",
                        }));
                        break;
                    }
                  }
                }
              }
            }
          } catch (error) {
            setVerifyBtnDisabled(false);
            setSubmitLoader(false);
            dispatch(closeModal());
            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              // Handle other errors
              switch (error.response ? error.response.status : "") {
                case 401:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to check user verify. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }, 10000); // Repeat every 10 seconds
        setIntervalId(interval);
      } else if (response.data.status === "userUpdateRequested") {
        setCreditCheck((prev) => ({
          ...prev,
          customer: { id: response.data.failedValue },
        }));
        setSubmitLoader(false);
        dispatch(openModal("updateUserReq"));
        setOpenVerificationModal(true);
        setSeconds(30);
        let totalTime = 0;

        const interval = setInterval(async () => {
          try {
            // Call the API every 10 seconds
            const response1 =
              await PersonalInformationCreditCheckService.checkUpdateUserRequest(
                emailValue
              );
            if (response1.status === 200) {
              if (response1.data.status === "failed") {
                console.log("Failed");

                // Continue calling every 10 seconds for 30 seconds
                totalTime += 10;
                setThirtySecTimer(true);

                if (totalTime >= 30) {
                  clearInterval(interval);
                  setIsResetButton(true);
                }
              } else if (response1.data.status === "Success") {
                dispatch(closeModal("updateUserReq"));
                setOpenVerificationModal(false);
                try {
                  const updatedCreditCheck = {
                    ...creditCheck,
                    nameModified: false,
                  };
                  const response2 = await axios.put(
                    `${API_URL}api/v1/updateCreditCheck`,
                    updatedCreditCheck
                  );
                  if (response2.data.status === "success") {
                    localStorage.setItem(
                      "creditCheck",
                      JSON.stringify(updatedCreditCheck)
                    );
                    setErrors({});
                    setCurrentStep(currentStep + 1);
                    setActiveSteps((prevActiveSteps) => [
                      ...prevActiveSteps,
                      currentStep + 1,
                    ]);
                    clearInterval(interval);
                    const firstName = creditCheck.firstName || "";
                    const lastName = creditCheck.lastName || "";
                    const fullName = `${firstName} ${lastName}`.trim();
                    console.log("full name" + fullName);
                    localStorage.setItem("FirstLastName", fullName);
                  }
                } catch (error) {
                  setSubmitLoader(false);
                  if (error.message === "Network Error") {
                    setErrors((prev) => ({
                      ...prev,
                      stepFour:
                        "Network error occurred. Please try again later.",
                    }));
                  } else {
                    switch (error.response ? error.response.status : "") {
                      case 400:
                      case 403:
                      case 404:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Failed to update Check Credit. Please try again later.",
                        }));
                        break;
                      case 500:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Server error occurred. Please try again later.",
                        }));
                        break;
                      default:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Unknown error occurred. Please try again later.",
                        }));
                        break;
                    }
                  }
                }
              }
            }
          } catch (error) {
            setVerifyBtnDisabled(false);
            setSubmitLoader(false);
            dispatch(closeModal());
            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              // Handle other errors
              switch (error.response ? error.response.status : "") {
                case 401:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to check user verify. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }, 10000); // Repeat every 10 seconds
        setIntervalId(interval);
      } else if (
        response.data.status === "success" ||
        response.data.status === "newCustomerCreatedByDealer"
      ) {
        console.log("ddd");
        if (response.data.status === "newCustomerCreatedByDealer") {
          console.log("ddd1");
          setCreditCheck((prev) => ({
            ...prev,
            customer: { id: response.data.failedValue },
          }));
          toast.success("New customer is created for email ID " + emailValue, {
            style: {
              border: "1px solid #6ea8ad",
              backgroundColor: "#5a949a",
              color: "#f6f6f2",
            },
          });
        } else {
          console.log("ddd2");
          //console.log("Existing customer id:" + response.data.id);
          if (userRoleId === "ROLE_DEALER") {
            setCreditCheck((prev) => ({
              ...prev,
              customer: { id: response.data.id },
            }));
          } else {
            console.log("ddd3");
            const creditCheck = localStorage.getItem("creditCheck");
            // console.log("fullName",fullName.firstName);
            setCreditCheck((prev) => ({
              ...prev,
              createdBy: { id: response.data.id },
              customer: { id: response.data.id },
            }));
          }
        }
        console.log("ddd4");
        setSubmitLoader(false);
        localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
        setErrors({});
        setCurrentStep(currentStep + 1);
        setActiveSteps((prevActiveSteps) => [
          ...prevActiveSteps,
          currentStep + 1,
        ]);
        setSubmitLoader(false);
      }
    } catch (error) {
      setSubmitLoader(false);
      //console.log("error in dealer----", error);

      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          stepFour: "Network error occurred. Please try again later.",
        }));
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Please enter Customer Email Id.",
            }));
            break;
          case 403:
          case 404:
            setErrors((prev) => ({
              ...prev,
              stepFour:
                "Failed to update Check Credit. Please try again later.",
            }));
            break;
          case 406:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Invalid Referrel Code",
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Server error occurred. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
      }
    }
  };

  const handleResendVerificationLink = async () => {
    setResendBtnDisabled(true);
    try {
      const response = await axios.post(
        `${API_URL}authentication/resendVerificationLinkForCustomer/${emailValue}`
      );

      if (response.data.status === "Success") {
        setCustomerMessage("");
        setVerificationMessage(
          "Please check your email for the confirmation mail and click on the 'Verify' button to continue."
        );
        dispatch(openModal("userVerification"));
        setOpenVerificationModal(true);
        setSeconds(30);
        let totalTime = 0;
        const interval = setInterval(async () => {
          try {
            // Call the API every 10 seconds
            const response1 =
              await PersonalInformationCreditCheckService.checkUserStatus(
                emailValue
              );

            if (response1.status === 200) {
              if (response1.data.status === "failed") {
                // Continue calling every 10 seconds for 30 seconds
                totalTime += 10;
                setThirtySecTimer(true);

                if (totalTime >= 30) {
                  clearInterval(interval);
                  setIsResetButton(true);
                  setResendBtnDisabled(false);
                }
              } else if (response1.data.status === "Success") {
                dispatch(closeModal("userVerification"));
                setOpenVerificationModal(false);
                try {
                  const response2 = await axios.put(
                    `${API_URL}api/v1/updateCreditCheck`,
                    creditCheck
                  );

                  if (response2.data.status === "success") {
                    localStorage.setItem(
                      "creditCheck",
                      JSON.stringify(creditCheck)
                    );
                    setErrors({});
                    setCurrentStep(currentStep + 1);
                    setActiveSteps((prevActiveSteps) => [
                      ...prevActiveSteps,
                      currentStep + 1,
                    ]);
                    clearInterval(interval);
                  }
                } catch (error) {
                  setSubmitLoader(false);

                  if (error.message === "Network Error") {
                    setErrors((prev) => ({
                      ...prev,
                      stepFour:
                        "Network error occurred. Please try again later.",
                    }));
                  } else {
                    switch (error.response ? error.response.status : "") {
                      case 400:
                      case 403:
                      case 404:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Failed to update Check Credit. Please try again later.",
                        }));
                        break;
                      case 500:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Server error occurred. Please try again later.",
                        }));
                        break;
                      default:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Unknown error occurred. Please try again later.",
                        }));
                        break;
                    }
                  }
                }
              }
            }
          } catch (error) {
            setResendBtnDisabled(false);
            setVerifyBtnDisabled(false);
            setSubmitLoader(false);
            dispatch(closeModal("userVerification"));
            setOpenVerificationModal(false);

            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              switch (error.response ? error.response.status : "") {
                case 401:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to check user verification. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }, 10000); // Repeat every 10 seconds
        setIntervalId(interval);
      }
    } catch (error) {
      setResendBtnDisabled(false);

      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          stepFour: "Network error occurred. Please try again later.",
        }));
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
          case 403:
          case 404:
            setErrors((prev) => ({
              ...prev,
              stepFour:
                "Failed to send verification link. Please try again later.",
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Server error occurred. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
      }
    }
  };

  const handleManualCheckToVerify = async () => {
    setVerificationMessage("");
    setSubmitLoader(true);
    try {
      const response1 =
        await PersonalInformationCreditCheckService.checkUserStatus(emailValue);
      if (response1.status === 200) {
        if (response1.data.status === "failed") {
          setSubmitLoader(false);
          setCustomerMessage(
            "Customer is not verified yet. Please check email."
          );
        } else if (response1.data.status === "Success") {
          dispatch(closeModal("userVerification"));
          setOpenVerificationModal(false);

          try {
            const response = await axios.put(
              `${API_URL}api/v1/updateCreditCheck`,
              creditCheck
            );

            if (response.data.status === "success") {
              setSubmitLoader(false);
              localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
              setErrors({});
              setCurrentStep(currentStep + 1);
              setActiveSteps((prevActiveSteps) => [
                ...prevActiveSteps,
                currentStep + 1,
              ]);
            }
          } catch (error) {
            setSubmitLoader(false);
            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              switch (error.response ? error.response.status : "") {
                case 400:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to update Check Credit. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }
      }
    } catch (error) {
      setVerifyBtnDisabled(false);
      setSubmitLoader(false);
      dispatch(closeModal("userVerification"));
      setOpenVerificationModal(false);

      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          stepFour: "Network error occurred. Please try again later.",
        }));
      } else {
        switch (error.response ? error.response.status : "") {
          case 401:
          case 403:
          case 404:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Failed to check user verify. Please try again later.",
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Server error occurred. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
      }
    }
  };
  const handleManualCheckToVerifyUserUpdateReq = async () => {
    setVerificationMessage("");
    setSubmitLoader(true);
    try {
      const response1 =
        await PersonalInformationCreditCheckService.checkUpdateUserRequest(
          emailValue
        );
      if (response1.status === 200) {
        if (response1.data.status === "failed") {
          setSubmitLoader(false);
          setCustomerMessage(
            "Customer is not verified yet. Please check email."
          );
        } else if (response1.data.status === "Success") {
          dispatch(closeModal("updateUserReq"));
          setOpenVerificationModal(false);

          try {
            const updatedCreditCheck = {
              ...creditCheck,
              nameModified: false,
            };
            const response = await axios.put(
              `${API_URL}api/v1/updateCreditCheck`,
              updatedCreditCheck
            );

            if (response.data.status === "success") {
              setSubmitLoader(false);
              localStorage.setItem(
                "creditCheck",
                JSON.stringify(updatedCreditCheck)
              );
              setErrors({});
              setCurrentStep(currentStep + 1);
              setActiveSteps((prevActiveSteps) => [
                ...prevActiveSteps,
                currentStep + 1,
              ]);
            }
          } catch (error) {
            setSubmitLoader(false);
            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              switch (error.response ? error.response.status : "") {
                case 400:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to update Check Credit. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }
      }
    } catch (error) {
      setVerifyBtnDisabled(false);
      setSubmitLoader(false);
      dispatch(closeModal("userVerification"));
      setOpenVerificationModal(false);

      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          stepFour: "Network error occurred. Please try again later.",
        }));
      } else {
        switch (error.response ? error.response.status : "") {
          case 401:
          case 403:
          case 404:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Failed to check user verify. Please try again later.",
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Server error occurred. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
      }
    }
  };

  const handleResendVerificationLinkForUserUpdateReq = async () => {
    setResendBtnDisabled(true);
    try {
      const response = await axios.post(
        `${API_URL}authentication/resendUserUpdateReqLink/${emailValue}`
      );

      if (response.data.status === "Success") {
        setCustomerMessage("");
        setVerificationMessage(
          "Please check your email for the confirmation mail and click on the 'Verify' button to continue."
        );
        dispatch(openModal("updateUserReq"));
        setOpenVerificationModal(true);
        setSeconds(30);
        let totalTime = 0;
        const interval = setInterval(async () => {
          try {
            // Call the API every 10 seconds
            const response1 =
              await PersonalInformationCreditCheckService.checkUpdateUserRequest(
                emailValue
              );

            if (response1.status === 200) {
              if (response1.data.status === "failed") {
                // Continue calling every 10 seconds for 30 seconds
                totalTime += 10;
                setThirtySecTimer(true);

                if (totalTime >= 30) {
                  clearInterval(interval);
                  setIsResetButton(true);
                  setResendBtnDisabled(false);
                }
              } else if (response1.data.status === "Success") {
                dispatch(closeModal("updateUserReq"));
                setOpenVerificationModal(false);
                try {
                  const updatedCreditCheck = {
                    ...creditCheck,
                    nameModified: false,
                  };
                  const response2 = await axios.put(
                    `${API_URL}api/v1/updateCreditCheck`,
                    updatedCreditCheck
                  );

                  if (response2.data.status === "success") {
                    localStorage.setItem(
                      "creditCheck",
                      JSON.stringify(updatedCreditCheck)
                    );
                    setErrors({});
                    setCurrentStep(currentStep + 1);
                    setActiveSteps((prevActiveSteps) => [
                      ...prevActiveSteps,
                      currentStep + 1,
                    ]);
                    clearInterval(interval);
                  }
                } catch (error) {
                  setSubmitLoader(false);

                  if (error.message === "Network Error") {
                    setErrors((prev) => ({
                      ...prev,
                      stepFour:
                        "Network error occurred. Please try again later.",
                    }));
                  } else {
                    switch (error.response ? error.response.status : "") {
                      case 400:
                      case 403:
                      case 404:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Failed to update Check Credit. Please try again later.",
                        }));
                        break;
                      case 500:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Server error occurred. Please try again later.",
                        }));
                        break;
                      default:
                        setErrors((prev) => ({
                          ...prev,
                          stepFour:
                            "Unknown error occurred. Please try again later.",
                        }));
                        break;
                    }
                  }
                }
              }
            }
          } catch (error) {
            setResendBtnDisabled(false);
            setVerifyBtnDisabled(false);
            setSubmitLoader(false);
            dispatch(closeModal("userVerification"));
            setOpenVerificationModal(false);

            if (error.message === "Network Error") {
              setErrors((prev) => ({
                ...prev,
                stepFour: "Network error occurred. Please try again later.",
              }));
            } else {
              switch (error.response ? error.response.status : "") {
                case 401:
                case 403:
                case 404:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour:
                      "Failed to check user verification. Please try again later.",
                  }));
                  break;
                case 500:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Server error occurred. Please try again later.",
                  }));
                  break;
                default:
                  setErrors((prev) => ({
                    ...prev,
                    stepFour: "Unknown error occurred. Please try again later.",
                  }));
                  break;
              }
            }
          }
        }, 10000); // Repeat every 10 seconds
        setIntervalId(interval);
      }
    } catch (error) {
      setResendBtnDisabled(false);

      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          stepFour: "Network error occurred. Please try again later.",
        }));
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
          case 403:
          case 404:
            setErrors((prev) => ({
              ...prev,
              stepFour:
                "Failed to send verification link. Please try again later.",
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Server error occurred. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              stepFour: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
      }
    }
  };

  const [fandLNameError, setFandLNameError] = useState("");

  const handleFirstAndLastName = async (emailId) => {
    setSubmitLoader(true);
    setErrors({});
    try {
      const response = await axios.get(`${API_URL}api/v1/getUserByEmail`, {
        params: { emailId },
      });
      if (response.status === 200) {
        console.log("respoce", response.data);
        const fullName = response.data.message;
        localStorage.setItem("FirstLastName", fullName);

        const [firstName, lastName] = fullName.split(" ");
        setFirstNameValue(firstName || "");
        setLastNameValue(lastName || "");

        setSubmitLoader(false);
      } else {
        setFandLNameError("Failed to fetch data. Please try again later.");
      }
    } catch (error) {
      setSubmitLoader(false);
      if (error.message === "Network Error") {
        setFandLNameError("Network error occurred. Please try again later.");
        setLoader(false);
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
            setFandLNameError("Failed to fetch data. Please try again later.");
            break;
          case 403:
            setFandLNameError("Failed to fetch data. Please try again later.");
            break;
          case 404:
            setFandLNameError("Failed to fetch data. Please try again later.");
            break;
          case 500:
            setFandLNameError("Server error occured. Please try again later.");
            break;
          default:
            setFandLNameError("Unknown error occured. Please try again later.");
            break;
        }
        // setLoader(false);
      }
    }
  };

  //step 5
  const handleCreditCheck = async () => {
    setLoader(true);
    setErrors({});
    const tempError = {};

    if (
      !dobValue ||
      dobValue === undefined ||
      dobValue === null ||
      dobValue === "" ||
      dobValue.length <= 0
    ) {
      tempError.dobValue = "Please select your DOB";
    } else if (dateObject != null) {
      const currentDate = new Date();
      const diffInTime = currentDate.getTime() - dateObject.getTime();
      const age = diffInTime / (1000 * 3600 * 24 * 365.25);
      if (age < 18) {
        tempError.dobValue =
          "To purchase a Pay Monthly SIM, you must be at least 18 years old.";
      }
    }
    if (!monthValue) {
      tempError.monthValue = "Please enter the time with employer month.";
    } else if (monthValue < 0) {
      tempError.monthValue = "Please enter a month greater than 0.";
    } else if (monthValue > 11) {
      tempError.monthValue = "Please enter a month less than 12.";
    }
    if (!yearValue) {
      tempError.yearValue = "Please enter the time with employer year.";
    } else if (yearValue < 0) {
      tempError.yearValue = "Please enter a year greater than 0.";
    }
    if (monthValue == 0 && yearValue == 0) {
      tempError.monthValue = "Please enter a month";
      tempError.yearValue = "Please enter a year";
    }
    if (!bankAccountSinceMonthValue) {
      tempError.bankAccountSinceMonthValue =
        "Please enter the bank account since month";
    } else if (bankAccountSinceMonthValue < 0) {
      tempError.bankAccountSinceMonthValue =
        "Please enter a month greater than 0.";
    } else if (bankAccountSinceMonthValue > 11) {
      tempError.bankAccountSinceMonthValue =
        "Please enter a month less than 12.";
    }
    if (bankAccountSinceMonthValue == 0 && bankAccountSinceYearValue == 0) {
      tempError.bankAccountSinceMonthValue =
        "Please enter a month greater than 0.";
      tempError.bankAccountSinceYearValue =
        "Please enter a year greater than 0.";
    }
    if (!bankAccountSinceYearValue) {
      tempError.bankAccountSinceYearValue =
        "Please enter the bank account since year";
    } else if (bankAccountSinceYearValue < 0) {
      tempError.bankAccountSinceYearValue =
        "Please enter a year greater than 0.";
    }
    if (!residentialStatusValue) {
      tempError.residentialStatusValue =
        "Please select the Residential Status.";
    }
    if (!maritalValue) {
      tempError.maritalValue = "Please select the Marital Status.";
    }
    if (!employmentStatusValue) {
      tempError.employmentStatusValue = "Please select the Employment Status.";
    }
    if (!gktNoValue) {
      tempError.gktNoValue = "Please select the Three supplied number.";
    }
    //BIC Validation
    const bicPattern = /^\d{2}-\d{2}-\d{2}$/;
    if (!bicValue || (bicValue && bicValue.trim().length <= 0)) {
      tempError.bicValue = "Please enter a  Sort Code.";
    } else if (!bicPattern.test(bicValue)) {
      tempError.bicValue = "Sort Code must be in the format 00-00-00.";
    }

    if (!ibanValue || (ibanValue && ibanValue.trim().length <= 0)) {
      tempError.ibanValue = "Please enter a Account Number.";
    } else if (!/^\d{8}$/.test(ibanValue)) {
      tempError.ibanValue = "Account Number must be exactly 8 digits.";
    }

    // if (!iccidValue || (iccidValue && iccidValue <= 0)) {
    //   tempError.iccidValue = "Please enter a ICCID";
    // } else if (iccidValue.length !== 19) {
    //   tempError.iccidValue = "ICCID must be 19 digits.";
    // }

    if (Object.keys(tempError).length > 0) {
      setErrors((prev) => ({
        ...prev,
        ...tempError,
      }));
      setLoader(false);
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}api/v1/updateCreditCheck`,
        creditCheck
      );
      if (response.data.status === "success") {
        localStorage.setItem("creditCheck", JSON.stringify(creditCheck));
        setCurrentStep(currentStep + 1);
        setActiveSteps((prevActiveSteps) => [
          ...prevActiveSteps,
          currentStep + 1,
        ]);
        setLoader(false);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          stepFive: "Network error occurred. Please try again later.",
        }));
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
          case 403:
          case 404:
            setErrors((prev) => ({
              ...prev,
              stepFive:
                "Failed to update Check Credit. Please try again later.",
            }));
            break;
          case 406:
            setErrors((prev) => ({
              ...prev,
              stepFive: error.response.data.failedReason,
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              stepFive: "Server error occurred. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              stepFive: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
      }
      setLoader(false);
    }
  };

  const handleStepper = (type) => {
    if (type === "next") {
      if (currentStep > 0 && currentStep <= 6) {
        if (currentStep === 6 && isCreditFail === true) {
          dispatch(openModal("creditCheck"));
        } else if (currentStep == 6) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          toast.success("Proceed to checkout", {
            style: {
              border: "1px solid #6ea8ad",
              backgroundColor: "#5a949a",
              color: "#f6f6f2",
            },
          });
          setEnableCheckout(true);
        } else if (currentStep < 6) {
          // handleCheckout();
          switch (currentStep) {
            case 1:
              handleBillCapping();
              break;
            case 2:
              handleAlternateFormat();
              break;
            case 3:
              handleMobileNumberSelectionAsync();
              break;
            case 4:
              if (step4First === 0 && userRoleId === "ROLE_DEALER") {
                setStep4First(1);
                emailConfirmationModal();
              } else {
                handlePersonalDeatils();
              }
              break;
            case 5:
              handleCreditCheck();
              break;
            case 6:
              // handleSubmitButton();
              break;
          }
          // } else if (currentStep < 6) {
          // setCurrentStep(currentStep + 1);
          // setActiveSteps((prevActiveSteps) => [
          //   ...prevActiveSteps,
          //   currentStep + 1,
          // ]);
        }
      }
    } else if (type === "prev") {
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
        setActiveSteps((prevActiveSteps) => prevActiveSteps.slice(0, -1));
      }
    }
  };

  const handleMobileNumberSelection = (id) => {
    setMobileNumberSelection(id);
  };

  const handleTnCCheckbox = () => {
    setIsTNCChecked(!isTNCChecked);
  };

  const handleCheckout = () => {
    setEnableCheckout(true);
    toast.success("Proceed to checkout", {
      style: {
        border: "1px solid #6ea8ad",
        backgroundColor: "#5a949a",
        color: "#f6f6f2",
      },
    });
    if (checkoutFormRef.current) {
      checkoutFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // //console.log("credit check",creditCheck);
  const userId = localStorage.getItem("userId");
  const handleCheckoutButtonClick = (iccid) => {
    console.log("model open iccid");
    setResendBtnDisabled(true);
    setIccidValue(iccid);
    setLoader(true);
    setErrors({});
    const updatedCreditCheck = {
      ...creditCheck,
      iccid: iccid,
      createdBy: { id: userId },
    };
    setCreditCheck(updatedCreditCheck);
    const updatedOrder = {
      ...order,
      creditCheck: { ...updatedCreditCheck },
    };
    setOrder(updatedOrder);

    // check term and conditions
    //console.log("updatedOrder===<", updatedOrder);
    //call order api
    OrderService.createOrder(updatedOrder)
      .then((response) => {
        if (response.status == 200) {
          setResendBtnDisabled(false);

          //if successful then redirect to order confirmation page with order details
          let tempOrder = response.data;
          tempOrder.orderId = undefined;
          localStorage.setItem("orderConfirmation", JSON.stringify(tempOrder));
          Cookies.remove("accesstoken");
          navigate("/checkout");
          dispatch(closeModal());
          setLoader(false);
        }
      })
      .catch((error) => {
        setResendBtnDisabled(false);
        // dispatch(closeModal());
        if (error.message === "Network Error") {
          setErrors((prev) => ({
            ...prev,
            checkout: "Network error occurred. Please try again later.",
          }));
          setLoader(false);
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              if (error.response.data.status === "retry") {
                //retry button on popup when user click on then call createOrder api again with same object(here function call name as handleCheckoutButtonClick(iccid) )
                setErrors((prev) => ({
                  ...prev,
                  checkout: error.response.data.failedReason,
                }));
                dispatch(openModal("badReqCheckInCreateOrderAPI"));
                setSubmitLoader(false);
              } else if (error.response.data.status === "customMsg") {
                setErrors((prev) => ({
                  ...prev,
                  checkout: error.response.data.failedReason,
                }));
                dispatch(openModal("badReqCheckInCreateOrderAPICustomMsg"));
                setSubmitLoader(false);
              } else if (error.response.data.status === "invalidSim") {
                setErrors((prev) => ({
                  ...prev,
                  checkout: error.response.data.failedReason,
                }));
                dispatch(openModal("invalidSim"));
                setSubmitLoader(false);
              }
              break;
            case 401:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 500:
              setErrors((prev) => ({
                ...prev,
                checkout:
                  "Internal server error occurred. Please try again later.",
              }));
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 406:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 412:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                checkout: "Unknown error occurred. Please try again later.",
              }));
              break;
          }
          setLoader(false);
        }
        setShippingBtnDisable(false);
        setP1ShippingBtnDisable(false);
        setP2ShippingBtnDisable(false);
        setP3ShippingBtnDisable(false);
      });
  };

  const handleShippingZipCodeSearch = () => {
    setShippingAddValue(null);
    setShippingAddress([]);
    setErrors({});

    if (shippingZipCode && shippingZipCode.trim().length > 0) {
      const zipCodeRegex = /^[a-zA-Z0-9\s]{6,8}$/;

      if (!zipCodeRegex.test(shippingZipCode)) {
        setErrors((prev) => ({
          ...prev,
          shippingZipCode: "Invalid Post Code format",
        }));
        return; // Stop execution if validation fails
      }
      setShippingBtnDisable(true);
      // call Api here
      AddressService.addressApi(shippingZipCode)
        .then((response) => {
          if (response.status == 200) {
            setShippingAddress(response.data);
            setShippingBtnDisable(false);
            setDropdown(true);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setErrors((prev) => ({
              ...prev,
              shippingZipCode: "Please enter a valid post code",
            }));
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                break;
              case 403:
                break;
              case 500:
                break;
              case 404:
                break;
              case 406:
                break;
              case 412:
                break;
              default:
                break;
            }
            setErrors((prev) => ({
              ...prev,
              shippingZipCode: "Please enter a valid post code",
            }));
          }
          setShippingBtnDisable(false);
        });

      // setTimeout(() => {
      //   setShippingAddress(true);
      //   setShippingBtnDisable(false);
      // }, 2000);
    } else {
      setErrors((prev) => ({
        ...prev,
        shippingZipCode: "Please enter post code",
      }));
    }
  };

  useEffect(() => {
    if (shippingAddress && shippingAddValue) {
      let adress = shippingAddress.filter((addr) => {
        return addr.addressStr === shippingAddValue;
      });
      if (adress.length > 0) {
        setShippingPostCode(adress[0].postCode);
        setShippingAddressKey(adress[0].addressKey);
      }
    }
  }, [shippingAddValue]);

  //previous shipping address 1
  const handleP1ShippingZipCodeSearch = () => {
    setP1ShippingAddValue(null);
    setP1ShippingAddress([]);
    setErrors({});

    if (p1ShippingZipCode && p1ShippingZipCode.trim().length > 0) {
      setP1ShippingBtnDisable(true);
      // call Api here
      AddressService.addressApi(p1ShippingZipCode)
        .then((response) => {
          if (response.status == 200) {
            setP1ShippingAddress(response.data);
            setP1ShippingBtnDisable(false);
            setDropdown(true);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setErrors((prev) => ({
              ...prev,
              p1ShippingZipCode: "Please enter a valid post code",
            }));
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                break;
              case 403:
                break;
              case 500:
                break;
              case 404:
                break;
              case 406:
                break;
              case 412:
                break;
              default:
                break;
            }
            setErrors((prev) => ({
              ...prev,
              p1ShippingZipCode: "Please enter a valid post code",
            }));
          }
          setP1ShippingBtnDisable(false);
        });

      // setTimeout(() => {
      //   setShippingAddress(true);
      //   setShippingBtnDisable(false);
      // }, 2000);
    } else {
      setErrors((prev) => ({
        ...prev,
        p1ShippingZipCode: "Please enter post code",
      }));
    }
  };

  useEffect(() => {
    if (p1ShippingAddress && p1ShippingAddValue) {
      let adress = p1ShippingAddress.filter((addr) => {
        return addr.addressStr === p1ShippingAddValue;
      });
      if (adress.length > 0) {
        setP1ShippingPostCode(adress[0].postCode);
        setP1ShippingAddressKey(adress[0].addressKey);
      }
    }
  }, [p1ShippingAddValue]);

  //previous shipping address 2
  const handleP2ShippingZipCodeSearch = () => {
    setP2ShippingAddValue(null);
    setP2ShippingAddress([]);
    setErrors({});

    if (p2ShippingZipCode && p2ShippingZipCode.trim().length > 0) {
      setP2ShippingBtnDisable(true);
      // call Api here
      AddressService.addressApi(p2ShippingZipCode)
        .then((response) => {
          if (response.status == 200) {
            setP2ShippingAddress(response.data);
            setP2ShippingBtnDisable(false);
            setDropdown(true);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setErrors((prev) => ({
              ...prev,
              p2ShippingZipCode: "Please enter a valid post code",
            }));
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                break;
              case 403:
                break;
              case 500:
                break;
              case 404:
                break;
              case 406:
                break;
              case 412:
                break;
              default:
                break;
            }
            setErrors((prev) => ({
              ...prev,
              p2ShippingZipCode: "Please enter a valid post code",
            }));
          }
          setP2ShippingBtnDisable(false);
        });

      // setTimeout(() => {
      //   setShippingAddress(true);
      //   setShippingBtnDisable(false);
      // }, 2000);
    } else {
      setErrors((prev) => ({
        ...prev,
        p2ShippingZipCode: "Please enter post code",
      }));
    }
  };

  useEffect(() => {
    if (p2ShippingAddress && p2ShippingAddValue) {
      let adress = p2ShippingAddress.filter((addr) => {
        return addr.addressStr === p2ShippingAddValue;
      });
      if (adress.length > 0) {
        setP2ShippingPostCode(adress[0].postCode);
        setP2ShippingAddressKey(adress[0].addressKey);
      }
    }
  }, [p2ShippingAddValue]);

  //previous shipping address 3
  const handleP3ShippingZipCodeSearch = () => {
    setP3ShippingAddValue(null);
    setP3ShippingAddress([]);
    setErrors({});

    if (p3ShippingZipCode && p3ShippingZipCode.trim().length > 0) {
      setP3ShippingBtnDisable(true);
      // call Api here
      AddressService.addressApi(p3ShippingZipCode)
        .then((response) => {
          if (response.status == 200) {
            setP3ShippingAddress(response.data);
            setP3ShippingBtnDisable(false);
            setDropdown(true);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setErrors((prev) => ({
              ...prev,
              p3ShippingZipCode: "Please enter a valid post code",
            }));
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                break;
              case 403:
                break;
              case 500:
                break;
              case 404:
                break;
              case 406:
                break;
              case 412:
                break;
              default:
                break;
            }
            setErrors((prev) => ({
              ...prev,
              p3ShippingZipCode: "Please enter a valid post code",
            }));
          }
          setP3ShippingBtnDisable(false);
        });

      // setTimeout(() => {
      //   setShippingAddress(true);
      //   setShippingBtnDisable(false);
      // }, 2000);
    } else {
      setErrors((prev) => ({
        ...prev,
        p3ShippingZipCode: "Please enter post code",
      }));
    }
  };

  useEffect(() => {
    if (p3ShippingAddress && p3ShippingAddValue) {
      let adress = p3ShippingAddress.filter((addr) => {
        return addr.addressStr === p3ShippingAddValue;
      });
      if (adress.length > 0) {
        setP3ShippingPostCode(adress[0].postCode);
        setP3ShippingAddressKey(adress[0].addressKey);
      }
    }
  }, [p3ShippingAddValue]);

  const handleBillingZipCodeSearch = () => {
    setBillingAddValue(null);
    setBillingAddress([]);
    setErrors({});

    if (billingZipCode && billingZipCode.trim().length > 0) {
      const billingZipCodeRegex = /^[a-zA-Z0-9\s]{6,8}$/;

      if (!billingZipCodeRegex.test(billingZipCode)) {
        setErrors((prev) => ({
          ...prev,
          billingZipCode: "Invalid Post Code format",
        }));
        return; // Stop execution if validation fails
      }
      setBillingBtnDisable(true);
      AddressService.addressApi(billingZipCode)
        .then((response) => {
          if (response.status == 200) {
            setBillingAddress(response.data);
            setBillingBtnDisable(false);
            setDropdownShow(true);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setErrors((prev) => ({
              ...prev,
              billingZipCode: "Please enter a valid post code",
            }));
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                break;
              case 403:
                break;
              case 500:
                break;
              case 404:
                break;
              case 406:
                break;
              case 412:
                break;
              default:
                break;
            }
            setErrors((prev) => ({
              ...prev,
              billingZipCode: "Please enter a valid post code",
            }));
          }
          setBillingBtnDisable(false);
        });
      // setTimeout(() => {
      //   setBillingAddress(true);
      //   setBillingBtnDisable(false);
      // }, 2000);
    } else {
      setErrors((prev) => ({
        ...prev,
        billingZipCode: "Please enter post code",
      }));
    }
  };

  useEffect(() => {
    if (billingAddress && billingAddValue) {
      let adress = billingAddress.filter((addr) => {
        return addr.addressStr === billingAddValue;
      });
      if (adress.length > 0) {
        setBillingPostCode(adress[0].postCode);
        setBillingAddressKey(adress[0].addressKey);
      }
    }
  }, [billingAddValue]);

  useEffect(() => {
    if (!isCreditFail) {
      if (isTNCChecked === true && isContractRead === true) {
        setEnableCheckoutButton(true);
      } else {
        setEnableCheckoutButton(false);
      }
    } else {
      if (isTNCChecked === true) {
        setEnableCheckoutButton(true);
      } else {
        setEnableCheckoutButton(false);
      }
    }
  }, [isTNCChecked, isContractRead, buttonClicked, isCreditFail]);

  const handleBillingAddressChange = () => {
    setBillingAddressActive(!billingAddressActive);
    if (!billingAddressActive) {
      setBillingZipCode(null);
      setBillingPostCode(null);
      setBillingAddValue(null);
      setBillingAddressKey(null);
      setBillingAddress([]);
    }
  };

  const [submitAddress, setSubmitAddress] = useState({
    shippingAddress: {
      postCode: "",
      addressStr: "",
    },
    billingAddress: {
      postCode: "",
      addressStr: "",
    },
    sameAddress: false,
  });

  //address check
  const handleSubmitButtonCheck = () => {
    setLoader(true);
    setSubmitLoader(true);
    setErrors({});
    let tempError = {};

    //add validations
    if (
      shippingZipCode === "null" ||
      shippingZipCode === null ||
      shippingZipCode === undefined ||
      shippingZipCode.trim().length === 0
    ) {
      tempError.shippingZipCode = "Please search the Residential Address";
    }
    if (
      shippingAddValue === "null" ||
      shippingAddValue === null ||
      shippingAddValue === undefined ||
      shippingAddValue.trim().length === 0
    ) {
      tempError.shippingAddValue = "Please select Residential Address";
    }
    if (!deliveryMethod || deliveryMethod <= 0) {
      tempError.deliveryMethod = "Please select Address Type";
    }
    if (!currentAddressSinceMonthValue) {
      tempError.currentAddressSinceMonthValue =
        "Please enter the current address since month";
    } else if (
      currentAddressSinceYearValue &&
      currentAddressSinceYearValue <= 0 &&
      currentAddressSinceMonthValue <= 0
    ) {
      tempError.currentAddressSinceMonthValue =
        "Please enter a month greater than 0.";
    } else if (
      currentAddressSinceYearValue &&
      currentAddressSinceYearValue > 0 &&
      currentAddressSinceMonthValue < 0
    ) {
      tempError.currentAddressSinceMonthValue =
        "Please enter a month greater than or equal to 0.";
    } else if (currentAddressSinceMonthValue > 11) {
      tempError.currentAddressSinceMonthValue =
        "Please enter a month less than 12.";
    }
    if (!currentAddressSinceYearValue) {
      tempError.currentAddressSinceYearValue =
        "Please enter the current address since year";
    } else if (
      currentAddressSinceMonthValue &&
      currentAddressSinceMonthValue <= 0 &&
      currentAddressSinceYearValue <= 0
    ) {
      tempError.currentAddressSinceYearValue =
        "Please enter a year greater than 0.";
    } else if (
      currentAddressSinceMonthValue &&
      currentAddressSinceMonthValue > 0 &&
      currentAddressSinceYearValue < 0
    ) {
      tempError.currentAddressSinceYearValue =
        "Please enter a year greater than or equal to 0.";
    }
    if (
      currentAddressSinceMonthValue == 0 &&
      currentAddressSinceYearValue == 0
    ) {
      tempError.currentAddressSinceMonthValue = "Please enter a month";
      tempError.currentAddressSinceYearValue = "Please enter a year";
    }

    //previous address 1
    if (addressTotalDurationStep >= 2) {
      if (
        p1ShippingZipCode === "null" ||
        p1ShippingZipCode === null ||
        p1ShippingZipCode === undefined ||
        p1ShippingZipCode.trim().length === 0
      ) {
        tempError.p1ShippingZipCode = "Please search the Residential Address";
      }
      if (
        p1ShippingAddValue === "null" ||
        p1ShippingAddValue === null ||
        p1ShippingAddValue === undefined ||
        p1ShippingAddValue.trim().length === 0
      ) {
        tempError.p1ShippingAddValue = "Please select Residential Address";
      }
      if (
        p1ShippingAddValue !== "null" &&
        p1ShippingAddValue !== null &&
        p1ShippingAddValue !== undefined &&
        p1ShippingAddValue === shippingAddValue
      ) {
        tempError.p1ShippingAddValue =
          "Duplicate Address. Please select different address.";
      }
      if (
        !previousAddress1DeliveryMethod ||
        previousAddress1DeliveryMethod <= 0
      ) {
        tempError.previousAddress1DeliveryMethod = "Please select Address Type";
      }
      if (!previousAddressSince1MonthValue) {
        tempError.previousAddressSince1MonthValue =
          "Please enter the current address since month";
      } else if (
        previousAddressSince1YearValue &&
        previousAddressSince1YearValue <= 0 &&
        previousAddressSince1MonthValue <= 0
      ) {
        tempError.previousAddressSince1MonthValue =
          "Please enter a month greater than 0.";
      } else if (
        previousAddressSince1YearValue &&
        previousAddressSince1YearValue > 0 &&
        previousAddressSince1MonthValue < 0
      ) {
        tempError.previousAddressSince1MonthValue =
          "Please enter a month greater than or equal to 0.";
      } else if (previousAddressSince1MonthValue > 11) {
        tempError.previousAddressSince1MonthValue =
          "Please enter a month less than 12.";
      }
      if (!previousAddressSince1YearValue) {
        tempError.previousAddressSince1YearValue =
          "Please enter the current address since year";
      } else if (
        previousAddressSince1MonthValue &&
        previousAddressSince1MonthValue <= 0 &&
        previousAddressSince1YearValue <= 0
      ) {
        tempError.previousAddressSince1YearValue =
          "Please enter a year greater than 0.";
      } else if (
        previousAddressSince1MonthValue &&
        previousAddressSince1MonthValue > 0 &&
        previousAddressSince1YearValue < 0
      ) {
        tempError.previousAddressSince1YearValue =
          "Please enter a year greater than or equal to 0.";
      }
      if (
        previousAddressSince1MonthValue == 0 &&
        previousAddressSince1YearValue == 0
      ) {
        tempError.previousAddressSince1MonthValue = "Please enter a month";
        tempError.previousAddressSince1YearValue = "Please enter a year";
      }
    }

    //previous address 2
    if (addressTotalDurationStep >= 3) {
      if (
        p2ShippingZipCode === "null" ||
        p2ShippingZipCode === null ||
        p2ShippingZipCode === undefined ||
        p2ShippingZipCode.trim().length === 0
      ) {
        tempError.p2ShippingZipCode = "Please search the Residential Address";
      }
      if (
        p2ShippingAddValue === "null" ||
        p2ShippingAddValue === null ||
        p2ShippingAddValue === undefined ||
        p2ShippingAddValue.trim().length === 0
      ) {
        tempError.p2ShippingAddValue = "Please select Residential Address";
      }
      if (
        p2ShippingAddValue !== "null" &&
        p2ShippingAddValue !== null &&
        p2ShippingAddValue !== undefined &&
        (p2ShippingAddValue === shippingAddValue ||
          p2ShippingAddValue === p1ShippingAddValue)
      ) {
        tempError.p2ShippingAddValue =
          "Duplicate Address. Please select different address.";
      }
      if (
        !previousAddress2DeliveryMethod ||
        previousAddress2DeliveryMethod <= 0
      ) {
        tempError.previousAddress2DeliveryMethod = "Please select Address Type";
      }
      if (!previousAddressSince2MonthValue) {
        tempError.previousAddressSince2MonthValue =
          "Please enter the current address since month";
      } else if (
        previousAddressSince2YearValue &&
        previousAddressSince2YearValue <= 0 &&
        previousAddressSince2MonthValue <= 0
      ) {
        tempError.previousAddressSince2MonthValue =
          "Please enter a month greater than 0.";
      } else if (
        previousAddressSince2YearValue &&
        previousAddressSince2YearValue > 0 &&
        previousAddressSince2MonthValue < 0
      ) {
        tempError.previousAddressSince2MonthValue =
          "Please enter a month greater than or equal to 0.";
      } else if (previousAddressSince2MonthValue > 11) {
        tempError.previousAddressSince2MonthValue =
          "Please enter a month less than 12.";
      }
      if (!previousAddressSince2YearValue) {
        tempError.previousAddressSince2YearValue =
          "Please enter the current address since year";
      } else if (
        previousAddressSince2MonthValue &&
        previousAddressSince2MonthValue <= 0 &&
        previousAddressSince2YearValue <= 0
      ) {
        tempError.previousAddressSince2YearValue =
          "Please enter a year greater than 0.";
      } else if (
        previousAddressSince2MonthValue &&
        previousAddressSince2MonthValue > 0 &&
        previousAddressSince2YearValue < 0
      ) {
        tempError.previousAddressSince2YearValue =
          "Please enter a year greater than or equal to 0.";
      }
      if (
        previousAddressSince2MonthValue == 0 &&
        previousAddressSince2YearValue == 0
      ) {
        tempError.previousAddressSince2MonthValue = "Please enter a month";
        tempError.previousAddressSince2YearValue = "Please enter a year";
      }
    }

    //previous address 3
    if (addressTotalDurationStep >= 4) {
      if (
        p3ShippingZipCode === "null" ||
        p3ShippingZipCode === null ||
        p3ShippingZipCode === undefined ||
        p3ShippingZipCode.trim().length === 0
      ) {
        tempError.p3ShippingZipCode = "Please search the Residential Address";
      }
      if (
        p3ShippingAddValue === "null" ||
        p3ShippingAddValue === null ||
        p3ShippingAddValue === undefined ||
        p3ShippingAddValue.trim().length === 0
      ) {
        tempError.p3ShippingAddValue = "Please select Residential Address";
      }
      if (
        p3ShippingAddValue !== "null" &&
        p3ShippingAddValue !== null &&
        p3ShippingAddValue !== undefined &&
        (p3ShippingAddValue === shippingAddValue ||
          p3ShippingAddValue === p1ShippingAddValue ||
          p3ShippingAddValue === p2ShippingAddValue)
      ) {
        tempError.p3ShippingAddValue =
          "Duplicate Address. Please select different address.";
      }
      if (
        !previousAddress3DeliveryMethod ||
        previousAddress3DeliveryMethod <= 0
      ) {
        tempError.previousAddress3DeliveryMethod = "Please select Address Type";
      }
      if (!previousAddressSince3MonthValue) {
        tempError.previousAddressSince3MonthValue =
          "Please enter the current address since month";
      } else if (
        previousAddressSince3YearValue &&
        previousAddressSince3YearValue <= 0 &&
        previousAddressSince3MonthValue <= 0
      ) {
        tempError.previousAddressSince3MonthValue =
          "Please enter a month greater than 0.";
      } else if (
        previousAddressSince3YearValue &&
        previousAddressSince3YearValue > 0 &&
        previousAddressSince3MonthValue < 0
      ) {
        tempError.previousAddressSince3MonthValue =
          "Please enter a month greater than or equal to 0.";
      } else if (previousAddressSince3MonthValue > 11) {
        tempError.previousAddressSince3MonthValue =
          "Please enter a month less than 12.";
      }
      if (!previousAddressSince3YearValue) {
        tempError.previousAddressSince3YearValue =
          "Please enter the current address since year";
      } else if (
        previousAddressSince3MonthValue &&
        previousAddressSince3MonthValue <= 0 &&
        previousAddressSince3YearValue <= 0
      ) {
        tempError.previousAddressSince3YearValue =
          "Please enter a year greater than 0.";
      } else if (
        previousAddressSince3MonthValue &&
        previousAddressSince3MonthValue > 0 &&
        previousAddressSince3YearValue < 0
      ) {
        tempError.previousAddressSince3YearValue =
          "Please enter a year greater than or equal to 0.";
      }
      if (
        previousAddressSince3MonthValue == 0 &&
        previousAddressSince3YearValue == 0
      ) {
        tempError.previousAddressSince3MonthValue = "Please enter a month";
        tempError.previousAddressSince3YearValue = "Please enter a year";
      }
    }

    if (
      !billingAddressActive &&
      (billingAddValue === "null" ||
        billingAddValue === null ||
        billingAddValue === undefined ||
        billingAddValue.trim().length === 0)
    ) {
      tempError.billingAddValue = "Please select billing address";
    }
    if (
      !billingAddressActive &&
      (billingZipCode === "null" ||
        billingZipCode === null ||
        billingZipCode === undefined ||
        billingZipCode.trim().length === 0)
    ) {
      tempError.billingZipCode = "Please search the billing address";
    }

    //if validation failed then return
    if (Object.keys(tempError).length > 0) {
      setErrors((prev) => ({
        ...prev,
        ...tempError,
      }));
      setLoader(false);
      setSubmitLoader(false);
      return;
    }
    dispatch(openModal("creditVerification"));
    setLoader(false);
    setSubmitLoader(false);
  };

  //Insert the address
  const handleSubmitButton = () => {
    setCreditCheckLoader(true);
    setSubmitLoader(true);
    setErrors({});
    let tempError = {};

    //add validations
    if (
      shippingZipCode === "null" ||
      shippingZipCode === null ||
      shippingZipCode === undefined ||
      shippingZipCode.trim().length === 0
    ) {
      tempError.shippingZipCode = "Please search the Residential Address";
    }
    if (
      shippingAddValue === "null" ||
      shippingAddValue === null ||
      shippingAddValue === undefined ||
      shippingAddValue.trim().length === 0
    ) {
      tempError.shippingAddValue = "Please select Residential Address";
    }
    if (!deliveryMethod || deliveryMethod <= 0) {
      tempError.deliveryMethod = "Please select Address Type";
    }
    if (!currentAddressSinceMonthValue) {
      tempError.currentAddressSinceMonthValue =
        "Please enter the current address since month";
    } else if (
      currentAddressSinceYearValue &&
      currentAddressSinceYearValue <= 0 &&
      currentAddressSinceMonthValue <= 0
    ) {
      tempError.currentAddressSinceMonthValue =
        "Please enter a month greater than 0.";
    } else if (
      currentAddressSinceYearValue &&
      currentAddressSinceYearValue > 0 &&
      currentAddressSinceMonthValue < 0
    ) {
      tempError.currentAddressSinceMonthValue =
        "Please enter a month greater than or equal to 0.";
    } else if (currentAddressSinceMonthValue > 11) {
      tempError.currentAddressSinceMonthValue =
        "Please enter a month less than 12.";
    }
    if (!currentAddressSinceYearValue) {
      tempError.currentAddressSinceYearValue =
        "Please enter the current address since year";
    } else if (
      currentAddressSinceMonthValue &&
      currentAddressSinceMonthValue <= 0 &&
      currentAddressSinceYearValue <= 0
    ) {
      tempError.currentAddressSinceYearValue =
        "Please enter a year greater than 0.";
    } else if (
      currentAddressSinceMonthValue &&
      currentAddressSinceMonthValue > 0 &&
      currentAddressSinceYearValue < 0
    ) {
      tempError.currentAddressSinceYearValue =
        "Please enter a year greater than or equal to 0.";
    }
    if (
      currentAddressSinceMonthValue == 0 &&
      currentAddressSinceYearValue == 0
    ) {
      tempError.currentAddressSinceMonthValue = "Please enter a month";
      tempError.currentAddressSinceYearValue = "Please enter a year";
    }

    //previous address 1
    if (addressTotalDurationStep >= 2) {
      if (
        p1ShippingZipCode === "null" ||
        p1ShippingZipCode === null ||
        p1ShippingZipCode === undefined ||
        p1ShippingZipCode.trim().length === 0
      ) {
        tempError.p1ShippingZipCode = "Please search the Residential Address";
      }
      if (
        p1ShippingAddValue === "null" ||
        p1ShippingAddValue === null ||
        p1ShippingAddValue === undefined ||
        p1ShippingAddValue.trim().length === 0
      ) {
        tempError.p1ShippingAddValue = "Please select Residential Address";
      }
      if (
        p1ShippingAddValue !== "null" &&
        p1ShippingAddValue !== null &&
        p1ShippingAddValue !== undefined &&
        p1ShippingAddValue === shippingAddValue
      ) {
        tempError.p1ShippingAddValue =
          "Duplicate Address. Please select different address.";
      }
      if (
        !previousAddress1DeliveryMethod ||
        previousAddress1DeliveryMethod <= 0
      ) {
        tempError.previousAddress1DeliveryMethod = "Please select Address Type";
      }
      if (!previousAddressSince1MonthValue) {
        tempError.previousAddressSince1MonthValue =
          "Please enter the current address since month";
      } else if (
        previousAddressSince1YearValue &&
        previousAddressSince1YearValue <= 0 &&
        previousAddressSince1MonthValue <= 0
      ) {
        tempError.previousAddressSince1MonthValue =
          "Please enter a month greater than 0.";
      } else if (
        previousAddressSince1YearValue &&
        previousAddressSince1YearValue > 0 &&
        previousAddressSince1MonthValue < 0
      ) {
        tempError.previousAddressSince1MonthValue =
          "Please enter a month greater than or equal to 0.";
      } else if (previousAddressSince1MonthValue > 11) {
        tempError.previousAddressSince1MonthValue =
          "Please enter a month less than 12.";
      }
      if (!previousAddressSince1YearValue) {
        tempError.previousAddressSince1YearValue =
          "Please enter the current address since year";
      } else if (
        previousAddressSince1MonthValue &&
        previousAddressSince1MonthValue <= 0 &&
        previousAddressSince1YearValue <= 0
      ) {
        tempError.previousAddressSince1YearValue =
          "Please enter a year greater than 0.";
      } else if (
        previousAddressSince1MonthValue &&
        previousAddressSince1MonthValue > 0 &&
        previousAddressSince1YearValue < 0
      ) {
        tempError.previousAddressSince1YearValue =
          "Please enter a year greater than or equal to 0.";
      }
      if (
        previousAddressSince1MonthValue == 0 &&
        previousAddressSince1YearValue == 0
      ) {
        tempError.previousAddressSince1MonthValue = "Please enter a month";
        tempError.previousAddressSince1YearValue = "Please enter a year";
      }
    }

    //previous address 2
    if (addressTotalDurationStep >= 3) {
      if (
        p2ShippingZipCode === "null" ||
        p2ShippingZipCode === null ||
        p2ShippingZipCode === undefined ||
        p2ShippingZipCode.trim().length === 0
      ) {
        tempError.p2ShippingZipCode = "Please search the Residential Address";
      }
      if (
        p2ShippingAddValue === "null" ||
        p2ShippingAddValue === null ||
        p2ShippingAddValue === undefined ||
        p2ShippingAddValue.trim().length === 0
      ) {
        tempError.p2ShippingAddValue = "Please select Residential Address";
      }
      if (
        p2ShippingAddValue !== "null" &&
        p2ShippingAddValue !== null &&
        p2ShippingAddValue !== undefined &&
        (p2ShippingAddValue === shippingAddValue ||
          p2ShippingAddValue === p1ShippingAddValue)
      ) {
        tempError.p2ShippingAddValue =
          "Duplicate Address. Please select different address.";
      }
      if (
        !previousAddress2DeliveryMethod ||
        previousAddress2DeliveryMethod <= 0
      ) {
        tempError.previousAddress2DeliveryMethod = "Please select Address Type";
      }
      if (!previousAddressSince2MonthValue) {
        tempError.previousAddressSince2MonthValue =
          "Please enter the current address since month";
      } else if (
        previousAddressSince2YearValue &&
        previousAddressSince2YearValue <= 0 &&
        previousAddressSince2MonthValue <= 0
      ) {
        tempError.previousAddressSince2MonthValue =
          "Please enter a month greater than 0.";
      } else if (
        previousAddressSince2YearValue &&
        previousAddressSince2YearValue > 0 &&
        previousAddressSince2MonthValue < 0
      ) {
        tempError.previousAddressSince2MonthValue =
          "Please enter a month greater than or equal to 0.";
      } else if (previousAddressSince2MonthValue > 11) {
        tempError.previousAddressSince2MonthValue =
          "Please enter a month less than 12.";
      }
      if (!previousAddressSince2YearValue) {
        tempError.previousAddressSince2YearValue =
          "Please enter the current address since year";
      } else if (
        previousAddressSince2MonthValue &&
        previousAddressSince2MonthValue <= 0 &&
        previousAddressSince2YearValue <= 0
      ) {
        tempError.previousAddressSince2YearValue =
          "Please enter a year greater than 0.";
      } else if (
        previousAddressSince2MonthValue &&
        previousAddressSince2MonthValue > 0 &&
        previousAddressSince2YearValue < 0
      ) {
        tempError.previousAddressSince2YearValue =
          "Please enter a year greater than or equal to 0.";
      }
      if (
        previousAddressSince2MonthValue == 0 &&
        previousAddressSince2YearValue == 0
      ) {
        tempError.previousAddressSince2MonthValue = "Please enter a month";
        tempError.previousAddressSince2YearValue = "Please enter a year";
      }
    }

    //previous address 3
    if (addressTotalDurationStep >= 4) {
      if (
        p3ShippingZipCode === "null" ||
        p3ShippingZipCode === null ||
        p3ShippingZipCode === undefined ||
        p3ShippingZipCode.trim().length === 0
      ) {
        tempError.p3ShippingZipCode = "Please search the Residential Address";
      }
      if (
        p3ShippingAddValue === "null" ||
        p3ShippingAddValue === null ||
        p3ShippingAddValue === undefined ||
        p3ShippingAddValue.trim().length === 0
      ) {
        tempError.p3ShippingAddValue = "Please select Residential Address";
      }
      if (
        p3ShippingAddValue !== "null" &&
        p3ShippingAddValue !== null &&
        p3ShippingAddValue !== undefined &&
        (p3ShippingAddValue === shippingAddValue ||
          p3ShippingAddValue === p1ShippingAddValue ||
          p3ShippingAddValue === p2ShippingAddValue)
      ) {
        tempError.p3ShippingAddValue =
          "Duplicate Address. Please select different address.";
      }
      if (
        !previousAddress3DeliveryMethod ||
        previousAddress3DeliveryMethod <= 0
      ) {
        tempError.previousAddress3DeliveryMethod = "Please select Address Type";
      }
      if (!previousAddressSince3MonthValue) {
        tempError.previousAddressSince3MonthValue =
          "Please enter the current address since month";
      } else if (
        previousAddressSince3YearValue &&
        previousAddressSince3YearValue <= 0 &&
        previousAddressSince3MonthValue <= 0
      ) {
        tempError.previousAddressSince3MonthValue =
          "Please enter a month greater than 0.";
      } else if (
        previousAddressSince3YearValue &&
        previousAddressSince3YearValue > 0 &&
        previousAddressSince3MonthValue < 0
      ) {
        tempError.previousAddressSince3MonthValue =
          "Please enter a month greater than or equal to 0.";
      } else if (previousAddressSince3MonthValue > 11) {
        tempError.previousAddressSince3MonthValue =
          "Please enter a month less than 12.";
      }
      if (!previousAddressSince3YearValue) {
        tempError.previousAddressSince3YearValue =
          "Please enter the current address since year";
      } else if (
        previousAddressSince3MonthValue &&
        previousAddressSince3MonthValue <= 0 &&
        previousAddressSince3YearValue <= 0
      ) {
        tempError.previousAddressSince3YearValue =
          "Please enter a year greater than 0.";
      } else if (
        previousAddressSince3MonthValue &&
        previousAddressSince3MonthValue > 0 &&
        previousAddressSince3YearValue < 0
      ) {
        tempError.previousAddressSince3YearValue =
          "Please enter a year greater than or equal to 0.";
      }
      if (
        previousAddressSince3MonthValue == 0 &&
        previousAddressSince3YearValue == 0
      ) {
        tempError.previousAddressSince3MonthValue = "Please enter a month";
        tempError.previousAddressSince3YearValue = "Please enter a year";
      }
    }

    if (
      !billingAddressActive &&
      (billingAddValue === "null" ||
        billingAddValue === null ||
        billingAddValue === undefined ||
        billingAddValue.trim().length === 0)
    ) {
      tempError.billingAddValue = "Please select billing address";
    }
    if (
      !billingAddressActive &&
      (billingZipCode === "null" ||
        billingZipCode === null ||
        billingZipCode === undefined ||
        billingZipCode.trim().length === 0)
    ) {
      tempError.billingZipCode = "Please search the billing address";
    }

    //if validation failed then return
    if (Object.keys(tempError).length > 0) {
      setErrors((prev) => ({
        ...prev,
        ...tempError,
      }));
      setLoader(false);
      setSubmitLoader(false);
      return;
    }
    //console.log("===========creditCheck:", creditCheck);
    let finalCreditCheck = {};
    if (addressTotalDurationStep == 3) {
      finalCreditCheck = { ...creditCheck, previousShippingAddress3: null };
    } else if (addressTotalDurationStep == 2) {
      finalCreditCheck = {
        ...creditCheck,
        previousShippingAddress2: null,
        previousShippingAddress3: null,
      };
    } else if (addressTotalDurationStep == 1) {
      finalCreditCheck = {
        ...creditCheck,
        previousShippingAddress1: null,
        previousShippingAddress2: null,
        previousShippingAddress3: null,
      };
    } else {
      finalCreditCheck = { ...creditCheck };
    }
    //console.log("===========finalCreditCheck:", finalCreditCheck);
    //return;

    AddressService.addressSubmit(finalCreditCheck)
      .then((response) => {
        setCreditCheckLoader(false);
        setSubmitLoader(false);
        if (response.data.status == "success") {
          localStorage.setItem("creditCheck", JSON.stringify(finalCreditCheck));
          setLoader(false);
          setIsCreditFail(false);
          //disable submit button after credit  check pass
          setSubmitButtonDisable(true);
          // setCurrentStep(currentStep + 1);
          // setActiveSteps((prevActiveSteps) => [
          //   ...prevActiveSteps,
          //   currentStep + 1,
          // ]);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          dispatch(openModal("proceedToCheckout"));
          // toast.success("Proceed to checkout", {
          //   style: {
          //     border: "1px solid #6ea8ad",
          //     backgroundColor: "#5a949a",
          //     color: "#f6f6f2",
          //   },
          // });
          setEnableCheckout(true);
          if (checkoutFormRef.current) {
            checkoutFormRef.current.scrollIntoView({ behavior: "smooth" });
          }
          //for testing declined case
          //dispatch(openModal("creditCheck"));
        }
      })
      .catch((error) => {
        setCreditCheckLoader(false);
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          setErrors((prev) => ({
            ...prev,
            finalSubmit: "Network error occurred. Please try again later.",
          }));
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setErrors((prev) => ({
                ...prev,
                finalSubmit: error.response.data.failedReason,
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                finalSubmit: error.response.data.failedReason,
              }));
              break;
            case 500:
              if (error.response.data.failedReason === "Credit Check failed") {
                localStorage.setItem(
                  "creditCheck",
                  JSON.stringify(creditCheck)
                );
                localStorage.setItem("order", JSON.stringify(order));
                dispatch(openModal("creditCheck"));
                setIsCreditFail(true);
              } else {
                setErrors((prev) => ({
                  ...prev,
                  finalSubmit:
                    "Internal server error occurred. Please try again later.",
                }));
              }
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                finalSubmit: error.response.data.failedReason,
              }));
              break;
            case 406:
              setErrors((prev) => ({
                ...prev,
                finalSubmit: error.response.data.failedReason,
              }));
              break;
            case 412:
              setErrors((prev) => ({
                ...prev,
                finalSubmit: error.response.data.failedReason,
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                finalSubmit: "Unknown error occurred. Please try again later.",
              }));
              break;
          }
        }
        setCreditCheckLoader(false);
        dispatch(closeModal());
      });
  };

  useEffect(() => {
    if (modalSliceState) {
      // setOpen(false);
      // localStorage.removeItem("kioskSelectedPlanToBuy");
      // setEmailError("");
      // setEmail();
      setSeconds(30); // Reset the countdown timer to 30 seconds
      clearInterval(intervalId); // Clear the interval for verification API calls
      // setCount(0); // Reset the count to 0
    }
  }, [modalSliceState]);

  //dropdown api call step 5
  const [maritalStatuses, setMaritalStatuses] = useState([]);
  const [residetialStatuses, setResidetialStatuses] = useState([]);
  const [employmentStatuses, setEmploymentStatuses] = useState([]);
  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  const [marketingOptInList, setMarketingOptInList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [mobileNoSelection, setMobileNoSelection] = useState([]);

  useEffect(() => {
    const fetchMaritalStatuses = async () => {
      try {
        const response = await axios.get(`${API_URL}api/v1/maritalStatus`);
        if (response.status === 200) {
          setMaritalStatuses(response.data);
        }
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 403:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 404:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 406:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 500:
              setErrorMsg("Server error occured. Please try again later.");
              break;
            default:
              setErrorMsg("Unknown error occured. Please try again later.");
              break;
          }
        }
      }
    };
    const fetchResidentialStatus = async () => {
      try {
        const response = await axios.get(`${API_URL}api/v1/residentialStatus`);
        if (response.status === 200) {
          setResidetialStatuses(response.data);
        }
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 403:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 404:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 406:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 500:
              setErrorMsg("Server error occured. Please try again later.");
              break;
            default:
              setErrorMsg("Unknown error occured. Please try again later.");
              break;
          }
        }
      }
    };
    const fetchEmploymentStatus = async () => {
      try {
        const response = await axios.get(`${API_URL}api/v1/employmentStatus`);
        if (response.status === 200) {
          setEmploymentStatuses(response.data);
        }
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 403:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 404:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 406:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 500:
              setErrorMsg("Server error occured. Please try again later.");
              break;
            default:
              setErrorMsg("Unknown error occured. Please try again later.");
              break;
          }
        }
      }
    };

    const fetchDeliveryStatus = async () => {
      try {
        const response = await axios.get(`${API_URL}api/v1/deliveryMethod`);
        if (response.status === 200) {
          setDeliveryStatuses(response.data);
        }
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 403:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 404:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 406:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 500:
              setErrorMsg("Server error occured. Please try again later.");
              break;
            default:
              setErrorMsg("Unknown error occured. Please try again later.");
              break;
          }
        }
      }
    };

    const fetchMarketingOptin = async () => {
      try {
        const response = await axios.get(`${API_URL}api/v1/marketingOptin`);
        if (response.status === 200) {
          setMarketingOptInList(response.data);
        }
      } catch (error) {
        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrorMsg(
                "Failed to fetch the marketing opt-in. Please try again later."
              );
              break;
            case 403:
              setErrorMsg(
                "Failed to fetch the marketing opt-in. Please try again later."
              );
              break;
            case 404:
              setErrorMsg(
                "Failed to fetch the marketing opt-in. Please try again later."
              );
              break;
            case 406:
              setErrorMsg(
                "Failed to fetch the marketing opt-in. Please try again later."
              );
              break;
            case 500:
              setErrorMsg("Server error occured. Please try again later.");
              break;
            default:
              setErrorMsg("Unknown error occured. Please try again later.");
              break;
          }
        }
      }
    };

    const fetchMobileNoSelection = async () => {
      try {
        const response = await axios.get(`${API_URL}api/v1/mobileNoSelection`);
        if (response.status === 200) {
          console.log("response==", response.data);
          setMobileNoSelection(response.data);
        }
      } catch (error) {
        setSubmitLoader(false);
        if (error.message === "Network Error") {
          errors.stepOne = "Network error occurred. Please try again later.";
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 403:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 404:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 406:
              setErrorMsg("Failed to fetch the value. Please try again later.");
              break;
            case 500:
              setErrorMsg("Server error occured. Please try again later.");
              break;
            default:
              setErrorMsg("Unknown error occured. Please try again later.");
              break;
          }
        }
      }
    };

    fetchMaritalStatuses();
    fetchResidentialStatus();
    fetchEmploymentStatus();
    fetchDeliveryStatus();
    fetchMarketingOptin();
    fetchMobileNoSelection();
  }, []);

  const handleApplyVoucherClick = () => {
    setErrors({});
    setLoader(true);
    //validation
    if (!(voucherCode != null && voucherCode.trim().length > 0)) {
      setErrors((prev) => ({
        ...prev,
        voucherCode: "Please enter a discount code",
      }));
      setLoader(false);
      return;
    }

    //call apply voucher api
    VoucherService.applyVoucher(
      voucherCode,
      creditCheck.createdBy.id,
      creditCheck.plan.finalPrice,
      creditCheck.plan.planId
    )
      .then((response) => {
        if (response.status == 200) {
          const tempVoucher = response.data;
          //if successful then set voucher object
          setOrder({
            subtotalAmount: creditCheck.plan.finalPrice,
            discountAmount: tempVoucher.applicableAmount,
            amount: creditCheck.plan.finalPrice - tempVoucher.applicableAmount,
            voucher: tempVoucher,
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setErrors((prev) => ({
            ...prev,
            voucherCode: "Network error occurred. Please try again later.",
          }));
          setLoader(false);
          return;
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setErrors((prev) => ({
                ...prev,
                voucherCode: error.response.data.failedReason,
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                voucherCode: error.response.data.failedReason,
              }));
              break;
            case 500:
              //console.log("Error: ", error.response.data.failedReason);
              setErrors((prev) => ({
                ...prev,
                voucherCode: error.response.data.failedReason,
              }));
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                voucherCode:
                  "Discount code apply failed. Please try again later.",
              }));
              break;
            case 406:
              setErrors((prev) => ({
                ...prev,
                voucherCode: error.response.data.failedReason,
              }));
              break;
            case 412:
              setErrors((prev) => ({
                ...prev,
                voucherCode: error.response.data.failedReason,
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                voucherCode: "Unknown error occurred. Please try again later.",
              }));
              break;
          }
          setLoader(false);
        }
      });
  };

  const handleRemoveVoucherClick = () => {
    setOrder({
      subtotalAmount: creditCheck.plan.finalPrice,
      discountAmount: 0,
      amount: creditCheck.plan.finalPrice,
      voucher: null,
    });
    setVoucherCode("");
  };

  const handleMarketingOptinChange = (e) => {
    //console.log("e.target.value", e.target.value);
    //console.log("e.target.checked", e.target.checked);
    if (e.target.checked) {
      setMarketingOptValue([
        ...marketingOptValue,
        { marketingOptinId: Number(e.target.value) },
      ]);
    } else {
      //console.log("else data",marketingOptValue.filter((item) => item.marketingOptinId !== Number(e.target.value)));

      setMarketingOptValue(
        marketingOptValue.filter(
          (item) => item.marketingOptinId !== Number(e.target.value)
        )
      );
    }
  };
  const [resendEmailMessage, setResendAckEmailMessage] = useState("");
  const [ackEmailMessage, setAckEmailMessage] = useState("");

  const handleResendAcknowledgeEmail = async () => {
    setResendBtnDisabled(true);
    try {
      const response = await axios.get(`${API_URL}api/v1/resendContractEmail`, {
        headers: {
          creditCheckId: creditCheck.creditCheckId,
        },
      });

      if (response.status === 200) {
        setResendBtnDisabled(false);
        setCustomerMessage("");
        setAckEmailMessage(
          "Acknowledge email sent successfully. Please check email."
        );
        dispatch(openModal("acknowledgeContract"));
        setOpenVerificationModal(true);
        setResendBtnDisabled(true);
        setSeconds(30);

        let totalTime = 0;
        const interval = setInterval(async () => {
          try {
            const response1 =
              await AcknowledgementService.checkAcknowledgeStatus(
                contractData.orderId
              );
            console.log("response1===>", response1);
            if (response1.status === 200) {
              if (response1.data.status === "failed") {
                totalTime += 10;
                setThirtySecTimer(true);

                if (totalTime >= 30) {
                  clearInterval(interval);
                  setIsResetButton(true);
                  setResendBtnDisabled(false);
                }
              } else if (response1.data.status === "success") {
                clearInterval(interval);
                dispatch(closeModal("acknowledgeContract"));
                setOpenVerificationModal(false);
                setResendBtnDisabled(false);
                if (userRoleId === "ROLE_DEALER") {
                  if (isCreditFail === true) {
                    setEnableCheckout(true);
                    handleCheckoutButtonClick(null);
                  } else {
                    setTimeout(() => {
                      dispatch(openModal("checkoutModel"));
                    }, [600]);
                  }
                } else {
                  setResendBtnDisabled(false);
                  handleCheckoutButtonClick(null);
                }
              }
            }
          } catch (error) {
            clearInterval(interval);
            setResendBtnDisabled(false);
            setVerifyBtnDisabled(false);
            setSubmitLoader(false);
            setOpenVerificationModal(false);

            if (error.message === "Network Error") {
              setAcknowledgmentError(
                "Network error occurred. Please try again later."
              );
            } else {
              const status = error.response ? error.response.status : "";
              switch (status) {
                case 400:
                case 403:
                case 404:
                  setAcknowledgmentError(
                    "Acknowledgment Failed. Please try again later."
                  );
                  break;
                case 406:
                  setCustomerMessage(
                    "Customer is not acknowledged yet. Please check email."
                  );
                  dispatch(openModal("acknowledgeContract"));
                  break;
                case 500:
                  setAcknowledgmentError(
                    "Server error occurred. Please try again later."
                  );
                  break;
                default:
                  setAcknowledgmentError(
                    "Unknown error occurred. Please try again later."
                  );
                  break;
              }
            }
          }
        }, 10000); // Repeat every 10 seconds

        setIntervalId(interval);
      }
    } catch (error) {
      setResendBtnDisabled(false);
      if (error.message === "Network Error") {
        setAckError("Network error occurred. Please try again later.");
      } else {
        const status = error.response ? error.response.status : "";
        switch (status) {
          case 400:
          case 403:
          case 404:
            setResendAckEmailMessage(
              "Failed to send acknowledgment email. Please try again later."
            );
            break;
          case 500:
            setResendAckEmailMessage(
              "Server error occurred. Please try again later."
            );
            break;
          default:
            setResendAckEmailMessage(
              "Unknown error occurred. Please try again later."
            );
            break;
        }
      }
    }
  };

  const [ackError, setAckError] = useState("");
  const [contractData, setContractData] = useState();

  //for Acknowledge Email send on Contract
  const handleAcknowledgeEmail = async () => {
    setResendBtnDisabled(true);
    // try {
    //   const response = await axios.get(
    //     `${API_URL}api/v1/sendContractAcknowledgeEmail`,
    //     {
    //       headers: {
    //         creditCheckId: creditCheck.creditCheckId,
    //       },
    //     }
    //   );
    const updatedCreditCheck = {
      ...creditCheck,
    };
    setCreditCheck(updatedCreditCheck);

    const updatedOrder = {
      ...order,
      creditCheck: { ...updatedCreditCheck },
    };
    setOrder(updatedOrder);

    try {
      const response = await axios.post(
        `${API_URL}api/v1/sendContractAcknowledgeEmail`,
        updatedOrder
      );
      if (response.status === 200) {
        ////console.log("setContractData", response.data);
        setContractData(response.data);
        setCheckContractEmailStatus(true);
        setResendBtnDisabled(false);
        // setAckEmailMessage(
        //   "Acknowledge email send successfully. Please check email."
        // );
      }
    } catch (error) {
      setResendBtnDisabled(false);
      if (error.message === "Network Error") {
        setAckError("Network error occurred. Please try again later.");
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
            setAckError(
              "Failed to send acknowledgment email. Please try again later."
            );
            break;
          case 403:
            setAckError(
              "Failed to send acknowledgment email. Please try again later."
            );
            break;
          case 404:
            setAckError(
              "Failed to send acknowledgment email. Please try again later."
            );
            break;
          case 500:
            setAckError("Server error occured. Please try again later.");
            break;
          default:
            setAckError("Unknown error occured. Please try again later.");
            break;
        }
      }
    }
  };

  //step 2 Default "NO" option select
  useEffect(() => {
    setAlternativeFormatValue(2); // Default to "No"
  }, []);

  useEffect(() => {
    let isMounted = true;

    const planDataString = localStorage.getItem("creditCheck");
    if (planDataString) {
      const planData = JSON.parse(planDataString);
      if (isMounted) {
        setCurrentPlanData(planData.plan);
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [acknowledgmentError, setAcknowledgmentError] = useState("");
  //on checkout ack check pass or fail
  const handleCheckoutContract = async () => {
    // setResendBtnDisabled(true);
    //api call ack
    axios
      .get(`${API_URL}api/v1/checkCustomerAcknowledgment`, {
        headers: {
          orderId: contractData.orderId,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // //console.log("res",response.data);
        if (response.data.status === "success") {
          setResendBtnDisabled(false);
          if (userRoleId === "ROLE_DEALER") {

            if (isCreditFail === true) {
              setEnableCheckout(true);
              handleCheckoutButtonClick(null);
            } else {
              dispatch(openModal("checkoutModel"));
            }
          } else {
            setResendBtnDisabled(false);
            handleCheckoutButtonClick(null);
          }
        } else if (response.data.status === "failed") {
          setAckEmailMessage("");
          setCustomerMessage(
            "Customer is not acknowledged yet. Please check email."
          );
          dispatch(openModal("acknowledgeContract"));
          //30 sec timer
          setOpenVerificationModal(true);
          setSeconds(30);
          let totalTime = 0;
          const interval = setInterval(async () => {
            try {
              const response1 =
                await AcknowledgementService.checkAcknowledgeStatus(
                  contractData.orderId
                );
              // console.log("response1===>", response1);
              if (response1.status === 200) {
                if (response1.data.status === "failed") {
                  totalTime += 10;
                  setThirtySecTimer(true);
                  if (totalTime >= 30) {
                    clearInterval(interval);
                    setIsResetButton(true);
                    setResendBtnDisabled(false);
                  }
                } else if (response1.data.status === "success") {
                  clearInterval(interval);
                  dispatch(closeModal("acknowledgeContract"));//timer
                  setOpenVerificationModal(false);
                  setResendBtnDisabled(false);
                  if (userRoleId === "ROLE_DEALER") {
                    if (isCreditFail === true) {
                      console.log("isCreditFail true");
                      setEnableCheckout(true);
                      handleCheckoutButtonClick(null);
                    } else {
                      setTimeout(()=>{
                        dispatch(openModal("checkoutModel"));
                      },[600])
                    }
                  } else {
                    setResendBtnDisabled(false);
                    handleCheckoutButtonClick(null);
                  }
                }
              }
            } catch (error) {
              clearInterval(interval);
              setResendBtnDisabled(false);
              setVerifyBtnDisabled(false);
              setSubmitLoader(false);
              setOpenVerificationModal(false);
              if (error.message === "Network Error") {
                setAcknowledgmentError(
                  "Network error occurred. Please try again later."
                );
              } else {
                const status = error.response ? error.response.status : "";
                switch (status) {
                  case 400:
                  case 403:
                  case 404:
                    setAcknowledgmentError(
                      "Acknowledgment Failed. Please try again later."
                    );
                    break;
                  case 406:
                    setCustomerMessage(
                      "Customer is not acknowledged yet. Please check email."
                    );
                    dispatch(openModal("acknowledgeContract"));
                    break;
                  case 500:
                    setAcknowledgmentError(
                      "Server error occurred. Please try again later."
                    );
                    break;
                  default:
                    setAcknowledgmentError(
                      "Unknown error occurred. Please try again later."
                    );
                    break;
                }
              }
            }
          }, 10000);
          setIntervalId(interval);
        }
      })
      .catch((error) => {
        setResendBtnDisabled(false);
        if (error.message === "Network Error") {
          setAcknowledgmentError(
            "Network error occurred. Please try again later."
          );
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setAcknowledgmentError(
                "Acknowledgment Failed. Please try again later."
              );
              break;
            case 403:
              setAcknowledgmentError(
                "Acknowledgment Failed. Please try again later."
              );
              break;
            case 404:
              setAcknowledgmentError(
                "Acknowledgment Failed. Please try again later."
              );
              break;
            case 406:
              // setCustomerMessage(
              //   "Customer is not acknowledged yet. Please check email."
              // );
              // dispatch(openModal("acknowledgeContract"));
              setAcknowledgmentError(
                "Acknowledgment Failed. Please try again later."
              );
              break;
            case 500:
              setAcknowledgmentError(
                "Server error occurred. Please try again later."
              );
              break;
            default:
              setAcknowledgmentError(
                "Unknown error occurred. Please try again later."
              );
              break;
          }
        }
      });
  };
  const handleManualCheckAcknowledge = async () => {
    try {
      const response = await axios.get(`${API_URL}api/v1/checkCustomerAcknowledgment`, {
        headers: {
          orderId: contractData.orderId,
          "Content-Type": "application/json",
        },
      });
  
      if (response.data.status === "success") {
        setResendBtnDisabled(false);
        if (userRoleId === "ROLE_DEALER") {
          if (isCreditFail === true) {
            setEnableCheckout(true);
            handleCheckoutButtonClick(null);
          } else {
            dispatch(openModal("checkoutModel"));
          }
        } else {
          handleCheckoutButtonClick(null);
        }
      } else if (response.data.status === "failed") {
        setAckEmailMessage("");
        setCustomerMessage("Customer is not acknowledged yet. Please check email.");
        dispatch(openModal("acknowledgeContract"));
      }
    } catch (error) {
      setResendBtnDisabled(false);
      if (error.message === "Network Error") {
        setAcknowledgmentError("Network error occurred. Please try again later.");
      } else if (error.response) {
        switch (error.response.status) {
          case 400:
          case 403:
          case 404:
          case 406:
            setAcknowledgmentError("Acknowledgment Failed. Please try again later.");
            break;
          case 500:
            setAcknowledgmentError("Server error occurred. Please try again later.");
            break;
          default:
            setAcknowledgmentError("Unknown error occurred. Please try again later.");
            break;
        }
      } else {
        setAcknowledgmentError("An error occurred. Please try again later.");
      }
    }
  };
  
  // //console.log("setSubmitButtonDisable",submitButtonDisable);
  return (
    <>
      <Navbar />
      <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
        <div className="flex flex-col lg:flex-row gap-4 pb-4">
          {/* ProductDetails info start */}

          <div className="lg:w-8/12 rounded-lg">
            {/* stepper */}
            <div className="" id="stepper">
              <ol className="stepper flex flex-wrap lg:flex-nowrap items-center w-full pb-5 text-sm font-medium text-center shadow-sm sm:text-base">
                {checkoutSteps.map((item, index) => (
                  <li
                    key={index}
                    className={`flex items-center text-md py-2 lg:py-0`}
                  >
                    <p
                      className={`text-lg md:text-md ${
                        activeSteps.includes(index + 1)
                          ? "active-step"
                          : "btn-disabled"
                      }`}
                    >
                      <span className="hidden md:inline-block mr-1">Step</span>
                      <span>{item}</span>
                    </p>
                    <RiArrowRightDoubleFill
                      className={`mx-4 ${
                        activeSteps.includes(index + 1)
                          ? "active-step"
                          : "btn-disabled"
                      }`}
                    />
                  </li>
                ))}
              </ol>
            </div>
            <form>
              <div className="space-y-12">
                <div className="pb-4">
                  {currentStep === 1 ? (
                    <fieldset className="border custom-border-50 rounded-lg p-4 mb-4">
                      <h4 className="text-xl font-semibold leading-8 ">
                        Bill Capping
                      </h4>
                      <p className="text-sm leading-5">
                        Set a cap on top of your plan to control how much you
                        spend on out of bundle services every month.
                      </p>
                      <div className="mt-6 space-y-6">
                        <div className="relative flex gap-x-3">
                          <div className="flex h-6 items-center">
                            <input
                              checked={billCappingValue === 2}
                              onChange={() => setBillCappingValue(2)}
                              id="billCapping"
                              name="billCapping"
                              type="radio"
                              className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                            />
                          </div>
                          <div className="text-sm leading-6">
                            <label htmlFor="comments" className="font-medium ">
                              No
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-col ">
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="billCapping"
                                checked={billCappingValue === 1}
                                onChange={() => setBillCappingValue(1)}
                                name="billCapping"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="candidates"
                                className="font-medium "
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          {billCappingValue === 1 && (
                            <div className="w-1/2 mt-2 ml-7">
                              <label
                                htmlFor="pac-number-1"
                                className="font-medium text-xs"
                              >
                                Bill Capping Amount*
                              </label>
                              <input
                                value={billCappAmount}
                                onChange={(e) => {
                                  setBillCappAmount(e.target.value.trim());
                                }}
                                id="pac-number-1"
                                type="text"
                                placeholder="Please enter your Bill Capping Amount"
                                className="block w-full mt-1 rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                              {billCappingError ? (
                                <p className="error-text">{billCappingError}</p>
                              ) : null}
                            </div>
                          )}
                        </div>
                        {errors?.billCappingValue && (
                          <p className="error-text">
                            {errors.billCappingValue}
                          </p>
                        )}
                        {errors?.stepOne && (
                          <p className="error-text">{errors.stepOne}</p>
                        )}
                      </div>
                    </fieldset>
                  ) : currentStep === 2 ? (
                    <>
                      <fieldset className="border custom-border-50 rounded-lg p-4 mb-4">
                        <h4 className="text-xl font-semibold leading-8 ">
                          Alternative Format
                        </h4>
                        <p className="text-sm leading-5">
                          Need information in an alternative format?
                        </p>
                        <p className="text-sm leading-5">
                          If you find reading difficult, your network can
                          provide the information you need in braille, audio
                          format, or large font text.
                        </p>
                        <div className="mt-6 space-y-6">
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                checked={alternativeFormatValue === 2}
                                onChange={() => setAlternativeFormatValue(2)}
                                id="alternateFormat"
                                name="alternateFormat"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium "
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {/* <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                checked={alternativeFormatValue === 1}
                                onChange={() => setAlternativeFormatValue(1)}
                                id="alternateFormat"
                                name="alternateFormat"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="candidates"
                                className="font-medium "
                              >
                                Yes
                              </label>
                            </div>
                          </div> */}
                          {errors?.alternativeFormatValue && (
                            <p className="error-text">
                              {errors.alternativeFormatValue}
                            </p>
                          )}
                          {errors?.stepTwo && (
                            <p className="error-text">{errors.stepTwo}</p>
                          )}
                        </div>
                      </fieldset>
                    </>
                  ) : currentStep === 3 ? (
                    <>
                      <fieldset className="hidden border custom-border-50 rounded-lg p-4 mb-4">
                        <h4 className="text-xl font-semibold leading-8 ">
                          Alternative Format
                        </h4>
                        <p className="text-sm leading-5">
                          Need information in an alternative format?
                        </p>
                        <p className="text-sm leading-5">
                          If you find reading difficult, your network can
                          provide the information you need in braille, audio
                          format, or large font text.
                        </p>
                        <div className="mt-6 space-y-6">
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                checked={alternativeFormatValue === 2}
                                onChange={() => setAlternativeFormatValue(2)}
                                id="alternateFormat"
                                name="alternateFormat"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium "
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                checked={alternativeFormatValue === 1}
                                onChange={() => setAlternativeFormatValue(1)}
                                id="alternateFormat"
                                name="alternateFormat"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="candidates"
                                className="font-medium "
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="border custom-border-50 rounded-lg p-4 mb-4">
                        <h4 className="text-xl font-semibold leading-8 ">
                          Mobile number selection
                        </h4>
                        <p className="text-sm leading-5">
                          Switch to <b>Three</b> or get a new number?
                        </p>
                        <p className="text-sm leading-5">
                          This can be done now, or after you receive your order.
                          What are PAC and STAC codes?
                        </p>
                        <div className="mt-6 space-y-6">
                          {mobileNoSelection.length > 0 ? (
                            mobileNoSelection.map((option) => (
                              <div
                                key={option.mobileNoSelectionId}
                                className="relative flex gap-x-3"
                              >
                                <div className="flex h-6 items-center">
                                  <input
                                    checked={
                                      mobileNumberSelectionValue ===
                                      option.mobileNoSelectionId
                                    }
                                    onChange={() => {
                                      handleMobileNumberSelection(
                                        option.mobileNoSelectionId
                                      );
                                      setMobileNumberSelectionValue(
                                        option.mobileNoSelectionId
                                      );
                                    }}
                                    id={`numberSelection-${option.mobileNoSelectionId}`}
                                    name="numberSelection"
                                    type="radio"
                                    className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                                  />
                                </div>
                                <div className="text-sm leading-6">
                                  <label
                                    htmlFor={`numberSelection-${option.mobileNoSelectionId}`}
                                    className="font-medium"
                                    dangerouslySetInnerHTML={{
                                      __html: option.mobileNoSelectionDesc,
                                    }}
                                  ></label>
                                  {mobileNumberSelectionValue ===
                                    option.mobileNoSelectionId && (
                                    <div className="flex flex-wrap md:flex-nowrap w-full gap-[10px] mt-1">
                                      {/* PAC Input */}
                                      {option.mobileNoSelectionId === 2 && (
                                        <>
                                          <div className="w-full">
                                            <label
                                              htmlFor="pac-number-1"
                                              className="font-medium text-xs"
                                            >
                                              PAC Number*
                                            </label>
                                            <input
                                              value={pacNumber}
                                              onChange={(e) => {
                                                setPacNumber(
                                                  e.target.value.trim()
                                                );
                                              }}
                                              id="pac-number-1"
                                              type="text"
                                              placeholder="Please enter your PAC number"
                                              className="block w-full mt-1 rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            />
                                            {pacNoError && (
                                              <p className="error-text">
                                                {pacNoError}
                                              </p>
                                            )}
                                          </div>
                                          <div className="w-full">
                                            <label
                                              htmlFor="MSISDN-number"
                                              className="font-medium text-xs"
                                            >
                                              Mobile Number*
                                            </label>
                                            <input
                                              value={misdinNumber}
                                              onChange={(e) => {
                                                setMisdinNumber(
                                                  e.target.value.trim()
                                                );
                                              }}
                                              id="MSISDN-number"
                                              type="number"
                                              placeholder="Enter the number you'd like to port."
                                              className="block w-full mt-1 rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            />
                                            {msisdnError && (
                                              <p className="error-text">
                                                {msisdnError}
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      )}
                                      {/* STAC Input */}
                                      {option.mobileNoSelectionId === 3 && (
                                        <>
                                          <div className="w-full">
                                            <label
                                              htmlFor="stac-number-1"
                                              className="font-medium text-xs"
                                            >
                                              STAC Number*
                                            </label>
                                            <input
                                              value={stackNumber}
                                              onChange={(e) => {
                                                setStacNumber(
                                                  e.target.value.trim()
                                                );
                                              }}
                                              id="stac-number-1"
                                              type="text"
                                              placeholder="Please enter your STAC number"
                                              className="block w-full mt-1 rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            />
                                            {stacNoError && (
                                              <p className="error-text">
                                                {stacNoError}
                                              </p>
                                            )}
                                          </div>
                                          <div className="w-full">
                                            <label
                                              htmlFor="MSISDN-number-2"
                                              className="font-medium text-xs"
                                            >
                                              Mobile Number*
                                            </label>
                                            <input
                                              value={misdinNumber}
                                              onChange={(e) => {
                                                setMisdinNumber(
                                                  e.target.value.trim()
                                                );
                                              }}
                                              id="MSISDN-number-1"
                                              type="number"
                                              placeholder="Enter the number you'd like to port."
                                              className="block w-full mt-1 rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            />
                                            {msisdnErrorForStac && (
                                              <p className="error-text">
                                                {msisdnErrorForStac}
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>Loading options...</p>
                          )}
                          {errors?.mobileNumberSelectionValue && (
                            <p className="error-text">
                              {errors.mobileNumberSelectionValue}
                            </p>
                          )}
                          {errors?.stepThree && (
                            <p className="error-text">{errors.stepThree}</p>
                          )}
                        </div>
                      </fieldset>
                    </>
                  ) : currentStep === 4 ? (
                    <>
                      <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
                        <h2 className="text-xl font-semibold leading-8">
                          {userRoleId === "ROLE_DEALER"
                            ? "Add Customer Details"
                            : "Personal Information"}
                          {/* Personal Information */}
                        </h2>
                        <div className="grid md:grid-cols-2 gap-4">
                          <div className="mt-4">
                            <label
                              htmlFor="Affiliate"
                              className="block text-sm font-medium leading-6 "
                            >
                              Affiliate ID / Unique Referral Code
                            </label>
                            <div className="mt-2">
                              <input
                                value={affiliateValue}
                                readOnly={isAffiliateValueReadonly}
                                onChange={(e) => {
                                  setAffiliateValue(e.target.value.trim());
                                }}
                                placeholder="Affiliate ID / Unique Referral Code"
                                type="text"
                                id="Affiliate"
                                className={
                                  isAffiliateValueReadonly === true
                                    ? "readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 gap-4">
                          {/* {isResetButton ?
                          <>
                          <div className="w-full flex gap-">
                          <LoaderButton
                            handleClick={handleResendVerificationLink}
                            title="Resend"
                            // disabled={resendBtnDisabled}
                            type="button"
                            style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                          />
                                <Button
                                    handleClick={handleManualCheckToVerify}
                                    title="Okay"
                                    type="button"
                                    style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent  hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
                                  />
                          </div>
                          </> : null} */}
                          <div className="mt-4">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 "
                            >
                              {userRoleId === "ROLE_DEALER"
                                ? "Customer Email Id*"
                                : "Email Id*"}
                            </label>
                            <div className="mt-2">
                              <input
                                value={emailValue}
                                readOnly={isEmailValueReadonly}
                                onChange={(e) => {
                                  setEmailValue(e.target.value);
                                }}
                                onBlur={() => {
                                  // Trim value only on blur and run the function if needed
                                  setEmailValue(emailValue.trim());
                                  handleFirstAndLastName(emailValue.trim());
                                }}
                                id="email"
                                type="email"
                                placeholder="Please enter your email address"
                                className={
                                  isEmailValueReadonly === true
                                    ? "readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                }
                              />
                            </div>
                            {errors?.emailValue && (
                              <p className="error-text">{errors.emailValue}</p>
                            )}
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="number"
                              className="block text-sm font-medium leading-6 "
                            >
                              {userRoleId === "ROLE_DEALER"
                                ? "Customer Contact Number*"
                                : "Contact Number*"}
                            </label>
                            <div className="mt-2">
                              <input
                                value={contactNoValue}
                                onChange={(e) => {
                                  setContactNoValue(e.target.value.trim());
                                }}
                                placeholder="Please enter your contact number"
                                id="number"
                                type="number"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                            {errors?.contactNoValue && (
                              <p className="error-text">
                                {errors.contactNoValue}
                              </p>
                            )}
                          </div>
                          <div className="mt-4">
                            <div className="flex-col-2">
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6"
                              >
                                {userRoleId === "ROLE_DEALER"
                                  ? "Customer First Name*"
                                  : "First Name*"}
                              </label>
                              <div className="mt-2">
                                <input
                                  value={firstNameValue}
                                  // value={localStorage.getItem("name")? localStorage.getItem("name").split(' ')[0] : firstNameValue}
                                  onChange={(e) => {
                                    setFirstNameValue(e.target.value.trim());
                                  }}
                                  type="text"
                                  id="first-name"
                                  placeholder="Please enter your first name"
                                  className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                />
                              </div>
                              {errors?.firstNameValue && (
                                <p className="error-text">
                                  {errors.firstNameValue}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="flex-col-2">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 "
                              >
                                {userRoleId === "ROLE_DEALER"
                                  ? "Customer Last Name*"
                                  : "Last Name*"}
                              </label>
                              <div className="mt-2">
                                <input
                                  value={lastNameValue}
                                  // value={localStorage.getItem("name")? localStorage.getItem("name").split(' ')[1] : lastNameValue}
                                  onChange={(e) => {
                                    setLastNameValue(e.target.value.trim());
                                  }}
                                  placeholder="Please enter your last name"
                                  type="text"
                                  id="last-name"
                                  className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                />
                              </div>
                              {errors?.lastNameValue && (
                                <p className="error-text">
                                  {errors.lastNameValue}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="border custom-border-50 rounded-lg p-4 mb-4">
                        <h4 className="text-xl font-semibold leading-8 ">
                          Marketing opt-in
                        </h4>
                        <p className="text-sm leading-5">
                          Are you happy for IOT Mobile to send you updates about
                          their latest special offers?
                        </p>
                        <div className="mt-6 space-y-6">
                          {marketingOptInList.map((item, idx) => {
                            var flag = false;
                            marketingOptValue.forEach((marOptIn) => {
                              if (
                                item.marketingOptinId ===
                                marOptIn.marketingOptinId
                              ) {
                                flag = true;
                              }
                            });
                            if (flag === true) {
                              return (
                                <div
                                  key={idx}
                                  className="relative flex gap-x-3"
                                >
                                  <div className="flex h-6 items-center">
                                    <input
                                      defaultChecked={true}
                                      type="checkbox"
                                      value={item.marketingOptinId}
                                      onChange={(e) =>
                                        handleMarketingOptinChange(e)
                                      }
                                      className="cursor-pointer h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                                    />
                                  </div>
                                  <div className="text-sm leading-6">
                                    <label
                                      htmlFor="marketingOpt"
                                      className="font-medium "
                                    >
                                      {item.marketingOptinDesc}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={idx}
                                  className="relative flex gap-x-3"
                                >
                                  <div className="flex h-6 items-center">
                                    <input
                                      defaultChecked={false}
                                      type="checkbox"
                                      value={item.marketingOptinId}
                                      onChange={(e) =>
                                        handleMarketingOptinChange(e)
                                      }
                                      className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                                    />
                                  </div>
                                  <div className="text-sm leading-6">
                                    <label
                                      htmlFor="marketingOpt"
                                      className="font-medium "
                                    >
                                      {item.marketingOptinDesc}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          {/* <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                checked={marketingOpt === 1}
                                onChange={(e) => {
                                  setMarketingOpt(1);
                                }}
                                id="marketingOpt"
                                name="marketingOpt"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="marketingOpt"
                                className="font-medium "
                              >
                                SMS
                              </label>
                            </div>
                          </div>
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                checked={marketingOpt === 2}
                                onChange={(e) => {
                                  setMarketingOpt(2);
                                }}
                                id="marketingOpt"
                                name="marketingOpt"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="marketingOpt"
                                className="font-medium "
                              >
                                Email
                              </label>
                            </div>
                          </div> */}
                          {/* {errors?.marketingOpt && (
                            <p className="error-text">{errors.marketingOpt}</p>
                          )} */}
                        </div>
                      </fieldset>
                    </>
                  ) : currentStep === 5 ? (
                    <>
                      <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
                        <h2 className="text-xl font-semibold leading-8">
                          Credit Check
                        </h2>
                        <p className="mb-4">
                          We need to know the following information to complete
                          your credit check
                        </p>

                        <div className="grid md:grid-cols-2 gap-4">
                          <div className="mt-4 relative">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium leading-6 "
                            >
                              Date of Birth*
                            </label>
                            <CalendarComponent
                              setDobValueRef={setDobValue}
                              defaultDateValue={dobValue}
                              setDateObject={setDateObject}
                            />
                            {errors?.dobValue && (
                              <p className="error-text">{errors.dobValue}</p>
                            )}
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="marital-status"
                              className="block text-sm font-medium leading-6 "
                            >
                              Marital Status*
                            </label>
                            <div className="mt-2">
                              <select
                                id="marital-status"
                                value={maritalValue || ""}
                                onChange={(e) =>
                                  setMaritalValue(e.target.value)
                                }
                                // autoComplete="country-name"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Select Marital Status
                                </option>
                                {/* <option value={1}>Married</option>
                                <option value={2}>Un-Married</option> */}
                                {maritalStatuses.map((status) => (
                                  <option
                                    key={status.maritalStatusId}
                                    value={status.maritalStatusId}
                                  >
                                    {status.maritalStatusDesc}
                                  </option>
                                ))}
                              </select>
                              {errorMsg && (
                                <p className="error-text">{errorMsg}</p>
                              )}

                              {errors.maritalValue && (
                                <p className="error-text">
                                  {errors.maritalValue}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="residential-status"
                              className="block text-sm font-medium leading-6 "
                            >
                              Residential Status*
                            </label>
                            <div className="mt-2">
                              <select
                                id="residential-status"
                                name="residential-status"
                                value={residentialStatusValue || ""}
                                onChange={(e) =>
                                  setResidentialStatus(e.target.value)
                                }
                                autoComplete="country-name"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Select Residential Status
                                </option>
                                {/* <option value={1}>
                                  Resident and Ordinarily Resident (ROR)
                                </option>
                                <option value={2}>
                                  Resident but Not Ordinarily Resident (RNOR)
                                </option>
                                <option value={3}>Non-Resident (NR)</option> */}
                                {residetialStatuses.map((status) => (
                                  <option
                                    key={status.residentialStatusId}
                                    value={status.residentialStatusId}
                                  >
                                    {status.residentialStatusDesc}
                                  </option>
                                ))}
                              </select>
                              {errorMsg && (
                                <p className="error-text">{errorMsg}</p>
                              )}

                              {errors.residentialStatusValue && (
                                <p className="error-text">
                                  {errors.residentialStatusValue}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 "
                            >
                              Employment Status*
                            </label>
                            <div className="mt-2">
                              <select
                                id="country"
                                name="country"
                                value={employmentStatusValue || ""}
                                onChange={(e) =>
                                  setEmploymentStatusValue(e.target.value)
                                }
                                autoComplete="country-name"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Select Employment Status
                                </option>
                                {/* <option value={1}>Worker</option>
                                <option value={2}>Employee</option>
                                <option value={3}>Self-Employed</option> */}
                                {employmentStatuses.map((status) => (
                                  <option
                                    key={status.employmentStatusId}
                                    value={status.employmentStatusId}
                                  >
                                    {status.employmentStatusDesc}
                                  </option>
                                ))}
                              </select>
                              {errorMsg && (
                                <p className="error-text">{errorMsg}</p>
                              )}

                              {errors.employmentStatusValue && (
                                <p className="error-text">
                                  {errors.employmentStatusValue}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="mt-4 flex flex-col">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 "
                            >
                              Time with employer?
                            </label>
                            <div className="flex items-center mt-2 gap-7">
                              <div className="flex items-center">
                                <div className="">
                                  <input
                                    value={yearValue}
                                    onChange={(e) =>
                                      setYearValue(e.target.value.trim())
                                    }
                                    className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    type="number"
                                    placeholder="0"
                                  />
                                </div>
                                <p className="ml-2">Years</p>
                              </div>
                              <div className="flex items-center">
                                <div className="">
                                  <input
                                    value={monthValue}
                                    onChange={(e) =>
                                      setMonthValue(e.target.value.trim())
                                    }
                                    className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    type="number"
                                    placeholder="0"
                                  />
                                </div>
                                <p className="ml-2">Months</p>
                              </div>
                            </div>
                            {errors?.yearValue && (
                              <p className="error-text">{errors.yearValue}</p>
                            )}
                            {/* {//console.log("errors time with employer=>", errors)} */}
                            {errors?.monthValue && (
                              <p className="error-text">{errors.monthValue}</p>
                            )}
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 "
                            >
                              Do you have GKT supplied number?
                            </label>
                            <div className="mt-2">
                              <select
                                id="country"
                                value={gktNoValue || ""}
                                onChange={(e) => setGktNoValue(e.target.value)}
                                name="country"
                                autoComplete="country-name"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Select GKT supplied number
                                </option>
                                <option value={1}>Yes</option>
                                <option value={2}>No</option>
                                <option value={3}>Not Sure</option>
                              </select>
                              {errors.gktNoValue && (
                                <p className="error-text">
                                  {errors.gktNoValue}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
                        <h2 className="text-xl font-semibold leading-8">
                          Banking Details
                        </h2>
                        <p className="mb-4">
                          Please enter your banking details
                        </p>

                        <div className="grid md:grid-cols-2 gap-4">
                          <div className="mt-4 relative">
                            <label
                              htmlFor="bic"
                              className="block text-sm font-medium leading-6 "
                            >
                              Sort Code*
                            </label>
                            <div className="mt-2">
                              <input
                                value={bicValue}
                                onChange={(e) => {
                                  let inputValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  if (inputValue.length > 6) {
                                    inputValue = inputValue.slice(0, 6);
                                  }
                                  if (inputValue.length > 2) {
                                    inputValue = inputValue
                                      .match(/.{1,2}/g)
                                      .join("-");
                                  }
                                  setBicValue(inputValue);
                                }}
                                type="text"
                                id="bic"
                                placeholder="Sort Code"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                            {errors?.bicValue && (
                              <p className="error-text">{errors.bicValue}</p>
                            )}
                          </div>
                          <div className="mt-4 relative">
                            <label
                              htmlFor="iban"
                              className="block text-sm font-medium leading-6 "
                            >
                              Account Number*
                            </label>
                            <div className="mt-2">
                              <input
                                value={ibanValue}
                                onChange={(e) =>
                                  setIbanValue(e.target.value.trim())
                                }
                                type="number"
                                id="iban"
                                max={99999999}
                                placeholder="Account Number"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                            {errors?.ibanValue && (
                              <p className="error-text">{errors.ibanValue}</p>
                            )}
                          </div>
                          <div className="mt-4 flex flex-col">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 "
                            >
                              Bank Account Since*
                            </label>
                            <div className="flex items-center mt-2 gap-7">
                              <div className="flex items-center">
                                <div className="">
                                  <input
                                    value={bankAccountSinceYearValue}
                                    onChange={(e) =>
                                      setBankAccountSinceYearValue(
                                        e.target.value.trim()
                                      )
                                    }
                                    className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    type="number"
                                    placeholder="0"
                                  />
                                </div>
                                <p className="ml-2">Years</p>
                              </div>
                              <div className="flex items-center">
                                <div className="">
                                  <input
                                    value={bankAccountSinceMonthValue}
                                    onChange={(e) =>
                                      setBankAccountSinceMonthValue(
                                        e.target.value.trim()
                                      )
                                    }
                                    className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    type="number"
                                    placeholder="0"
                                  />
                                </div>
                                <p className="ml-2">Months</p>
                              </div>
                            </div>
                            {errors?.bankAccountSinceYearValue && (
                              <p className="error-text">
                                {errors.bankAccountSinceYearValue}
                              </p>
                            )}
                            {/* {//console.log("errors bank=>", errors)} */}
                            {errors?.bankAccountSinceMonthValue && (
                              <p className="error-text">
                                {errors.bankAccountSinceMonthValue}
                              </p>
                            )}
                          </div>
                        </div>
                      </fieldset>
                      {/* 
                      <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
                        <h2 className="text-xl font-semibold leading-8">
                          Assigned SIM
                        </h2>
                        <div className="grid md:grid-cols-2 gap-4">
                          <div className="col-span-1/2 mt-4 relative">
                            <label
                              htmlFor="iccid"
                              className="block text-sm font-medium leading-6 "
                            >
                              ICCID Number*
                            </label>
                            <div className="mt-2">
                              <input
                                value={iccidValue}
                                onChange={(e) => setIccidValue(e.target.value)}
                                type="number"
                                id="iccid"
                                placeholder="Please enter ICCID number"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                            {errors?.iccidValue && (
                              <p className="error-text">
                                {errors.iccidValue}
                              </p>
                            )}
                          </div>
                        </div>
                      </fieldset> */}
                    </>
                  ) : currentStep === 6 ? (
                    <>
                      <fieldset className="w-full mb-4">
                        <div className="border custom-border-50 my-3 p-6 rounded">
                          <h2 className="text-xl font-semibold leading-8">
                            Residential Address
                          </h2>
                          <div className="grid grid-cols-6 gap-4">
                            <div className="lg:col-span-4 md:col-span-3 col-span-full mt-4 relative">
                              <label
                                htmlFor="shipping-zip-search"
                                className="block text-sm font-medium leading-6"
                              >
                                Search by Post Code
                              </label>
                              <div className="mt-2">
                                <input
                                  value={shippingZipCode}
                                  // onChange={(e) => {
                                  //    setZipCode(e.target.value);
                                  //  }}
                                  type="text"
                                  id="shipping-zip-search"
                                  onChange={(e) =>
                                    setShippingZipCode(e.target.value)
                                  }
                                  className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                />
                              </div>
                              {errors?.shippingZipCode && (
                                <p className="error-text">
                                  {errors.shippingZipCode}
                                </p>
                              )}
                            </div>
                            <div className="lg:col-span-2 md:col-span-3 col-span-full mt-4 relative">
                              <LoaderButton
                                type="button"
                                title="Search Address"
                                style="md:mt-[32px]"
                                handleClick={handleShippingZipCodeSearch}
                                disabled={shippingBtnDisable}
                              />
                            </div>
                            {shippingAddress && dropdown && (
                              <div className="col-span-full mt-4">
                                <label
                                  htmlFor="suggested-address"
                                  className="block text-sm font-medium leading-6"
                                >
                                  Suggested Addresses
                                </label>
                                <div className="mt-2">
                                  <CustomSelect
                                    value={shippingAddValue}
                                    options={shippingAddress.map(
                                      (e) => e.addressStr
                                    )}
                                    placeholder="Choose an address"
                                    onChange={setShippingAddValue}
                                    //onChange={(e) => handleShippingAddSelection(e.target.value)}
                                    icon={
                                      <MdLocationOn className="text-lg opacity-40 mr-1" />
                                    }
                                  />
                                </div>
                                {errors?.shippingAddValue && (
                                  <p className="error-text">
                                    {errors.shippingAddValue}
                                  </p>
                                )}
                              </div>
                            )}

                            {shippingAddValue && (
                              <div className="col-span-full mt-6 w-full">
                                <p className="font-semibold text-lg mb-2 border-b pb-3">
                                  {userRoleId === "ROLE_DEALER"
                                    ? "Residential /Current Address"
                                    : "Deliver to"}
                                </p>
                                <p className="capitalize text-lg">
                                  {shippingAddValue
                                    ?.split(",")
                                    .map((line, idx) => (
                                      <span
                                        key={idx}
                                        style={{ display: "block" }}
                                      >
                                        {line.trim()}
                                      </span>
                                    ))}
                                </p>
                              </div>
                            )}
                            {shippingAddValue && (
                              <>
                                <div className="mt-4 lg:col-span-3 col-span-full relative">
                                  <label
                                    htmlFor="postal-code"
                                    className="block text-sm font-medium leading-6 "
                                  >
                                    Post Code*
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      value={shippingPostCode}
                                      type="text"
                                      id="postal-code"
                                      autoComplete="address-level1"
                                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                    />
                                  </div>
                                </div>
                                <div className="mt-4 relative lg:col-span-3 col-span-full">
                                  <label
                                    // htmlFor="country"
                                    className="block text-sm font-medium leading-6"
                                  >
                                    Country*
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      value="United Kingdom"
                                      className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                    />
                                  </div>
                                </div>
                                <div className="mt-4 lg:col-span-3 col-span-full">
                                  <label
                                    htmlFor="delivery-method"
                                    className="block text-sm font-medium leading-6 "
                                  >
                                    Address Type*
                                  </label>
                                  <div className="mt-2">
                                    <select
                                      id="delivery-method"
                                      name="delivery-method"
                                      value={deliveryMethod || ""}
                                      onChange={(e) =>
                                        setDeliveryMethod(e.target.value)
                                      }
                                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    >
                                      <option value="" disabled>
                                        Select Address Type
                                      </option>
                                      {/* <option value={1}>Home</option>
                                    <option value={2}>Retail Store</option>
                                    <option value={3}>PUDO Store</option>
                                    <option value={4}>Work</option>
                                    <option value={5}>Other</option> */}
                                      {deliveryStatuses.map((status) => (
                                        <option
                                          key={status.deliveryMethodId}
                                          value={status.deliveryMethodId}
                                        >
                                          {status.deliveryMethodDesc}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {errorMsg && (
                                    <p className="error-text">{errorMsg}</p>
                                  )}

                                  {errors?.deliveryMethod && (
                                    <p className="error-text">
                                      {errors.deliveryMethod}
                                    </p>
                                  )}
                                </div>
                                <div className="mt-4 flex flex-col lg:col-span-3 col-span-full">
                                  <label
                                    htmlFor="current-address-since"
                                    className="block text-sm font-medium leading-6 "
                                  >
                                    Current/Residential Address Since?*
                                  </label>
                                  <div className="flex items-center mt-2 gap-7">
                                    <div className="flex items-center">
                                      <div className="">
                                        <input
                                          value={currentAddressSinceYearValue}
                                          onChange={(e) =>
                                            setCurrentAddressSinceYearValue(
                                              e.target.value.trim()
                                            )
                                          }
                                          className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                          type="number"
                                          placeholder="0"
                                        />
                                      </div>
                                      <p className="ml-2">Years</p>
                                    </div>
                                    <div className="flex items-center">
                                      <div className="">
                                        <input
                                          value={currentAddressSinceMonthValue}
                                          onChange={(e) =>
                                            setCurrentAddressSinceMonthValue(
                                              e.target.value.trim()
                                            )
                                          }
                                          className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                          type="number"
                                          placeholder="0"
                                        />
                                      </div>
                                      <p className="ml-2">Months</p>
                                    </div>
                                  </div>
                                  {errors?.currentAddressSinceYearValue && (
                                    <p className="error-text">
                                      {errors.currentAddressSinceYearValue}
                                    </p>
                                  )}
                                  {errors?.currentAddressSinceMonthValue && (
                                    <p className="error-text">
                                      {errors.currentAddressSinceMonthValue}
                                    </p>
                                  )}
                                </div>
                              </>
                            )}
                            {/* <div className="lg:col-span-3 col-span-full mt-4">
                            <label
                              htmlFor="house-name"
                              className="block text-sm font-medium leading-6"
                            >
                              House number / name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="house-name"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="col-span-full mt-4">
                            <label
                              htmlFor="address-line-1"
                              className="block text-sm font-medium leading-6"
                            >
                              Address line 1
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="address-line-1"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="col-span-full mt-4">
                            <label
                              htmlFor="address-line-2"
                              className="block text-sm font-medium leading-6"
                            >
                              Address line 2
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="street-address"
                                id="address-line-2"
                                autoComplete="street-address"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="col-span-full mt-4">
                            <label
                              htmlFor="address-line-3"
                              className="block text-sm font-medium leading-6"
                            >
                              Address line 3
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="address-line-3"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="lg:col-span-2 col-span-full mt-4">
                            <label
                              htmlFor="city"
                              className="block text-sm font-medium leading-6 "
                            >
                              City
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="city"
                                id="city"
                                autoComplete="address-level2"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div> */}
                            {/* <div className="lg:col-span-2 col-span-full mt-4">
                            <label
                              htmlFor="postal-code"
                              className="block text-sm font-medium leading-6 "
                            >
                              ZIP / Postal code
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                onChange={(e) => {
                                  setZipCodeForShipping(e.target.value);
                                }}
                                id="postal-code"
                                autoComplete="address-level1"
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="lg:col-span-2 col-span-full mt-4">
                            <label
                              htmlFor="address-code"
                              className="block text-sm font-medium leading-6 "
                            >
                              Address
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                onChange={(e) => {
                                  setAddressForShipping(e.target.value);
                                }}
                                id="address1"
                                autoComplete="address-level1"
                                // readOnly
                                // value={address ? address.addressStr : ''}
                                className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 h-32"
                              />
                            </div>
                          </div>
                          <div className="lg:col-span-2 col-span-full mt-4">
                            <label
                              // htmlFor="country"
                              className="block text-sm font-medium leading-6"
                            >
                              Country
                            </label>
                            <div className="mt-2">
                              <input
                                // id="country"
                                onChange={() => {}}
                                value="United Kingdom"
                                className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div> */}
                          </div>
                        </div>
                        {addressTotalDurationStep >= 2 && (
                          <div className="border custom-border-50 my-3 p-6 rounded">
                            <h2 className="text-xl font-semibold leading-8">
                              Previous Residential Address&nbsp;
                              <span className="error-text">
                                (Minimum 3-year history required)
                              </span>
                            </h2>
                            <div className="grid grid-cols-6 gap-4">
                              <div className="lg:col-span-4 md:col-span-3 col-span-full mt-4 relative">
                                <label
                                  htmlFor="shipping-zip-search"
                                  className="block text-sm font-medium leading-6"
                                >
                                  Search by Post Code
                                </label>
                                <div className="mt-2">
                                  <input
                                    value={p1ShippingZipCode}
                                    // onChange={(e) => {
                                    //    setZipCode(e.target.value);
                                    //  }}
                                    type="text"
                                    id="shipping-zip-search"
                                    onChange={(e) =>
                                      setP1ShippingZipCode(
                                        e.target.value.trim()
                                      )
                                    }
                                    className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {errors?.p1ShippingZipCode && (
                                  <p className="error-text">
                                    {errors.p1ShippingZipCode}
                                  </p>
                                )}
                              </div>
                              <div className="lg:col-span-2 md:col-span-3 col-span-full mt-4 relative">
                                <LoaderButton
                                  type="button"
                                  title="Search address"
                                  style="md:mt-[32px]"
                                  handleClick={handleP1ShippingZipCodeSearch}
                                  disabled={p1ShippingBtnDisable}
                                />
                              </div>
                              {p1ShippingAddress && dropdown && (
                                <div className="col-span-full mt-4">
                                  <label
                                    htmlFor="suggested-address"
                                    className="block text-sm font-medium leading-6"
                                  >
                                    Suggested Addresses
                                  </label>
                                  <div className="mt-2">
                                    <CustomSelect
                                      value={p1ShippingAddValue}
                                      options={p1ShippingAddress.map(
                                        (e) => e.addressStr
                                      )}
                                      placeholder="Choose an address"
                                      onChange={setP1ShippingAddValue}
                                      //onChange={(e) => handleShippingAddSelection(e.target.value)}
                                      icon={
                                        <MdLocationOn className="text-lg opacity-40 mr-1" />
                                      }
                                    />
                                  </div>
                                  {errors?.p1ShippingAddValue && (
                                    <p className="error-text">
                                      {errors.p1ShippingAddValue}
                                    </p>
                                  )}
                                </div>
                              )}

                              {p1ShippingAddValue && (
                                <div className="col-span-full mt-6 w-full">
                                  <p className="font-semibold text-lg mb-2 border-b pb-3">
                                    {userRoleId === "ROLE_DEALER"
                                      ? "Residential Address"
                                      : "Residential Address"}
                                  </p>
                                  <p className="capitalize text-lg">
                                    {p1ShippingAddValue
                                      ?.split(",")
                                      .map((line, idx) => (
                                        <span
                                          key={idx}
                                          style={{ display: "block" }}
                                        >
                                          {line.trim()}
                                        </span>
                                      ))}
                                  </p>
                                </div>
                              )}
                              {p1ShippingAddValue && (
                                <>
                                  <div className="mt-4 lg:col-span-3 col-span-full relative">
                                    <label
                                      htmlFor="postal-code"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      Post code*
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value={p1ShippingPostCode}
                                        type="text"
                                        id="postal-code"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4 relative lg:col-span-3 col-span-full">
                                    <label
                                      // htmlFor="country"
                                      className="block text-sm font-medium leading-6"
                                    >
                                      Country*
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value="United Kingdom"
                                        className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4 lg:col-span-3 col-span-full">
                                    <label
                                      htmlFor="delivery-method"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      Address Type*
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="delivery-method"
                                        name="delivery-method"
                                        value={
                                          previousAddress1DeliveryMethod || ""
                                        }
                                        onChange={(e) =>
                                          setPreviousAddress1DeliveryMethod(
                                            e.target.value
                                          )
                                        }
                                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                      >
                                        <option value="" disabled>
                                          Select Address Type
                                        </option>
                                        {/* <option value={1}>Home</option>
                                    <option value={2}>Retail Store</option>
                                    <option value={3}>PUDO Store</option>
                                    <option value={4}>Work</option>
                                    <option value={5}>Other</option> */}
                                        {deliveryStatuses.map((status) => (
                                          <option
                                            key={status.deliveryMethodId}
                                            value={status.deliveryMethodId}
                                          >
                                            {status.deliveryMethodDesc}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    {errorMsg && (
                                      <p className="error-text">{errorMsg}</p>
                                    )}

                                    {errors?.previousAddress1DeliveryMethod && (
                                      <p className="error-text">
                                        {errors.previousAddress1DeliveryMethod}
                                      </p>
                                    )}
                                  </div>
                                  <div className="mt-4 flex flex-col lg:col-span-3 col-span-full">
                                    <label
                                      htmlFor="current-address-since"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      How long did you live at this address?*
                                    </label>
                                    <div className="flex items-center mt-2 gap-7">
                                      <div className="flex items-center">
                                        <div className="">
                                          <input
                                            value={
                                              previousAddressSince1YearValue
                                            }
                                            onChange={(e) =>
                                              setPreviousAddressSince1YearValue(
                                                e.target.value.trim()
                                              )
                                            }
                                            className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            type="number"
                                            placeholder="0"
                                          />
                                        </div>
                                        <p className="ml-2">Years</p>
                                      </div>
                                      <div className="flex items-center">
                                        <div className="">
                                          <input
                                            value={
                                              previousAddressSince1MonthValue
                                            }
                                            onChange={(e) =>
                                              setPreviousAddressSince1MonthValue(
                                                e.target.value.trim()
                                              )
                                            }
                                            className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            type="number"
                                            placeholder="0"
                                          />
                                        </div>
                                        <p className="ml-2">Months</p>
                                      </div>
                                    </div>
                                    {errors?.previousAddressSince1YearValue && (
                                      <p className="error-text">
                                        {errors.previousAddressSince1YearValue}
                                      </p>
                                    )}
                                    {errors?.previousAddressSince1MonthValue && (
                                      <p className="error-text">
                                        {errors.previousAddressSince1MonthValue}
                                      </p>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {addressTotalDurationStep >= 3 && (
                          <div className="border custom-border-50 my-3 p-6 rounded">
                            <h2 className="text-xl font-semibold leading-8">
                              Previous Residential Address&nbsp;
                              <span className="error-text">
                                (Minimum 3-year history required)
                              </span>
                            </h2>
                            <div className="grid grid-cols-6 gap-4">
                              <div className="lg:col-span-4 md:col-span-3 col-span-full mt-4 relative">
                                <label
                                  htmlFor="shipping-zip-search"
                                  className="block text-sm font-medium leading-6"
                                >
                                  Search by Post code
                                </label>
                                <div className="mt-2">
                                  <input
                                    value={p2ShippingZipCode}
                                    // onChange={(e) => {
                                    //    setZipCode(e.target.value);
                                    //  }}
                                    type="text"
                                    id="shipping-zip-search"
                                    onChange={(e) =>
                                      setP2ShippingZipCode(
                                        e.target.value.trim()
                                      )
                                    }
                                    className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {errors?.p2ShippingZipCode && (
                                  <p className="error-text">
                                    {errors.p2ShippingZipCode}
                                  </p>
                                )}
                              </div>
                              <div className="lg:col-span-2 md:col-span-3 col-span-full mt-4 relative">
                                <LoaderButton
                                  type="button"
                                  title="Search address"
                                  style="md:mt-[32px]"
                                  handleClick={handleP2ShippingZipCodeSearch}
                                  disabled={p2ShippingBtnDisable}
                                />
                              </div>
                              {p2ShippingAddress && dropdown && (
                                <div className="col-span-full mt-4">
                                  <label
                                    htmlFor="suggested-address"
                                    className="block text-sm font-medium leading-6"
                                  >
                                    Suggested Addresses
                                  </label>
                                  <div className="mt-2">
                                    <CustomSelect
                                      value={p2ShippingAddValue}
                                      options={p2ShippingAddress.map(
                                        (e) => e.addressStr
                                      )}
                                      placeholder="Choose an address"
                                      onChange={setP2ShippingAddValue}
                                      //onChange={(e) => handleShippingAddSelection(e.target.value)}
                                      icon={
                                        <MdLocationOn className="text-lg opacity-40 mr-1" />
                                      }
                                    />
                                  </div>
                                  {errors?.p2ShippingAddValue && (
                                    <p className="error-text">
                                      {errors.p2ShippingAddValue}
                                    </p>
                                  )}
                                </div>
                              )}

                              {p2ShippingAddValue && (
                                <div className="col-span-full mt-6 w-full">
                                  <p className="font-semibold text-lg mb-2 border-b pb-3">
                                    {userRoleId === "ROLE_DEALER"
                                      ? "Residential Address"
                                      : "Residential Address"}
                                  </p>
                                  <p className="capitalize text-lg">
                                    {p2ShippingAddValue
                                      ?.split(",")
                                      .map((line, idx) => (
                                        <span
                                          key={idx}
                                          style={{ display: "block" }}
                                        >
                                          {line.trim()}
                                        </span>
                                      ))}
                                  </p>
                                </div>
                              )}
                              {p2ShippingAddValue && (
                                <>
                                  <div className="mt-4 lg:col-span-3 col-span-full relative">
                                    <label
                                      htmlFor="postal-code"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      Post code*
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value={p2ShippingPostCode}
                                        type="text"
                                        id="postal-code"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4 relative lg:col-span-3 col-span-full">
                                    <label
                                      // htmlFor="country"
                                      className="block text-sm font-medium leading-6"
                                    >
                                      Country*
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value="United Kingdom"
                                        className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4 lg:col-span-3 col-span-full">
                                    <label
                                      htmlFor="delivery-method"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      Address Type*
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="delivery-method"
                                        name="delivery-method"
                                        value={
                                          previousAddress2DeliveryMethod || ""
                                        }
                                        onChange={(e) =>
                                          setPreviousAddress2DeliveryMethod(
                                            e.target.value
                                          )
                                        }
                                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                      >
                                        <option value="" disabled>
                                          Select Address Type
                                        </option>
                                        {/* <option value={1}>Home</option>
                                    <option value={2}>Retail Store</option>
                                    <option value={3}>PUDO Store</option>
                                    <option value={4}>Work</option>
                                    <option value={5}>Other</option> */}
                                        {deliveryStatuses.map((status) => (
                                          <option
                                            key={status.deliveryMethodId}
                                            value={status.deliveryMethodId}
                                          >
                                            {status.deliveryMethodDesc}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    {errorMsg && (
                                      <p className="error-text">{errorMsg}</p>
                                    )}

                                    {errors?.previousAddress2DeliveryMethod && (
                                      <p className="error-text">
                                        {errors.previousAddress2DeliveryMethod}
                                      </p>
                                    )}
                                  </div>
                                  <div className="mt-4 flex flex-col lg:col-span-3 col-span-full">
                                    <label
                                      htmlFor="current-address-since"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      How long did you live at this address?*
                                    </label>
                                    <div className="flex items-center mt-2 gap-7">
                                      <div className="flex items-center">
                                        <div className="">
                                          <input
                                            value={
                                              previousAddressSince2YearValue
                                            }
                                            onChange={(e) =>
                                              setPreviousAddressSince2YearValue(
                                                e.target.value.trim()
                                              )
                                            }
                                            className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            type="number"
                                            placeholder="0"
                                          />
                                        </div>
                                        <p className="ml-2">Years</p>
                                      </div>
                                      <div className="flex items-center">
                                        <div className="">
                                          <input
                                            value={
                                              previousAddressSince2MonthValue
                                            }
                                            onChange={(e) =>
                                              setPreviousAddressSince2MonthValue(
                                                e.target.value.trim()
                                              )
                                            }
                                            className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            type="number"
                                            placeholder="0"
                                          />
                                        </div>
                                        <p className="ml-2">Months</p>
                                      </div>
                                    </div>
                                    {errors?.previousAddressSince2YearValue && (
                                      <p className="error-text">
                                        {errors.previousAddressSince2YearValue}
                                      </p>
                                    )}
                                    {errors?.previousAddressSince2MonthValue && (
                                      <p className="error-text">
                                        {errors.previousAddressSince2MonthValue}
                                      </p>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {addressTotalDurationStep >= 4 && (
                          <div className="border custom-border-50 my-3 p-6 rounded">
                            <h2 className="text-xl font-semibold leading-8">
                              Previous Residential Address&nbsp;
                              <span className="error-text">
                                (Minimum 3-year history required)
                              </span>
                            </h2>
                            <div className="grid grid-cols-6 gap-4">
                              <div className="lg:col-span-4 md:col-span-3 col-span-full mt-4 relative">
                                <label
                                  htmlFor="shipping-zip-search"
                                  className="block text-sm font-medium leading-6"
                                >
                                  Search by Post code
                                </label>
                                <div className="mt-2">
                                  <input
                                    value={p3ShippingZipCode}
                                    // onChange={(e) => {
                                    //    setZipCode(e.target.value);
                                    //  }}
                                    type="text"
                                    id="shipping-zip-search"
                                    onChange={(e) =>
                                      setP3ShippingZipCode(
                                        e.target.value.trim()
                                      )
                                    }
                                    className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {errors?.p3ShippingZipCode && (
                                  <p className="error-text">
                                    {errors.p3ShippingZipCode}
                                  </p>
                                )}
                              </div>
                              <div className="lg:col-span-2 md:col-span-3 col-span-full mt-4 relative">
                                <LoaderButton
                                  type="button"
                                  title="Search address"
                                  style="md:mt-[32px]"
                                  handleClick={handleP3ShippingZipCodeSearch}
                                  disabled={p3ShippingBtnDisable}
                                />
                              </div>
                              {p3ShippingAddress && dropdown && (
                                <div className="col-span-full mt-4">
                                  <label
                                    htmlFor="suggested-address"
                                    className="block text-sm font-medium leading-6"
                                  >
                                    Suggested Addresses
                                  </label>
                                  <div className="mt-2">
                                    <CustomSelect
                                      value={p3ShippingAddValue}
                                      options={p3ShippingAddress.map(
                                        (e) => e.addressStr
                                      )}
                                      placeholder="Choose an address"
                                      onChange={setP3ShippingAddValue}
                                      //onChange={(e) => handleShippingAddSelection(e.target.value)}
                                      icon={
                                        <MdLocationOn className="text-lg opacity-40 mr-1" />
                                      }
                                    />
                                  </div>
                                  {errors?.p3ShippingAddValue && (
                                    <p className="error-text">
                                      {errors.p3ShippingAddValue}
                                    </p>
                                  )}
                                </div>
                              )}

                              {p3ShippingAddValue && (
                                <div className="col-span-full mt-6 w-full">
                                  <p className="font-semibold text-lg mb-2 border-b pb-3">
                                    {userRoleId === "ROLE_DEALER"
                                      ? "Residential Address"
                                      : "Residential Address"}
                                  </p>
                                  <p className="capitalize text-lg">
                                    {p3ShippingAddValue
                                      ?.split(",")
                                      .map((line, idx) => (
                                        <span
                                          key={idx}
                                          style={{ display: "block" }}
                                        >
                                          {line.trim()}
                                        </span>
                                      ))}
                                  </p>
                                </div>
                              )}
                              {p3ShippingAddValue && (
                                <>
                                  <div className="mt-4 lg:col-span-3 col-span-full relative">
                                    <label
                                      htmlFor="postal-code"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      Post code*
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value={p3ShippingPostCode}
                                        type="text"
                                        id="postal-code"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4 relative lg:col-span-3 col-span-full">
                                    <label
                                      // htmlFor="country"
                                      className="block text-sm font-medium leading-6"
                                    >
                                      Country*
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value="United Kingdom"
                                        className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4 lg:col-span-3 col-span-full">
                                    <label
                                      htmlFor="delivery-method"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      Address Type*
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="delivery-method"
                                        name="delivery-method"
                                        value={
                                          previousAddress3DeliveryMethod || ""
                                        }
                                        onChange={(e) =>
                                          setPreviousAddress3DeliveryMethod(
                                            e.target.value
                                          )
                                        }
                                        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                      >
                                        <option value="" disabled>
                                          Select Address Type
                                        </option>
                                        {/* <option value={1}>Home</option>
                                    <option value={2}>Retail Store</option>
                                    <option value={3}>PUDO Store</option>
                                    <option value={4}>Work</option>
                                    <option value={5}>Other</option> */}
                                        {deliveryStatuses.map((status) => (
                                          <option
                                            key={status.deliveryMethodId}
                                            value={status.deliveryMethodId}
                                          >
                                            {status.deliveryMethodDesc}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    {errorMsg && (
                                      <p className="error-text">{errorMsg}</p>
                                    )}

                                    {errors?.previousAddress3DeliveryMethod && (
                                      <p className="error-text">
                                        {errors.previousAddress3DeliveryMethod}
                                      </p>
                                    )}
                                  </div>
                                  <div className="mt-4 flex flex-col lg:col-span-3 col-span-full">
                                    <label
                                      htmlFor="current-address-since"
                                      className="block text-sm font-medium leading-6 "
                                    >
                                      How long did you live at this address?*
                                    </label>
                                    <div className="flex items-center mt-2 gap-7">
                                      <div className="flex items-center">
                                        <div className="">
                                          <input
                                            value={
                                              previousAddressSince3YearValue
                                            }
                                            onChange={(e) =>
                                              setPreviousAddressSince3YearValue(
                                                e.target.value.trim()
                                              )
                                            }
                                            className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            type="number"
                                            placeholder="0"
                                          />
                                        </div>
                                        <p className="ml-2">Years</p>
                                      </div>
                                      <div className="flex items-center">
                                        <div className="">
                                          <input
                                            value={
                                              previousAddressSince3MonthValue
                                            }
                                            onChange={(e) =>
                                              setPreviousAddressSince3MonthValue(
                                                e.target.value.trim()
                                              )
                                            }
                                            className="custom-number-input block w-[50px] focus:border-b focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                            type="number"
                                            placeholder="0"
                                          />
                                        </div>
                                        <p className="ml-2">Months</p>
                                      </div>
                                    </div>
                                    {errors?.previousAddressSince3YearValue && (
                                      <p className="error-text">
                                        {errors.previousAddressSince3YearValue}
                                      </p>
                                    )}
                                    {errors?.previousAddressSince3MonthValue && (
                                      <p className="error-text">
                                        {errors.previousAddressSince3MonthValue}
                                      </p>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="border custom-border-50 my-3 p-6 rounded">
                          <div
                            style={{ justifyContent: "start" }}
                            className="custom-checkbox mt-4"
                          >
                            <div>
                              <input
                                id="billing-address"
                                checked={billingAddressActive}
                                onChange={handleBillingAddressChange}
                                type="checkbox"
                                className="focus:ring-teal-600"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="billing-address"
                                className="font-semibold"
                              >
                                My Billing information is same as my Residential
                                information
                              </label>
                            </div>
                          </div>

                          {!billingAddressActive && (
                            <div>
                              <h2 className="mt-4 text-xl font-semibold leading-8">
                                Billing Address
                              </h2>
                              <div className="grid grid-cols-6 gap-x-3">
                                <div className="lg:col-span-4 md:col-span-3 col-span-full mt-4 relative">
                                  <label
                                    htmlFor="billing-zip-search"
                                    className="block text-sm font-medium leading-6"
                                  >
                                    Search by Post code
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      value={billingZipCode}
                                      type="text"
                                      id="billing-zip-search"
                                      onChange={(e) =>
                                        setBillingZipCode(e.target.value)
                                      }
                                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                  {errors?.billingZipCode && (
                                    <p className="error-text">
                                      {errors.billingZipCode}
                                    </p>
                                  )}
                                </div>
                                <div className="lg:col-span-2 md:col-span-3 col-span-full mt-4 relative">
                                  <LoaderButton
                                    type="button"
                                    title="Search address"
                                    style="md:mt-[32px] w-full"
                                    handleClick={handleBillingZipCodeSearch}
                                    disabled={billingBtnDisable}
                                  />
                                </div>
                                {billingAddress && dropdownShow && (
                                  <div className="col-span-full mt-4">
                                    <label
                                      htmlFor="suggested-address"
                                      className="block text-sm font-medium leading-6"
                                    >
                                      Suggested Addresses
                                    </label>
                                    <div className="mt-2">
                                      <CustomSelect
                                        value={billingAddValue}
                                        options={billingAddress.map(
                                          (e) => e.addressStr
                                        )}
                                        placeholder="Choose an address"
                                        onChange={setBillingAddValue}
                                        icon={
                                          <MdLocationOn className="text-lg opacity-40 mr-1" />
                                        }
                                      />
                                    </div>
                                    {errors?.billingAddValue && (
                                      <p className="error-text">
                                        {errors.billingAddValue}
                                      </p>
                                    )}
                                  </div>
                                )}
                                {billingAddValue && (
                                  <div className="col-span-full mt-6 w-full">
                                    <p className="font-semibold text-lg mb-2 border-b pb-3">
                                      Billing Address
                                    </p>
                                    <p className="capitalize text-lg">
                                      {billingAddValue
                                        ?.split(",")
                                        .map((line, idx) => (
                                          <span
                                            key={idx}
                                            style={{ display: "block" }}
                                          >
                                            {line.trim()}
                                          </span>
                                        ))}
                                    </p>
                                  </div>
                                )}
                                {billingAddValue && (
                                  <>
                                    <div className="mt-4 lg:col-span-3 col-span-full relative">
                                      <label
                                        htmlFor="postal-code"
                                        className="block text-sm font-medium leading-6 "
                                      >
                                        Post code
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          value={billingPostCode}
                                          type="text"
                                          id="postal-code"
                                          autoComplete="address-level1"
                                          className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                        />
                                      </div>
                                    </div>
                                    <div className="mt-4 relative lg:col-span-3 col-span-full">
                                      <label
                                        // htmlFor="country"
                                        className="block text-sm font-medium leading-6"
                                      >
                                        Country
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          value="United Kingdom"
                                          className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </fieldset>
                    </>
                  ) : null}
                </div>
              </div>
              {errors?.stepFour && (
                <p className="error-text text-center">{errors.stepFour}</p>
              )}
              {errors?.stepFive && (
                <p className="error-text text-center">{errors.stepFive}</p>
              )}
              {errors?.finalSubmit && (
                <p className="error-text text-center">{errors.finalSubmit}</p>
              )}
              {acknowledgmentError && (
                <p className="error-text text-center">{acknowledgmentError}</p>
              )}
              <div className="flex items-center justify-center gap-3">
                {currentStep !== 1 && (
                  <button
                    type="button"
                    onClick={() => {
                      handleStepper("prev");
                      setEnableCheckout(false);
                    }}
                    className={`cta-two px-3 py-2 w-1/2 rounded-lg ${
                      submitButtonDisable ? "btn-disabled" : ""
                    }`}
                  >
                    Back
                  </button>
                )}
                <LoaderButton
                  type="button"
                  title={currentStep !== 6 ? "Save and Proceed" : "Submit"}
                  handleClick={() =>
                    currentStep !== 6
                      ? handleStepper("next")
                      : handleSubmitButtonCheck()
                  }
                  style={`cta-one px-3 py-2 w-1/2 rounded-lg ${
                    submitButtonDisable ? "btn-disabled" : ""
                  }`}
                  disabled={submitLoader}
                />
                {/* <button
                  type={`${currentStep !== 6 ? "button" : "button"}`}
                  // onClick={() => handleStepper("next")}
                  // handleSubmitButton()
                  onClick={() => currentStep !== 6 ? handleStepper("next") : handleSubmitButton()}
                  className="cta-one px-3 py-2 w-1/2 rounded-lg"
                >
                  {currentStep !== 6 ? "Save and Proceed" : "Submit"}
                </button> */}
              </div>
            </form>
          </div>
          {/* ProductDetails info end */}
          {/* ProductDetails payment start */}
          <hr className="md:none style-one mt-7" />
          <div ref={checkoutFormRef} className="lg:w-4/12 h-fit mt-4 lg:mt-0">
            <form>
              {currentPlanData !== null && (
                <div className="mb-4 bg-[#0f766d]/10 rounded-lg">
                  <ProductCard brandIndex={0} data={currentPlanData} />
                </div>
              )}
              <div className="border custom-border-50 h-fit rounded-lg p-4">
                {creditCheck.createdBy.id != null &&
                  creditCheck.createdBy.id !== "" &&
                  creditCheck.createdBy.id !== 0 && (
                    <fieldset className="mb-3">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="price"
                          className="block font-medium leading-6"
                        >
                          Apply Discount
                        </label>
                        <HiOutlineInformationCircle
                          //onClick={() => setIsCreditFail(!isCreditFail)}
                          className="text-xl"
                          // hover:opacity-50 cursor-pointer
                        />
                      </div>
                      {order?.voucher != null &&
                        order?.voucher.voucherId > 0 && (
                          <p className="text-sm text-green-500 text-start">
                            {order?.voucher.voucherApplyedMessage}
                          </p>
                        )}
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                          <RiDiscountPercentLine className="text-xl" />
                        </div>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          className={
                            (order?.voucher != null &&
                              order?.voucher.voucherId > 0) ||
                            isApplyDiscountDisabled
                              ? "block w-full rounded-md border custom-border-50 focus:border-2 placeholder-teal-800 placeholder-opacity-50 focus:ring-2 focus:ring-teal-600 py-1.5 pl-9 pr-20 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                              : "block w-full rounded-md border custom-border-50 focus:border-2 placeholder-teal-800 placeholder-opacity-50 focus:ring-2 focus:ring-teal-600 py-1.5 pl-9 pr-20 bg-transparent sm:text-sm sm:leading-6"
                          }
                          placeholder="Enter discount code"
                          value={voucherCode ? voucherCode : ""}
                          onChange={(event) => {
                            setVoucherCode(event.target.value.trim());
                          }}
                          disabled={isApplyDiscountDisabled}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          {order?.voucher != null &&
                          order?.voucher.voucherId > 0 ? (
                            <button
                              type="button"
                              className="h-full cta-one px-3 rounded-md"
                              onClick={() => handleRemoveVoucherClick()}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="h-full cta-one px-3 rounded-md"
                              onClick={() => handleApplyVoucherClick()}
                              disabled={isApplyDiscountDisabled}
                            >
                              Apply
                            </button>
                          )}
                        </div>
                      </div>
                      {errors?.voucherCode && (
                        <p className="text-sm error-text text-end">
                          {errors.voucherCode}
                        </p>
                      )}
                      {order?.voucher != null &&
                        order?.voucher.voucherId > 0 && (
                          <p className="text-sm text-green-500 text-end">
                            {order?.voucher.voucherCode.voucherCode} applied
                            successfully
                          </p>
                        )}
                    </fieldset>
                  )}
                <fieldset className="mt-1 flex flex-wrap items-center justify-between border-b custom-border-50 py-2">
                  <p>Pay Monthly</p>
                  <p className="font-semibold">£{order.subtotalAmount}</p>
                </fieldset>
                {order?.voucher != null && order?.voucher.voucherId > 0 && (
                  <fieldset className="mt-1 flex flex-wrap items-center justify-between border-b custom-border-50 py-2">
                    <p>Discount</p>
                    <p className="font-semibold">£{order.discountAmount}</p>
                  </fieldset>
                )}
                <fieldset className="mt-1 flex flex-wrap items-center justify-between border-b custom-border-50 py-2">
                  <p className="font-semibold">Total</p>
                  <p className="font-semibold">£{order.amount}</p>
                </fieldset>
                <div className="flex justify-end mt-2">
                  <p className="text-sm">(Taxes included)</p>
                </div>
              </div>

              <div>
                <fieldset
                  disabled={!enableCheckout}
                  className={`${!enableCheckout ? "btn-disabled" : ""}`}
                >
                  <div className="mt-6 space-y-6">
                    <div className="flex justify-start items-start">
                      <div className="custom-checkbox">
                        <div>
                          {/* {console.log("isTNCChecked",isTNCChecked)} */}
                          <input
                            checked={isTNCChecked}
                            onChange={handleTnCCheckbox}
                            type="checkbox"
                            className="focus:ring-teal-600"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label
                            name="t&c"
                            htmlFor="t&c"
                            className="font-medium"
                          >
                            By completing the order, you agree to our
                            <a
                              href=""
                              className="mx-1 underline decoration-1 cursor-pointer hover:decoration-2"
                            >
                              Terms & Conditions
                            </a>
                            and
                            <a
                              href=""
                              className="mx-1 underline decoration-1 cursor-pointer hover:decoration-2"
                            >
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                    {!isCreditFail && (
                      <div className="flex justify-start items-start">
                        <label className="custom-checkbox">
                          <div>
                            <button
                              onClick={() => {
                                if (checkContractEmailStatus === false) {
                                  handleAcknowledgeEmail(); // api call to resend acknowledge email
                                }
                                dispatch(openModal("contract"));
                              }}
                              type="button"
                              className={`relative dummy-checkbox focus:ring-teal-600 mr-[10px] ${
                                isContractRead ? "dummy-checked" : ""
                              }`}
                            ></button>
                          </div>
                          <div className="text-sm leading-6">
                            <label
                              name="contract"
                              htmlFor="contract"
                              className="font-medium"
                            >
                              Please check the box to receive the contract via
                              email.{" "}
                              <p
                                onClick={() => {
                                  if (checkContractEmailStatus === false) {
                                    handleAcknowledgeEmail(); // api call to resend acknowledge email
                                  }
                                  dispatch(openModal("contract"));
                                }}
                                className="inline-block mx-1 underline decoration-1 cursor-pointer hover:decoration-2"
                              >
                                View Contract
                              </p>
                            </label>
                          </div>
                        </label>
                      </div>
                    )}
                  </div>
                </fieldset>
                {/* ProductDetails payment start */}
                <fieldset
                  className={`${
                    !enableCheckoutButton ? "btn-disabled" : ""
                  } mt-4`}
                  disabled={!enableCheckoutButton}
                >
                  {/* <p className="text-red-600 text-sm">{errors.checkout}</p> */}
                  <button
                    onClick={() => {
                      setErrors((prev) => ({
                        ...prev,
                        checkout: "",
                      }));

                      handleCheckoutContract();
                      // dispatch(openModal("acknowledgeContract"));
                      // handleCheckoutButtonClick(null);
                      // if (userRoleId === "ROLE_DEALER") {
                      //api call ack
                      // if(ackPass){
                      // if (isCreditFail === true) {
                      // setEnableCheckout(true);
                      // handleCheckoutButtonClick(null);
                      // } else {
                      // dispatch(openModal("checkoutModel"));
                      // }
                      // }//ackPass
                      // else{
                      // dispatch(openModal("acknowledgeContract"));
                      // }
                      // navigate("/checkout");
                      // Cookies.remove("accesstoken");
                      // } else {
                      // handleCheckoutButtonClick(null);
                      // }
                    }}
                    type="button"
                    className="cta-one px-3 py-2 w-full rounded-lg"
                  >
                    Checkout
                  </button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
        <Toaster position="top-center" reverseOrder={false} />
      </section>

      <ModalComponent
        size={
          modalInitState.contentId === "creditCheck"
            ? "md"
            : modalInitState.contentId === "contract"
            ? "4xl"
            : modalInitState.contentId === "portinfail"
            ? "md"
            : modalInitState.contentId === "portinsuccess"
            ? "md"
            : modalInitState.contentId === "userVerification"
            ? "md"
            : modalInitState.contentId === "login"
            ? "md"
            : modalInitState.contentId === "emailConfirm"
            ? "md"
            : modalInitState.contentId === "checkoutModel"
            ? "md"
            : modalInitState.contentId === "acknowledgeContract"
            ? "md"
            : modalInitState.contentId === "proceedToCheckout"
            ? "md"
            : modalInitState.contentId === "creditVerification"
            ? "md"
            : modalInitState.contentId === "badReqCheckInCreateOrderAPI"
            ? "md"
            : modalInitState.contentId ===
              "badReqCheckInCreateOrderAPICustomMsg"
            ? "md"
            : modalInitState.contentId === "invalidSim"
            ? "md"
            : modalInitState.contentId === "updateUserReq"
            ? "md"
            : null
        }
        type={
          modalInitState.contentId === "creditCheck"
            ? "error"
            : modalInitState.contentId === "contract"
            ? ""
            : modalInitState.contentId === "portinfail"
            ? "error"
            : modalInitState.contentId === "portinsuccess"
            ? "success"
            : modalInitState.contentId === "userVerification"
            ? "pending"
            : modalInitState.contentId === "login"
            ? "info"
            : modalInitState.contentId === "emailConfirm"
            ? "alert"
            : modalInitState.contentId === "checkoutModel"
            ? "info"
            : modalInitState.contentId === "acknowledgeContract"
            ? "info"
            : modalInitState.contentId === "proceedToCheckout"
            ? "success"
            : modalInitState.contentId === "creditVerification"
            ? `${creditCheckLoader == true ? null : "alert"}`
            : modalInitState.contentId === "badReqCheckInCreateOrderAPI"
            ? "alert"
            : modalInitState.contentId ===
              "badReqCheckInCreateOrderAPICustomMsg"
            ? "alert"
            : modalInitState.contentId === "invalidSim"
            ? "alert"
            : modalInitState.contentId === "updateUserReq"
            ? "pending"
            : null
        }
        position={
          modalInitState.contentId === "creditCheck"
            ? "center"
            : modalInitState.contentId === "contract"
            ? "top"
            : modalInitState.contentId === "portinfail"
            ? "center"
            : modalInitState.contentId === "portinsuccess"
            ? "center"
            : modalInitState.contentId === "userVerification"
            ? "center"
            : modalInitState.contentId === "login"
            ? "center"
            : modalInitState.contentId === "emailConfirm"
            ? "center"
            : modalInitState.contentId === "checkoutModel"
            ? "center"
            : modalInitState.contentId === "acknowledgeContract"
            ? "center"
            : modalInitState.contentId === "proceedToCheckout"
            ? "center"
            : modalInitState.contentId === "creditVerification"
            ? "center"
            : modalInitState.contentId === "badReqCheckInCreateOrderAPI"
            ? "md"
            : modalInitState.contentId ===
              "badReqCheckInCreateOrderAPICustomMsg"
            ? "md"
            : modalInitState.contentId === "invalidSim"
            ? "md"
            : modalInitState.contentId === "updateUserReq"
            ? "center"
            : null
        }
        title={
          modalInitState.contentId === "creditCheck"
            ? "Credit check fail"
            : modalInitState.contentId === "contract"
            ? "Contract details"
            : modalInitState.contentId === "portinfail"
            ? "Port In status"
            : modalInitState.contentId === "portinsuccess"
            ? "Port In status"
            : modalInitState.contentId === "userVerification"
            ? "Verification pending"
            : modalInitState.contentId === "login"
            ? "Please Login"
            : modalInitState.contentId === "emailConfirm"
            ? "Alert"
            : modalInitState.contentId === "checkoutModel"
            ? "Please Enter ICCID"
            : modalInitState.contentId === "acknowledgeContract"
            ? "Acknowledgement Pending"
            : modalInitState.contentId === "proceedToCheckout"
            ? "Credit Check Successful"
            : modalInitState.contentId === "creditVerification"
            ? `${
                creditCheckLoader == true
                  ? "Credit Check Inprogress..."
                  : "Credit Check Consent"
              }`
            : // ? "Credit Check Consent"
            modalInitState.contentId === "badReqCheckInCreateOrderAPI"
            ? "Warning"
            : modalInitState.contentId ===
              "badReqCheckInCreateOrderAPICustomMsg"
            ? "Warning"
            : modalInitState.contentId === "invalidSim"
            ? "SIM"
            : modalInitState.contentId === "updateUserReq"
            ? "Verification pending"
            : null
        }
        description=""
        bottomLine={true}
        crossButton={
          modalInitState.contentId === "creditVerification" &&
          creditCheckLoader == true
            ? false
            : true
        }
      >
        {modalInitState.contentId === "creditCheck" ? (
          <ModalForCredit
            navigate={navigate}
            dispatch={dispatch}
            handleCheckout={handleCheckout}
            checkoutFormRef={checkoutFormRef}
          />
        ) : modalInitState.contentId === "contract" ? (
          <ModalForContract
            setButtonClicked={setButtonClicked}
            setIsContractRead={setIsContractRead}
            buttonClicked={buttonClicked}
            dispatch={dispatch}
            contractData={contractData}
            setIsApplyDiscountDisabled={setIsApplyDiscountDisabled}
          />
        ) : modalInitState.contentId === "portinsuccess" ? (
          <ModalForPortInSuccess dispatch={dispatch} />
        ) : modalInitState.contentId === "portinfail" ? (
          <ModalForPortInFail navigate={navigate} dispatch={dispatch} />
        ) : modalInitState.contentId === "userVerification" ? (
          <ModelForTimer
            formattedTime={formattedTime}
            customerMessage={customerMessage}
            verificationMessage={verificationMessage}
            isResetButton={isResetButton}
            handleResendVerificationLink={handleResendVerificationLink}
            resendBtnDisabled={resendBtnDisabled}
            handleManualCheckToVerify={handleManualCheckToVerify}
          />
        ) : modalInitState.contentId === "login" ? (
          <ModelForLogin dispatch={dispatch} navigate={navigate} />
        ) : modalInitState.contentId === "emailConfirm" ? (
          <ModelForEmailConfirm
            dispatch={dispatch}
            setSubmitLoader={setSubmitLoader}
            setLoader={setLoader}
            handlePersonalDeatils={handlePersonalDeatils}
          />
        ) : modalInitState.contentId === "checkoutModel" ? (
          <ModelForCheckoutButton
            propsError={errors}
            handleCheckoutButtonClick={handleCheckoutButtonClick}
            resendBtnDisabled={resendBtnDisabled}
          />
        ) : modalInitState.contentId === "acknowledgeContract" ? (
          <ModelForAcknowledgeContract
            handleResendAcknowledgeEmail={handleResendAcknowledgeEmail}
            resendBtnDisabled={resendBtnDisabled}
            ackEmailMessage={ackEmailMessage}
            resendEmailMessage={resendEmailMessage}
            handleCheckoutContract={handleCheckoutContract}
            customerMessage={customerMessage}
            isResetButton={isResetButton}
            formattedTime={formattedTime}
            handleManualCheckAcknowledge={handleManualCheckAcknowledge}
          />
        ) : modalInitState.contentId === "proceedToCheckout" ? (
          <ModelForProceedToCheckout dispatch={dispatch} />
        ) : modalInitState.contentId === "creditVerification" ? (
          <ModelForCreditVerification
            creditCheckLoader={creditCheckLoader}
            handleSubmitButton={handleSubmitButton}
            dispatch={dispatch}
          />
        ) : modalInitState.contentId === "badReqCheckInCreateOrderAPI" ? (
          <ModelForBadReqInCreateOrderAPI
            // handleSubmitButton={handleSubmitButton}
            dispatch={dispatch}
            setSubmitLoader={setSubmitLoader}
            setLoader={setLoader}
            errorMessage={errors.checkout}
            handleCheckoutButtonClick={handleCheckoutButtonClick}
            setErrors={setErrors}
            setSubmitButtonDisable={setSubmitButtonDisable}
          />
        ) : modalInitState.contentId ===
          "badReqCheckInCreateOrderAPICustomMsg" ? (
          <ModelForBadReqInCreateOrderAPICustomMsg
            setSubmitButtonDisable={setSubmitButtonDisable}
            dispatch={dispatch}
            setSubmitLoader={setSubmitLoader}
            setLoader={setLoader}
            errorMessage={errors.checkout}
            setErrors={setErrors}
            setEnableCheckoutButton={setEnableCheckoutButton}
            setIsContractRead={setIsContractRead}
            setIsTNCChecked={setIsTNCChecked}
            setButtonClicked={setButtonClicked}
          />
        ) : modalInitState.contentId === "invalidSim" ? (
          <ModelForInvalidSIM
            setSubmitButtonDisable={setSubmitButtonDisable}
            dispatch={dispatch}
            setSubmitLoader={setSubmitLoader}
            setLoader={setLoader}
            errorMessage={errors.checkout}
          />
        ) : modalInitState.contentId === "updateUserReq" ? (
          <ModelForUserUpdateRequest
            formattedTime={formattedTime}
            customerMessage={customerMessage}
            verificationMessage={verificationMessage}
            isResetButton={isResetButton}
            handleResendVerificationLinkForUserUpdateReq={
              handleResendVerificationLinkForUserUpdateReq
            }
            resendBtnDisabled={resendBtnDisabled}
            handleManualCheckToVerifyUserUpdateReq={
              handleManualCheckToVerifyUserUpdateReq
            }
          />
        ) : null}
      </ModalComponent>
      {loader && <Loader />}
    </>
  );
}
