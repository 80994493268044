import axios from 'axios';

import React, { Component } from 'react';
import { API_URL } from '../constant/Constant';

class RegisterService {
    
    registerUser(json) {
        return axios.post(`${API_URL}authentication/signup`,json, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    registerDealerUser(json) {
        return axios.post(`${API_URL}authentication/signupDealer`,json, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    getSignupContractById(userId) {
        return axios.get(`${API_URL}authentication/${userId}/getSignupContractById`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    signupContractAcknowledge(signupContractData) {
        return axios.post(`${API_URL}authentication/signupContractAcknowledge`,signupContractData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    resendDealerSignupContractLink(userId) {
        return axios.get(`${API_URL}authentication/resendDealerSignupContractLink/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    signupContractAcknowledgementStatus(userId) {
        // const verification = {
        //     "userId": 0,
        //     "email": "string",
        //     "password": "string",
        //     "confirmPassword": "string",
        //     "dealerId": dealerId,
        //     "customer": true
        // }
        //console.log("dealer data in loader===",dealerId);
        return axios.get(`${API_URL}authentication/${userId}/signupContractAcknowledgementStatus`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
    }
    
}



export default new RegisterService()