import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { FaRegClock } from "react-icons/fa6";

const IotDevices = () => {
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <section className="mx-auto max-w-6xl min-h-[70vh] flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <FaRegClock className="text-[40px] mb-3" />
            <h1 className="text-2xl font-semibold leading-9 tracking-tight primary-text capitalize">
              Coming Soon...
            </h1>
          </div>
        </section>
      </main>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default IotDevices;
