import React, { useEffect, useState } from "react";
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ModalComponent from "../modalComponent/ModalComponent";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import axios from "axios";
import { API_URL } from "../../constant/Constant";

const AcknowledgeContract = () => {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const { id1 } = useParams();
  const {id2} = useParams();
  const modalInitState = useSelector((state) => state.modal);
  const [urlParams,setUrlParams] = useState("")
  const [displayAckError, setDisplayAckError] = useState("");

//   useEffect(() => {
//    dispatch(openModal("acknowledge"));
//   }, [dispatch]);
useEffect(() => {
    if (id1 || id2) {
      setUrlParams(id1,id2);
      fetchAcknowledgement(id1,id2); // Call the fetch function
    }
  }, [id1,id2]);


const fetchAcknowledgement = async (id1,id2) => {
      setShowLoader(true);
    try {
    const response = await axios.get(
        `${API_URL}api/v1/acceptAcknowledgement`,
        {
          headers: {
            orderIdEncrypt : id1,
            verificationIdEncrypt:id2,
          },
        }
      );
      setMessage(response.data.message || "Acknowledgement Successful");
      if (response.data.status === "alreadyAcknowledged") {
         setShowLoader(false);
        dispatch(openModal("alreadyAcknowledged"));
      } else if (response.data.status === "failed") {
         setShowLoader(false);
        dispatch(openModal("failed"));
      } else if (response.data.status === "success") {
          setShowLoader(false);
        dispatch(openModal("acknowledge"));
      }
    } catch (error) {
      setShowLoader(false);
      setDisplayAckError(""); 
        if (error.message === "Network Error") {
            setDisplayAckError("Network error occurred. Please try again later.");
        } else {
          switch (error.response ? error.response.status : "") {
            case 400:
              setMessage(
                "This link has expired. Please request a new link and try again. Note that the link is valid for 30 minutes only."
              );
              dispatch(openModal("linkExpired"));
              break;
            case 403:
                setDisplayAckError(
                "Failed to acknowledgment email. Please try again later."
              );
              break;
            case 404:
                setDisplayAckError(
                "Acknowledgment information not found. Please try again later."
              );
              break;
            case 500:
                setDisplayAckError("Server error occurred. Please try again later.");
              break;
            default:
                setDisplayAckError("An unknown error occurred. Please try again later.");
              break;
          }
        }
        // dispatch(openModal("error")); 
      } 
  };

 
  return (
    <>
       <ModalComponent
        size="md"
        type={
          modalInitState.contentId === "acknowledge"
            ? "success"
            : modalInitState.contentId === "alreadyAcknowledged"
            ? "info"
            : modalInitState.contentId === "failed"
            ? "failed"
            : modalInitState.contentId === "linkExpired"
            ? "error"
            : "error"
        }
        position="center"
        title={
          modalInitState.contentId === "acknowledge"
            ? "Acknowledgement Successful"
            : modalInitState.contentId === "alreadyAcknowledged"
            ? "Already Acknowledged"
            : modalInitState.contentId === "failed"
            ? "Acknowledgement Failed"
            : modalInitState.contentId === "linkExpired"  
            ? "Link Expired" 
            : "Error occured"
        }
        // description={modalInitState.contentId === "error" ? ackError : message}
          bottomLine={true}
      >
         <div>
          {(modalInitState.contentId === "acknowledge" ||
            modalInitState.contentId === "alreadyAcknowledged" ||
            modalInitState.contentId === "failed" ||
            modalInitState.contentId === "linkExpired" ||
            modalInitState.contentId === "error") && (
              <p>{modalInitState.contentId === "error" ? displayAckError : message}</p>
          )}
        </div>
      </ModalComponent>

      {showLoader && <Loader />}
    </>
  );
}

export default AcknowledgeContract;
