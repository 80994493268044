import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import {
  closeModal,
  openModal,
  selectModalContentId,
} from "../modalComponent/modalSlice";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CancellationService from "../../services/CancellationService";
// import Loader from '../loader/Loader'
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
// import ModalComponent from "../modalComponent/ModalComponent";
// import { closeModal, openModal } from "../modalComponent/modalSlice";
// import Button from "../button/Button";
// import VerificationService from "../../services/VerificationService";

const ModalError = ({ errorMsg }) => {
  return (
    <div>
      <p>{errorMsg}</p>
    </div>
  );
};

const ModalSuccess = ({ successMsg }) => {
  return (
    <div>
      <p>{successMsg}</p>
    </div>
  );
};

const OrderCancellationStatus = () => {
  const { verificationId, orderId } = useParams();
  const [errorMessageModal, setErrorMessageModal] = useState("");
  const modalContentId = useSelector(selectModalContentId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (verificationId && orderId) {
      OrderCancelConfirmation(verificationId, orderId);
    }
    // /orderCancelConfirmation/{verificationId}/{orderId}
  }, []);

  const OrderCancelConfirmation = async (verificationId, orderId) => {
    CancellationService.orderCancelConfirmation(verificationId, orderId)
      .then((response) => {
        if (response.status === 200) {
          //console.log("working");
          dispatch(openModal("successModal"));
        }
      })
      .catch((error) => {
        switch (error.response ? error.response.status : "") {
          case 400:
            dispatch(openModal("errorModal"));
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 403:
            dispatch(openModal("errorModal"));
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 404:
            dispatch(openModal("errorModal"));
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 500:
            dispatch(openModal("errorModal"));
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 406:
            dispatch(openModal("linkExpiredErrorModal"));
            setErrorMessageModal("This link has expired. Please request a new link and try again.");
            break;
          default:
            dispatch(openModal("errorModal"));
            break;
        }
      });
  };

  return (
    <>
      <ModalComponent
        size="md"
        type={modalContentId === "successModal" ? "success" : "error"}
        position="center"
        title={
          modalContentId === "successModal"
            ? "Confirmation Successful"
            : modalContentId === "linkExpiredErrorModal"
            ? "Link Expired"
            : "Something went wrong"
        }
        description=""
        bottomLine={true}
      >
        {modalContentId === "successModal" ? (
          <ModalSuccess successMsg="Thank you for your confirmation, you can close this tab." />
        ) : (
          <ModalError errorMsg={errorMessageModal} />
        )}
      </ModalComponent>
    </>
  );
};

export default OrderCancellationStatus;
